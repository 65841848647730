import { TablePaginationConfig } from 'antd/lib/table';
import { DEFAULT_PAGINATION } from 'src/domain/commonConst';
import { IPaginationMeta } from 'src/domain/IPagination';

export function getTablePaginationParams(meta: IPaginationMeta | undefined): TablePaginationConfig {
    const params = {
        ...DEFAULT_PAGINATION,
        ...(meta && {
            total: meta.total,
            current: Math.ceil(meta.offset / meta.limit) + 1,
            pageSize: meta.limit,
        }),
        showSizeChanger: true,
        size: 'default',
    } as const;

    return params;
}

import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { usePageLocation } from '../../pages/usePageState';
import { CAISO_SIBR_VIEW } from 'src/pages/interface';
import { IUseCaisoSibrSwitcher } from './caisoSibrInterface';

export const useCaisoSibrSwitcher = (defaultView: CAISO_SIBR_VIEW): IUseCaisoSibrSwitcher => {
    const location = useLocation();
    const { setPageQuery, queryToState } = usePageLocation();
    const pageState: any = queryToState(location.search);

    const viewMode = pageState?.viewMode ? pageState?.viewMode : defaultView;
    const [view, setView] = useState<CAISO_SIBR_VIEW>(viewMode);

    const handleView = (view: CAISO_SIBR_VIEW) => {
        setView(view);
        setPageQuery({
            companyId: pageState.companyId,
            viewMode: view,
        });
    };

    return { view, handleView };
};

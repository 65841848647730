import Table from 'antd/lib/table';
import { ColumnsType } from 'antd/lib/table/interface';

interface ISiteImportRecord {
    field: string;
    required: string;
    description?: string;
}

export function SitesImportFormatDescription() {
    const records: ISiteImportRecord[] = [
        {
            field: 'Company Name*',
            required: 'Yes',
            description: 'Exact name as it appears in DRMS',
        },
        {
            field: 'Control Provider Name',
            required: 'Optional',
            description: 'Exact name as it appears in DRMS',
        },
        {
            field: 'Utility Customer Name',
            required: 'Optional',
            description: 'Exact name as it appears in DRMS',
        },
        {
            field: 'Program Names (comma-separated)',
            required: 'Optional',
            description: 'Exact name as it appears in DRMS. May have multiple with comma separated values',
        },
        {
            field: 'Site Name*',
            required: 'Yes',
            description: 'Human readable name of the site. Appears at DRMS and emails',
        },
        {
            field: 'Site Store Number',
            required: 'Optional',
            description: 'Customers identification store or site number',
        },
        {
            field: 'Address*',
            required: 'Yes',
            description: 'Street address and number of site location for Google Maps lookup',
        },
        {
            field: 'City*',
            required: 'Yes',
            description: 'City of site location for Google Maps lookup',
        },
        {
            field: 'State*',
            required: 'Yes',
            description: 'State of site location for Google Maps lookup',
        },
        {
            field: 'Zip*',
            required: 'Yes',
            description: 'Zip Code of site location for Google Maps lookup',
        },
        {
            field: 'Market',
            required: 'Optional',
            description: 'Electricity market generated and transmitted by its member utilities',
        },
        {
            field: 'Load Zone',
            required: 'Optional',
            description: 'Market sub-region where the site is located',
        },
        {
            field: 'Estimated KW',
            required: 'Optional',
            description: 'Estimated summer peak kW for nomination (value between [0, 100000])',
        },
        {
            field: 'Enrollment ID',
            required: 'Optional',
            description: 'Utility identifier (service point number, ESID etc.)',
        },
        {
            field: 'Utility Name',
            required: 'Optional',
            description: 'The name of the utility which serves this site',
        },
        {
            field: 'Zone',
            required: 'Optional',
            description: 'The utility zone where this building is located',
        },
        {
            field: 'Network',
            required: 'Optional',
            description: 'The utility sub-network where this site is located',
        },
        {
            field: 'Event Offset (minutes)',
            required: 'Optional (default 0 minutes)',
            description: 'The number of minutes to offset the start time of an event. 0-30 minutes',
        },
        {
            field: 'Event Max Duration (minutes)',
            required: 'Optional (no max)',
            description: 'The maximum number of minutes to cap the duration of an event. 0-240 minutes',
        },
        {
            field: 'Create VEN (put 1 to create)',
            required: 'Optional (default no)',
            description: 'Create a VEN for this site with default generated values',
        },
        {
            field: 'Partner',
            required: 'Optional',
            description: 'The partner field is typically reserved for external API or integration data or ID’s',
        },
    ];

    const columns: ColumnsType<ISiteImportRecord> = [
        {
            title: 'Field Name',
            dataIndex: 'field',
            width: 300,
            ellipsis: true,
        },
        {
            title: 'Required',
            dataIndex: 'required',
            width: 200,
            ellipsis: true,
        },
        {
            title: 'Description',
            dataIndex: 'description',
            ellipsis: true,
        },
    ];
    return (
        <Table
            rowKey="field"
            size="small"
            scroll={{ x: 'scroll' }}
            sticky
            title={() => 'CSV format description'}
            columns={columns}
            dataSource={records}
            pagination={false}
        ></Table>
    );
}

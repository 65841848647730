import { useEffect, useState } from 'react';
import { DEFAULT_SIDEBAR_MAX_WIDTH, DEFAULT_SIDEBAR_MIN_WIDTH, DEFAULT_SIDEBAR_WIDTH } from 'src/domain/commonConst';
import { useSidebar } from '../context/SidebarContext';

export interface ISidebarResize {
    minWidth?: number;
    maxWidth?: number;
    defaultWidth?: number;
}

export function useSidebarResize({
    minWidth = DEFAULT_SIDEBAR_MIN_WIDTH,
    maxWidth = DEFAULT_SIDEBAR_MAX_WIDTH,
    defaultWidth = DEFAULT_SIDEBAR_WIDTH,
}: ISidebarResize) {
    const { config, setConfig } = useSidebar();
    const [width, setWidth] = useState(config?.width || defaultWidth);
    const [isResizing, setIsResizing] = useState(false);

    const resetDefault = () => {
        setWidth(defaultWidth);
    };

    useEffect(() => {
        const handleMouseMove = (e: MouseEvent) => {
            if (!isResizing) return;

            const newWidth = e.clientX;

            // avoid resizing further than max width
            if (newWidth > maxWidth) {
                e.preventDefault();
                return;
            }

            if (newWidth >= minWidth && newWidth <= maxWidth) {
                setWidth(newWidth);
            }
        };

        const handleMouseUp = (e: MouseEvent) => {
            setIsResizing(false);
            document.body.style.cursor = 'default';
            document.body.style.userSelect = 'auto';
            // store new width when mouse finish to move
            const newWidth = e.clientX;

            if (newWidth >= minWidth && newWidth <= maxWidth) {
                setConfig({ ...config, width: newWidth });
            }
        };

        if (isResizing) {
            document.addEventListener('mousemove', handleMouseMove);
            document.addEventListener('mouseup', handleMouseUp);
        }

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, [isResizing, minWidth, maxWidth, setConfig, config]);

    return {
        width,
        setIsResizing,
        resetDefault,
    };
}

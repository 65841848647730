import React from 'react';
import { useNavigate } from 'react-router-dom';
import LockOutlined from '@ant-design/icons/LockOutlined';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import Button from 'antd/lib/button';
import Result from 'antd/lib/result';
import Typography from 'antd/lib/typography';

interface INewPasswordFormProps {
    title: string;
    loading: boolean;
    isFinished: boolean;
    onFinish: (values: any) => {};
}

export const NewPasswordForm: React.FC<INewPasswordFormProps> = ({ title, loading, isFinished, onFinish }) => {
    const [form] = Form.useForm();
    const navigation = useNavigate();

    const passwordsMatchValidator = (_: any, value: string) => {
        const password = form.getFieldValue('password');
        if (!password || password === value) {
            return Promise.resolve();
        } else {
            return Promise.reject(new Error('The two passwords that you entered do not match!'));
        }
    };

    return isFinished ? (
        <>
            <Result
                status="success"
                title="Password Changed!"
                subTitle="Your password has been changed successfully."
                extra={
                    <Button type="primary" onClick={() => navigation('/login')}>
                        Back to login
                    </Button>
                }
            />
        </>
    ) : (
        <>
            <Typography.Title level={2} type="secondary" className="form-tittle">
                {title}
            </Typography.Title>
            <Form form={form} name="new_password_form" initialValues={{ remember: true }} onFinish={onFinish}>
                <Form.Item
                    name="password"
                    rules={[
                        {
                            pattern: /((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,})).*$/,
                            message:
                                'Password must have at least 6 characters that include at least 1 lowercase letter, 1 uppercase letter, number, and one special symbol, e.g. !-_;$@#^%&',
                        },
                        { required: true, message: 'Please input your password!' },
                    ]}
                >
                    <Input prefix={<LockOutlined />} type="password" placeholder="Password" />
                </Form.Item>
                <Form.Item
                    name="confirm"
                    rules={[
                        {
                            pattern: /((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,})).*$/,
                            message:
                                'Password must have at least 6 characters that include at least 1 lowercase letter, 1 uppercase letter, number, and one special symbol, e.g. !-_;$@#^%&',
                        },
                        { required: true, message: 'Please confirm your password!' },
                        { validator: passwordsMatchValidator },
                    ]}
                    dependencies={['password']}
                >
                    <Input prefix={<LockOutlined />} type="password" placeholder="Confirm Password" />
                </Form.Item>
                <Form.Item noStyle>
                    <Button loading={loading} type="primary" htmlType="submit" className="login-form-button">
                        Confirm
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
};

import { format } from 'date-fns';
import { API } from '../../../api';
import { IFetchPageQuery } from '../../../IFetchQueryOptions';
import { IPagination } from '../../../IPagination';
import { prepareFilters } from '../utils';
import { ICaisoLocation } from './interface';
import { DATE_FORMAT_CSV } from 'src/domain/commonConst';
import { exportCSVData } from 'src/domain/common/exportCSVData';

export const fetchCaisoLocations = async (
    pageQuery: IFetchPageQuery,
    signal?: AbortSignal | null
): Promise<IPagination<ICaisoLocation>> => {
    let { pagination, sorter, search = '', include, filter } = pageQuery;

    const limit = pagination?.pageSize || 10;
    const offset = (pagination?.current ? pagination.current - 1 : 0) * limit;

    if (sorter && (!sorter.field || !sorter.order)) {
        sorter = {};
    }

    const path = `/scheduling-coordinator/caiso/drrs/locations?${new URLSearchParams({
        offset: offset.toString(),
        limit: limit.toString(),
        ...(sorter?.field && { sortField: sorter.field, sortOrder: sorter.order }),
        ...(search && { search }),
        ...(include && { include }),
        ...prepareFilters(filter),
    })}`;

    const response = await API.fetch(path, {
        ...(signal && { signal }),
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const respBody: any = await response.json();

    if (response.ok) {
        return respBody;
    }

    throw new Error(respBody?.error?.message || `Cannot get caiso locations resource data!`);
};

export const fetchCaisoLocationFiltersList = async (
    filter: 'san' | 'status' | 'udc' | 'sub_lap' | 'resource_id',
    signal?: AbortSignal | null
): Promise<string[]> => {
    const response = await API.fetch(
        `/scheduling-coordinator/caiso/drrs/locations/filters?${new URLSearchParams({
            filter,
        })}`,
        {
            ...(signal && { signal }),
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            },
        }
    );

    const respBody: any = await response.json();

    if (response.ok) {
        return respBody.map((item: { value: string }) => item.value);
    }

    throw new Error(respBody?.error?.message || `Cannot get caiso location groups!`);
};

export const fetchSitesByEnrollmentId = async (
    san: string,
    pageQuery: IFetchPageQuery,
    signal?: AbortSignal | null
): Promise<{ site_id: string; site_name: string }[]> => {
    let { pagination, sorter, search = '', include, filter } = pageQuery;

    const limit = pagination?.pageSize || 10;
    const offset = (pagination?.current ? pagination.current - 1 : 0) * limit;

    if (sorter && (!sorter.field || !sorter.order)) {
        sorter = {};
    }

    search = search || san;

    const path = `/sites?${new URLSearchParams({
        offset: offset.toString(),
        limit: limit.toString(),
        ...(sorter?.field && { sortField: sorter.field, sortOrder: sorter.order }),
        ...(search && { search }),
        ...(include && { include }),
        ...prepareFilters(filter),
    })}`;

    const response = await API.fetch(path, {
        ...(signal && { signal }),
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const respBody: any = await response.json();

    if (response.ok) {
        return respBody.data.map((site: any) => {
            return { site_id: site.site_id, site_name: site.site_name };
        });
    }

    throw new Error(respBody?.error?.message || `Cannot get sites data!`);
};

export const linkLocationsToSites = async (
    locationId: number,
    siteId: number,
    signal?: AbortSignal | null
): Promise<string[]> => {
    const path = `/scheduling-coordinator/caiso/drrs/locations/${locationId}/link-site`;

    const response = await API.fetch(path, {
        ...(signal && { signal }),
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ site_id: siteId }),
    });

    const respBody: any = await response.json();

    if (response.ok) {
        return respBody;
    }

    const errorMessage = respBody?.error?.message || `Cannot link location to site`;
    throw new Error(errorMessage);
};

export const exportLocations = async (params: IFetchPageQuery, signal?: AbortSignal | null) => {
    let { search = '', include, filter } = params;

    const path = `/scheduling-coordinator/caiso/drrs/locations?${new URLSearchParams({
        ...(search && { search }),
        ...(include && { include }),
        ...(filter && prepareFilters(filter)),
        format: 'csv',
        limit: '1000000',
    })}`;

    const response = await API.fetch(path, {
        ...(signal && { signal }),
        method: 'get',
        headers: {
            'Content-Type': 'text/csv',
        },
    });

    if (!response.ok) {
        const errorBody: any = await response.json();
        throw new Error(errorBody?.error?.message || `Cannot export locations data!`);
    }

    const respBody: string = await response.text();
    const filename = `drrs-locations-${format(new Date(), DATE_FORMAT_CSV)}.csv`;
    exportCSVData(respBody, filename);
};

import { API } from '../../../api';
import { IFetchPageQuery } from '../../../IFetchQueryOptions';
import { IPagination } from '../../../IPagination';
import { CaisoSyncOrigin, ICaisoSync } from './interface';

export const fetchCaisoSyncs = async (
    origin: CaisoSyncOrigin,
    pageQuery: Pick<IFetchPageQuery, 'pagination'>,
    signal?: AbortSignal | null
): Promise<IPagination<ICaisoSync>> => {
    let { pagination } = pageQuery;

    const limit = pagination?.pageSize || 10;
    const offset = (pagination?.current ? pagination.current - 1 : 0) * limit;

    const path = `/scheduling-coordinator/caiso/drrs/syncs?${new URLSearchParams({
        offset: offset.toString(),
        limit: limit.toString(),
        origin,
    })}`;

    const syncStatusPath = `/scheduling-coordinator/caiso/drrs/request-status/${origin}-sync-status`;

    const syncStatusResponse = await API.fetch(syncStatusPath, { signal });
    if (!syncStatusResponse.ok) {
        throw new Error(`Failed to fetch sync status for ${origin}`);
    }
    const syncStatusData = await syncStatusResponse.json();

    const response = await API.fetch(path, {
        ...(signal && { signal }),
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const respBody: any = await response.json();

    if (!response.ok) {
        throw new Error(respBody?.error?.message || `Cannot get CAISO ${origin} data!`);
    }

    const syncs: ICaisoSync[] = Array.isArray(respBody.data)
        ? respBody.data.map((sync: ICaisoSync) => {
              const syncStatus =
                  syncStatusData?.find((status: any) => status.requestUuid === sync.id)?.status || 'Unknown';

              return {
                  id: sync.id,
                  date: sync.date,
                  is_lambda_sync: sync.is_lambda_sync,
                  requester: sync.requester,
                  status: syncStatus,
                  error_message: sync.error_message,
              };
          })
        : [];

    return {
        meta: {
            total: respBody.meta?.total || 0,
            offset: respBody.meta?.offset || 0,
            limit: respBody.meta?.limit || 0,
        },
        data: syncs,
    };
};

import { useMemo, useState } from 'react';
import Select, { SelectProps } from 'antd/lib/select';
import { useCompanyListQuery } from '../../../domain/company/queries';
import { filterSelectorOption } from '../selectorHelpers';
import { COMPANY_TYPE } from 'src/domain/company/interface';

interface ICompanySelectorProps extends SelectProps<number> {
    company_id?: number;
    onlyTypes?: COMPANY_TYPE[];
}

export interface IOption {
    value: number;
    label: string;
    isLeaf: boolean;
    description?: string;
    children?: any;
}

export const CompanySelector = ({ company_id, onlyTypes, ...props }: ICompanySelectorProps) => {
    const [companyId, setCompanyId] = useState<number | undefined>(company_id);
    const { data } = useCompanyListQuery({});

    const companies = useMemo(() => {
        let preparedCompanies = data?.data || [];

        if (onlyTypes?.length) {
            preparedCompanies = preparedCompanies.filter(c => onlyTypes.includes(c.type));
        }

        return preparedCompanies;
    }, [data?.data, onlyTypes]);

    const companiesLists: IOption[] = companies.map(c => {
        return {
            value: c.company_id,
            label: c.company_name,
            description: c.company_name,
            isLeaf: false,
        };
    });

    const handleChange = (value: number, option: any) => {
        setCompanyId(value);
        props.onChange && props.onChange(value, option);
    };

    return (
        <Select
            size="large"
            placeholder="Please select company"
            options={companiesLists}
            value={companyId}
            onChange={handleChange}
            style={{ width: '100%' }}
            showSearch
            filterOption={filterSelectorOption}
            {...props}
        />
    );
};

import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { cloneDeep, get, has, keys, set } from 'lodash';
import { InfoCircleOutlined } from '@ant-design/icons';
import WarningOutlined from '@ant-design/icons/WarningOutlined';
import { TableColumnsType } from 'antd/lib';
import Table from 'antd/lib/table';
import Tag from 'antd/lib/tag';
import Tooltip from 'antd/lib/tooltip';
import { toAllowedStringLength } from '../../../domain/common/formattersToAllowedValueLength';
import { PRIMARY_COLOR, WARNING_COLOR } from '../../../theme';
import { SiteAddress } from '../SiteAddress';
import { IMergeSitesContext, MergeSitesContext } from './MergeSitesContext';
import { MergeSitesTableUtils } from './MergeSitesTableUtils';
import { YearlyEventsCountInfo } from './YearlyEventsCountInfo';
import { ITransformedTableItem } from './interface';
import { MergeSitesMap } from './MergeSitesMap';
import { ShortVenInfo } from './ShortVenInfo';
import './merge-sites-table.css';

const NOT_SELECTABLE_CELL_BG = 'rgba(212, 212, 212, 0.2)';

interface IMergeSitesTableProps {}

export const MergeSitesTable: React.FC<IMergeSitesTableProps> = () => {
    const { resultsColumnData, setResultsColumnData, sites, eventsCountBySites } =
        useContext<IMergeSitesContext>(MergeSitesContext);
    const mergingSites = useMemo(
        () => MergeSitesMap.toTableSites(sites, eventsCountBySites),
        [eventsCountBySites, sites]
    );
    const tableData = useMemo(() => MergeSitesTableUtils.transformSiteToTableView(mergingSites), [mergingSites]);

    const handleCellClick = (siteId: number, propertyName: string) => {
        const site = mergingSites.find(site => site.site_id === siteId);
        const updatedResultsColumnData = cloneDeep(resultsColumnData);
        const cellKey = getCellKey(siteId, propertyName);
        const cellValue = get(site, propertyName);
        const propertyRelatedKeys = keys(resultsColumnData).filter(key => key.endsWith(`_${propertyName}`));

        if (MergeSitesTableUtils.MERGEABLE_COLUMNS.includes(propertyName)) {
            // check if result has same other properties selected
            if (has(resultsColumnData, cellKey) && propertyRelatedKeys.filter(key => key !== cellKey).length > 0) {
                delete updatedResultsColumnData[cellKey];
            } else {
                set(updatedResultsColumnData, cellKey, cellValue);
            }
        } else {
            // Remove all keys that are related to the property
            propertyRelatedKeys.forEach(key => {
                delete updatedResultsColumnData[key];
            });

            // Add the selected cell
            set(updatedResultsColumnData, cellKey, cellValue);
        }

        setResultsColumnData(updatedResultsColumnData);
    };

    const handleColumnClick = useCallback(
        (siteId: number) => {
            const updatedResultsColumnData = {};

            const mergingSite = mergingSites.find(site => site.site_id === siteId);

            if (!mergingSite) return;

            Object.keys(mergingSite).forEach(propertyName => {
                const cellKey = getCellKey(mergingSite.site_id, propertyName);

                set(updatedResultsColumnData, cellKey, get(mergingSite, propertyName, null));
            });

            setResultsColumnData(updatedResultsColumnData);
        },
        [mergingSites, setResultsColumnData]
    );

    useEffect(() => {
        if (mergingSites.length === 0) return;

        // If there are already selected cells, do not select the first site
        if (Object.keys(resultsColumnData).length > 0) return;

        const mergingSite = mergingSites[0];

        handleColumnClick(mergingSite.site_id);
    }, [handleColumnClick, mergingSites, resultsColumnData]);

    const columns: TableColumnsType<ITransformedTableItem> = [
        {
            title: 'Site Name',
            dataIndex: 'header',
            key: 'header',
            rowScope: 'row',
            width: 140,
            render: (key: keyof typeof MergeSitesTableUtils.MAPPED_HEADERS) => {
                if (MergeSitesTableUtils.MERGEABLE_COLUMNS.includes(key)) {
                    return (
                        <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                            {MergeSitesTableUtils.MAPPED_HEADERS[key]}
                            <Tooltip title="Allowed to select multiple values in this column">
                                <InfoCircleOutlined
                                    style={{ color: PRIMARY_COLOR, fontSize: '16px', paddingLeft: '8px' }}
                                />
                            </Tooltip>
                        </span>
                    );
                }

                if (['site_price_response_enrolled', 'site_clean_response_enrolled'].includes(key)) {
                    return (
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                            {MergeSitesTableUtils.MAPPED_HEADERS[key]}
                            <Tooltip title="Enrollment will be applied only if selected Load Zone supports it">
                                <WarningOutlined
                                    style={{ color: WARNING_COLOR, fontSize: '16px', paddingLeft: '8px' }}
                                />
                            </Tooltip>
                        </span>
                    );
                }

                return MergeSitesTableUtils.MAPPED_HEADERS[key];
            },
            onHeaderCell: () => ({
                style: {
                    backgroundColor: NOT_SELECTABLE_CELL_BG,
                },
            }),
            onCell: () => ({
                style: {
                    backgroundColor: NOT_SELECTABLE_CELL_BG,
                },
            }),
        },

        ...mergingSites.map((site, index) => ({
            title: (
                <TitleCell
                    key={`header_${site.site_id}_${index}`}
                    siteId={site.site_id}
                    siteName={site.site_name}
                    selectedSiteId={MergeSitesTableUtils.mergeResultsColumnValues(resultsColumnData)?.site_id}
                />
            ),
            dataIndex: `site_${site.site_id}`,
            key: `site_${site.site_id}_${index}`,
            width: 220,
            onHeaderCell: () => {
                const cellProp = 'site_name';
                const cellKey = getCellKey(site.site_id, cellProp);
                const isCellSelected = Object.keys(resultsColumnData).includes(cellKey);

                return {
                    onClick: () => {
                        handleCellClick(site.site_id, cellProp);
                    },
                    onDoubleClick: () => {
                        handleColumnClick(site.site_id);
                    },
                    style: {
                        cursor: 'pointer',
                        backgroundColor: isCellSelected ? MergeSitesTableUtils.SELECTED_CELL_BG_COLOR : 'inherit',
                    },
                };
            },
            onCell: (record: ITransformedTableItem) => {
                const cellKey = getCellKey(site.site_id, record.header);
                const isCellSelected = Object.keys(resultsColumnData).includes(cellKey);

                return {
                    onClick: () => {
                        handleCellClick(site.site_id, record.header);
                    },
                    style: {
                        cursor: 'pointer',
                        backgroundColor: isCellSelected ? MergeSitesTableUtils.SELECTED_CELL_BG_COLOR : 'inherit',
                    },
                };
            },
            render: (value: any, record: ITransformedTableItem) => {
                return <TableCell propertyName={record.header} value={value} />;
            },
        })),

        {
            title: MergeSitesTableUtils.mergeResultsColumnValues(resultsColumnData)?.site_name,
            dataIndex: 'result',
            key: 'result',
            rowScope: 'row',
            width: 220,
            render: (_, record) => {
                const property = record.header;

                return (
                    <TableCell
                        propertyName={property}
                        value={get(MergeSitesTableUtils.mergeResultsColumnValues(resultsColumnData), property)}
                    />
                );
            },
            onHeaderCell: () => ({
                style: {
                    backgroundColor: NOT_SELECTABLE_CELL_BG,
                },
            }),
            onCell: () => ({
                style: {
                    backgroundColor: NOT_SELECTABLE_CELL_BG,
                    fontWeight: 'normal',
                },
            }),
        },
    ];

    return (
        <Table
            bordered
            rowKey={makeRowKeyClosure('merge-site-row')}
            columns={columns}
            dataSource={tableData}
            pagination={false}
            className="merge-sites-table"
            scroll={{ x: 'scroll' }}
        />
    );
};

function TableCell({ propertyName, value }: { propertyName: string; value: any }) {
    const { sites, eventsCountBySites } = useContext<IMergeSitesContext>(MergeSitesContext);

    if (value === undefined || value === null) return '';

    switch (propertyName) {
        case 'site_store_number':
        case 'site_utility':
        case 'site_network':
        case 'partner_id':
        case 'site_resource_id':
        case 'control_provider_name':
        case 'utility_customer_name':
        case 'marketAndLoadZone':
            return value;

        case 'sans':
            const sans = value as string[];

            return sans.map((san: string) => (
                <Tooltip title={san} key={san}>
                    <Tag color="gold" style={{ marginBottom: 4 }}>
                        {toAllowedStringLength(san)}
                    </Tag>
                </Tooltip>
            ));

        case 'programs':
            const programs = value as string[];

            return programs.map((program: string) => (
                <Tooltip title={program} key={program}>
                    <Tag color="gold" style={{ marginBottom: 4 }}>
                        {toAllowedStringLength(program)}
                    </Tag>
                </Tooltip>
            ));

        case 'site_label':
            const siteLabels = value as string[];

            return siteLabels.map((label: string) => (
                <Tooltip title={label} key={label}>
                    <Tag style={{ marginBottom: 4 }}>{toAllowedStringLength(label)}</Tag>
                </Tooltip>
            ));

        case 'site_highlight':
        case 'email_only_integration':
        case 'site_clean_response_enrolled':
        case 'site_price_response_enrolled':
            return value ? 'Yes' : 'No';

        case 'location':
            return <SiteAddress address={value.site_address} city={value.site_city} state={value.site_state} />;

        case 'cloudVensFromSiteIds':
            const siteIds = value as number[];

            return MergeSitesTableUtils.getCloudVensBySiteIds(siteIds, sites).map(ven => (
                <ShortVenInfo key={ven.ven_id} ven={ven} />
            ));

        case 'customerVenFromSiteId':
            return <ShortVenInfo ven={MergeSitesTableUtils.getCustomerVenBySiteId(value, sites)} />;

        case 'eventsFromSiteId':
            return (
                <YearlyEventsCountInfo
                    eventsCountByYear={MergeSitesTableUtils.getEventsCountDataBySiteId(value, eventsCountBySites)}
                />
            );

        default:
            return JSON.stringify(value);
    }
}

interface ITitleCellProps {
    siteId: number;
    siteName: string;
    selectedSiteId?: number;
}

const TitleCell: React.FC<ITitleCellProps> = ({ siteId, siteName, selectedSiteId }) => {
    return (
        <span>
            {siteName}
            {selectedSiteId === siteId && (
                <Tag color={PRIMARY_COLOR} style={{ marginLeft: 8 }}>
                    Primary
                </Tag>
            )}
        </span>
    );
};

function getCellKey(siteId: number, propertyName: string): string {
    return `${siteId}_${propertyName}`;
}

function makeRowKeyClosure(prefix: string) {
    let rowIndex = 0;
    return () => `${prefix}-${rowIndex++}`;
}

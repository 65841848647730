import { useMutation, useQuery } from '@tanstack/react-query';
import { QueryKey } from '../../query-client';
import { IFetchPageQuery } from '../IFetchQueryOptions';
import { deleteCleanResponseTrigger, fetchCleanResponseTriggers, saveCleanResponseTrigger } from './index';
import { ICleanResponseTrigger } from './interface';

export const useCleanResponseTriggerListQuery = (query: IFetchPageQuery, options = {}) => {
    return useQuery({
        queryKey: [QueryKey.CLEAN_RESPONSE_TRIGGERS, query],
        queryFn: () => fetchCleanResponseTriggers(query),
        ...options,
    });
};

export const useSaveCleanResponseTriggerMutation = () => {
    return useMutation<ICleanResponseTrigger, Error, ICleanResponseTrigger>({
        mutationFn: trigger => saveCleanResponseTrigger(trigger),
    });
};

export const useDeleteCleanResponseTriggerMutation = () => {
    return useMutation<void, Error, ICleanResponseTrigger>({
        mutationFn: trigger => deleteCleanResponseTrigger(trigger),
    });
};

import { ISiteEnergyUsageData } from './interface';

export enum ENERGY_USAGE_INTERVAL_INDEX {
    TEN_DAYS = 1,
    FIVE_DAYS = 2,
    THREE_HOURS = 3,
}

export enum ENERGY_USAGE_TYPE {
    BASELINE = 'baseline',
    PERFORMANCE = 'performance',
}

/**
 *  function returns an array of baseline or performance for each site-san for specific interval
 *  !!! important: we summarize all values under the same timestamps for performance and baseline
 **/
export const getUsageData = (
    sitesEnergyUsageDataResults: ISiteEnergyUsageData[] | undefined,
    usageType: ENERGY_USAGE_TYPE,
    interval = ENERGY_USAGE_INTERVAL_INDEX.TEN_DAYS
): [number, number][] => {
    const result: [number, number][] = [];

    if (!sitesEnergyUsageDataResults || sitesEnergyUsageDataResults?.length === 0) {
        return result;
    }

    // summarize all values under the same timestamp
    sitesEnergyUsageDataResults.forEach(({ sanUsages }) => {
        const sansUsageValues: [number, number][] = [];

        sanUsages.forEach(sanUsage => {
            // baseline or performance values for one san
            const usageValues = sanUsage[usageType]?.values;

            if (usageValues && usageValues.length > 0) {
                const data: [number, number][] = usageValues.map(value => {
                    const timestamp = value[0];
                    const performanceValue = value[interval];
                    return [timestamp, performanceValue];
                });

                sansUsageValues.push(...data);
            }
        });

        const summarizedSiteUsage = sumSameTimestampValues(sansUsageValues);
        result.push(...summarizedSiteUsage);
    });

    return result;
};

function sumSameTimestampValues(data: [number, number][]): [number, number][] {
    const summary: { [timestamp: number]: number } = {};

    // Group and sum values by timestamp
    data.forEach(([timestamp, value]) => {
        if (summary[timestamp]) {
            summary[timestamp] += value;
        } else {
            summary[timestamp] = value;
        }
    });

    // Convert the summary object back to an array of tuples
    return Object.entries(summary).map(([timestamp, value]) => [Number(timestamp), value] as [number, number]);
}

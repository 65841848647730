import BankOutlined from '@ant-design/icons/BankOutlined';
import CalendarOutlined from '@ant-design/icons/CalendarOutlined';
import FileOutlined from '@ant-design/icons/FileOutlined';
import HomeOutlined from '@ant-design/icons/HomeOutlined';
import AuditOutlined from '@ant-design/icons/lib/icons/AuditOutlined';
import BulbOutlined from '@ant-design/icons/lib/icons/BulbOutlined';
import DatabaseOutlined from '@ant-design/icons/lib/icons/DatabaseOutlined';
import DollarOutlined from '@ant-design/icons/lib/icons/DollarOutlined';
import EnvironmentOutlined from '@ant-design/icons/lib/icons/EnvironmentOutlined';
import GlobalOutlined from '@ant-design/icons/lib/icons/GlobalOutlined';
import LineChartOutlined from '@ant-design/icons/lib/icons/LineChartOutlined';
import SyncOutlined from '@ant-design/icons/lib/icons/SyncOutlined';
import ProfileOutlined from '@ant-design/icons/ProfileOutlined';
import RobotOutlined from '@ant-design/icons/RobotOutlined';
import UserOutlined from '@ant-design/icons/UserOutlined';
import ControlOutlined from '@ant-design/icons/ControlOutlined';
import Badge from 'antd/lib/badge';
import Menu from 'antd/lib/menu';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../domain/auth/useAuth';
import { DAY_MS } from '../../domain/commonConst';
import { fetchLatestSettlements } from '../../domain/settlement';
import { getActiveSiteSan } from '../../domain/site/getActiveSiteSan';
import { useSiteListQuery } from '../../domain/site/queries';
import { UserType } from '../../domain/user/interface';
import { AbilityContext } from '../ability/can';
import { CoinsSVG, LeafSVG } from '../icons/icons';
import { useCompanies } from '../selectors/GlobalCompanySelector/CompanyContext';
import { SiteIntervalDataFilters } from '../../domain/site/interface';
import './AppNavigation.css';

const iconStyle = { fontSize: '20px', verticalAlign: 'middle' };

export const AppNavigation = () => {
    const [cookies] = useCookies(['settlement_viewed']);
    const [latestSettlement, setLatestSettlement] = useState<number>(0);
    const location = useLocation();
    const { companyId } = useCompanies()!;
    const companyQuery = companyId ? `?companyId=${companyId}` : '';

    const ability = useContext(AbilityContext);

    // if there is no cookie "settlement_viewed" set - check only one last day
    const timestampToCheck = cookies.settlement_viewed ?? new Date().valueOf() - DAY_MS;

    useEffect(() => {
        if (ability.can('read', 'Settlement')) {
            (async () => {
                const data = await fetchLatestSettlements(timestampToCheck);
                data.length && setLatestSettlement(data.length);
            })();
        }
    }, []);

    useEffect(() => {
        setLatestSettlement(0);
    }, [cookies]);

    const defaultSubMenu = useGetUserDefaultSubMenu(companyQuery);

    const navigationOptions = [
        {
            label: <Link to={`/${companyQuery}`}>Dashboard</Link>,
            key: '/',
            icon: <EnvironmentOutlined />,
        },
        {
            label: 'My Org',
            key: 'my-org',
            icon: <GlobalOutlined />,
            popupClassName: 'navigation-item',
            children: [
                ...(ability.can('read', 'Company')
                    ? [
                          {
                              label: (
                                  <Link to={`/companies${companyQuery}`} data-cy="navigation-company">
                                      Companies
                                  </Link>
                              ),
                              icon: <HomeOutlined />,
                              key: '/companies',
                          },
                      ]
                    : []),
                {
                    label: <Link to={`/sites${companyQuery}`}>Sites</Link>,
                    icon: <BankOutlined />,
                    key: '/sites',
                },
                ...(ability.can('view-route', '/ven-devices/overview')
                    ? [
                          {
                              label: <Link to={`/ven-devices/overview${companyQuery}`}>VEN</Link>,
                              icon: <RobotOutlined />,
                              key: '/ven-devices/overview',
                          },
                      ]
                    : []),
                {
                    label: <Link to={`/users${companyQuery}`}>Users</Link>,
                    icon: <UserOutlined />,
                    key: '/users',
                },
                ...(ability.can('view-route', '/admin/data-quality')
                    ? [
                          {
                              label: <Link to={`/admin/data-quality/by-company${companyQuery}`}>Admin Dashboard</Link>,
                              icon: <AuditOutlined />,
                              key: '/admin/data-quality/by-company',
                          },
                      ]
                    : []),
            ],
        },
        {
            label: 'Analytics',
            key: 'analytics',
            icon: <LineChartOutlined />,
            popupClassName: 'navigation-item',
            children: [
                ...(ability.can('read', 'ImpactReporting')
                    ? [
                          {
                              label: <Link to={`/impact${companyQuery}`}>Impact</Link>,
                              icon: <LeafSVG style={iconStyle} />,
                              key: '/impact',
                          },
                      ]
                    : []),
                {
                    label: <Link to={defaultSubMenu}>Energy Usage Data</Link>,
                    icon: <SyncOutlined style={iconStyle} />,
                    key: defaultSubMenu,
                },
                ...(ability.can('read', 'Settlement')
                    ? [
                          {
                              label: (
                                  <Link to={`/settlements${companyQuery}`}>
                                      Settlements
                                      {latestSettlement ? (
                                          <Badge count={latestSettlement} offset={[0, -15]} size="small"></Badge>
                                      ) : null}
                                  </Link>
                              ),
                              icon: <FileOutlined />,
                              key: '/settlements',
                          },
                      ]
                    : []),
                ...(ability.can('read', 'PriceResponseReporting')
                    ? [
                          {
                              label: <Link to={`/reporting/price-response${companyQuery}`}>Price Response</Link>,
                              icon: <CoinsSVG style={iconStyle} />,
                              key: '/reporting/price-response',
                          },
                      ]
                    : []),
                //   ...(ability.can('read', 'PowerBIReporting')
                //       ? [
                //         {
                //           label: <Link to={`/reporting/power-bi`}>Power BI (demo)</Link>,
                //           icon: <BarChartOutlined style={iconStyle} />,
                //           key: '/reporting/power-bi',
                //         },
                //       ]
                //       : []),
            ],
        },
        {
            label: 'Energy Market',
            icon: <BulbOutlined />,
            key: 'energy-market',
            popupClassName: 'navigation-item',
            children: [
                {
                    label: <Link to={`/events${companyQuery}`}>Events</Link>,
                    icon: <CalendarOutlined />,
                    key: '/events',
                },
                ...(ability.can('read', 'PriceResponse')
                    ? [
                          {
                              label: <Link to={`/trigger/price-response${companyQuery}`}>Price Response</Link>,
                              icon: <DollarOutlined />,
                              key: '/trigger/price-response',
                          },
                      ]
                    : []),
                ...(ability.can('read', 'CleanResponse')
                    ? [
                          {
                              label: <Link to={`/trigger/clean-response${companyQuery}`}>Clean Response</Link>,
                              icon: <LeafSVG />,
                              key: '/trigger/clean-response',
                          },
                      ]
                    : []),
                ...(ability.can('read', 'Program')
                    ? [
                          {
                              label: <Link to={`/programs${companyQuery}`}>Programs</Link>,
                              icon: <ProfileOutlined />,
                              key: '/programs',
                          },
                      ]
                    : []),

                ...(ability.can('read', '/caiso/sibr')
                    ? [
                          {
                              label: <Link to={`/caiso/sibr${companyQuery}`}>CAISO</Link>,
                              icon: <DatabaseOutlined />,
                              key: '/caiso',
                          },
                      ]
                    : []),

                ...(ability.can('read', 'NyisoResource')
                    ? [
                          {
                              label: <Link to={`/nyiso-resources${companyQuery}`}>NYISO</Link>,
                              icon: <ControlOutlined />,
                              key: '/nyiso-resources',
                          },
                      ]
                    : []),
            ],
        },
    ];

    const selectedKeys = [
        location.pathname === '/'
            ? location.pathname
            : location.pathname.startsWith('/energy-usage-data')
              ? defaultSubMenu
              : location.pathname,
    ];

    return <Menu mode="horizontal" selectedKeys={selectedKeys} items={navigationOptions} className="app-navigation" />;
};

export function useGetUserDefaultSubMenu(companyQuery: string): string {
    const auth = useAuth();
    const { data, isLoading } = useSiteListQuery({
        filter: { interval_data: SiteIntervalDataFilters.RECEIVING_DATA },
        pagination: { pageSize: 1, current: 1 },
    });
    const sites = data?.data || [];

    const defaultSubMenu = useMemo(() => {
        const hasAnyDataConnected = sites?.some(site => {
            const activeSan = getActiveSiteSan(site);

            return activeSan?.san_info?.latestIntervalDataDate !== null;
        });

        if (auth?.user?.user_type === UserType.ADMIN || auth?.user?.user_type === UserType.ACCOUNT_MANAGER) {
            // Admin & account manager
            return `/energy-usage-data/analytics${companyQuery}`;
        }

        if (
            (auth?.user?.user_type === UserType.CUSTOMER || auth?.user?.user_type === UserType.OPERATOR) &&
            hasAnyDataConnected
        ) {
            // Customer or operator with data connected
            return `/energy-usage-data/analytics${companyQuery}`;
        }

        // Default for all other cases
        return `/energy-usage-data/connect${companyQuery}`;
    }, [auth, sites, companyQuery]);

    return defaultSubMenu;
}

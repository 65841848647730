import React, { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import LockOutlined from '@ant-design/icons/LockOutlined';
import Result from 'antd/lib/result';
import Button from 'antd/lib/button';
import Typography from 'antd/lib/typography';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import { completeSignup } from '../../domain/auth/auth';
import logoImg from '../../logo.png';
import App from 'antd/lib/app';
import { useDocumentTitle } from '../../components/useDocumentTitle';

export const CompleteSignup = () => {
    const { notification } = App.useApp();
    const [form] = Form.useForm();
    const navigation = useNavigate();
    const [loading, setLoading] = useState(false);
    const [isFinished, setIsFinished] = useState(false);
    const { token } = useParams<{ token: string }>();

    useDocumentTitle('Complete Signup');

    const passwordsMatchValidator = (_: any, value: string) => {
        const password = form.getFieldValue('password');
        if (!password || password === value) {
            return Promise.resolve();
        } else {
            return Promise.reject(new Error('The two passwords that you entered do not match!'));
        }
    };

    const onFinish = async (values: { password: string }) => {
        setLoading(true);
        try {
            await completeSignup({ password: values.password, token });
            setIsFinished(true);
        } catch (error: any) {
            notification.error({
                key: 'complete-signup-error',
                message: 'Complete Signup Error',
                description: error.message,
            });
        }
        setLoading(false);
    };

    return (
        <>
            <img className="logo" src={logoImg} alt="Enersponse logo" />

            {isFinished ? (
                <Result
                    status="success"
                    title="Signup Completed!"
                    subTitle="Now you can login with your new password"
                    extra={
                        <Button type="primary" style={{ marginTop: '16px' }} onClick={() => navigation('/login')}>
                            Login
                        </Button>
                    }
                />
            ) : (
                <>
                    <Typography.Title level={2} type="secondary" className="form-title">
                        Complete Signup
                    </Typography.Title>
                    <Form form={form} name="confirm_signup_form" onFinish={onFinish}>
                        <Form.Item
                            name="password"
                            rules={[
                                {
                                    pattern: /((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,})).*$/,
                                    message:
                                        'Password must have at least 6 characters that include at least 1 lowercase letter, 1 uppercase letter, number, and one special symbol, e.g. !-_;$@#^%&',
                                },
                                { required: true, message: 'Please input your password!' },
                            ]}
                        >
                            <Input prefix={<LockOutlined />} type="password" placeholder="Password" />
                        </Form.Item>
                        <Form.Item
                            name="confirm"
                            rules={[
                                {
                                    pattern: /((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,})).*$/,
                                    message:
                                        'Password must have at least 6 characters that include at least 1 lowercase letter, 1 uppercase letter, number, and one special symbol, e.g. !-_;$@#^%&',
                                },
                                { required: true, message: 'Please confirm your password!' },
                                { validator: passwordsMatchValidator },
                            ]}
                            dependencies={['password']}
                        >
                            <Input prefix={<LockOutlined />} type="password" placeholder="Confirm Password" />
                        </Form.Item>
                        <Form.Item noStyle>
                            <Button loading={loading} type="primary" htmlType="submit" className="login-form-button">
                                Confirm
                            </Button>
                        </Form.Item>
                    </Form>
                </>
            )}

            {!isFinished && (
                <Link to="/" style={{ display: 'block', marginTop: 24 }}>
                    Back to Login
                </Link>
            )}
        </>
    );
};

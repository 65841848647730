import { QueryClient } from '@tanstack/react-query';

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            cacheTime: 1000 * 60 * 5, // 5 minutes // default is 5 minutes
            staleTime: 0, // always keep fresh data // default is 0
            retry: 3, // 3 times before sending error
            retryDelay: attemptIndex => Math.min(1000 * 2 ** attemptIndex, 30000), // 2 seconds, 4 seconds, 8 seconds
        },
    },
});

export enum QueryKey {
    SITES = 'sites',
    PROGRAM_TYPES = 'programTypes',
    ACCESS_PARTNERS = 'accessPartners',
    ALERT_TRACKS = 'alertTracks',
    ALERT_TRACKING_USERS = 'alertTrackingUsers',
    AUDIT_TRAIL = 'auditTrail',
    COMPANIES = 'companies',
    PROGRAMS = 'programs',
    PROGRAM_BY_COMPANY = 'programsByCompany',
    PROGRAM_OPERATORS = 'programOperators',
    USERS = 'users',
    IDENTIFY_MARKET_ZONE = 'identifyMarketZone',
    MARKET_ZONES = 'marketZones',
    ASYNC_JOB = 'asyncJob',
    SITE_LABELS = 'siteLabels',
    SITE_LOCATIONS = 'siteLocations',
    SITE_SAN_INFO = 'siteSanInfo',
    SITE_UTILITIES = 'siteUtilities',
    IMPACT = 'impact',
    UNKNOWN_VEN_ERRORS = 'unknownVenErrors',
    VEN_OVERVIEW = 'venOverview',
    CUSTOMER_VENS = 'customerVens',
    CUSTOMER_VENS_CONTROL_PROVIDERS = 'customerVensControlProviders',
    CLOUD_VENS = 'cloudVens',
    CLOUD_VEN_LOGS = 'cloudVenLogs',
    CLOUD_VEN_CERTIFICATE = 'cloudVenCertificate',
    PRICE_RESPONSE_TRIGGERS = 'priceResponseTriggers',
    CLEAN_RESPONSE_TRIGGERS = 'clearResponseTriggers',
    PRICE_RESPONSE_REPORTING = 'priceResponseReporting',
    DRRS_LOCATIONS = 'drrsLocations',
    DRRS_LOCATION_SANS = 'drrsLocationSans',
    DRRS_LOCATION_STATUSES = 'drrsLocationStatuses',
    DRRS_LOCATION_UDCS = 'drrsLocationUdcs',
    DRRS_LOCATION_SUBLAPS = 'drrsLocationSublaps',
    DRRS_LOCATION_RESOURCE_NAMES = 'drrsLocationResourceNames',
    DRRS_LOCATION_RESOURCE_IDS = 'drrsLocationLocationIds',
    DRRS_REGISTRATIONS = 'drrsRegistrations',
    DRRS_REGISTRATION_STATUSES = 'drrsRegistrationStatuses',
    DRRS_REGISTRATION_UDCS = 'drrsRegistrationUdcs',
    DRRS_REGISTRATION_SUBLAPS = 'drrsRegistrationSublaps',
    DRRS_REGISTRATION_RESOURCE_IDS = 'drrsRegistrationResourceIds',
    DRRS_REGISTRATION_LOCATIONS = 'drrsRegistrationLocations',
    DRRS_SYNCS = 'drrsSyncs',
    UTILITIES = 'utilities',
    GREENBUTTONS = 'greenbuttons',
    CARBON_INTENSITY = 'carbonIntensity',
    REAL_TIME_PRICES = 'realTimePrices',
    SETTLEMENTS = 'settlements',
    EVENTS = 'events',
    EVENTS_METRICS = 'eventsMetrics',
    EVENTS_COUNT = 'eventsCount',
    RESOURCES = 'resources',
    INTERVAL_DATA_REPORTING = 'intervalDataReporting',
    ENERGY_USAGES = 'energyUsages',
    WEATHER_FORECAST = 'weatherForecast',
    PRODUCTS = 'products',
    ACCOUNTS = 'accounts',
    METERS = 'meters',
    NYISO_RESOURCES = 'nyiso-resources',
    NYISO_SEASON = 'nyiso-season',
    INBOX_FILE = 'inbox-file',
    SC_RESOURCE_BIDS = 'sc-resource-bids',
    READINGS_AVAILABILITY_STATS = 'readingsAvailabilityStats',
}

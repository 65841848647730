export const DATA_AVAILABILITY_PERCENTAGES_MAP = {
    '0': { start: 0, end: 0 },
    '1-80': { start: 1, end: 80 },
    '81-99': { start: 81, end: 99 },
    '100': { start: 100, end: 100 },
};

export type TKeyofDataAvailabilityPercentagesMap = keyof typeof DATA_AVAILABILITY_PERCENTAGES_MAP;

export function buildMonthlyReadingsAvailabilityFilter() {
    return Object.keys(DATA_AVAILABILITY_PERCENTAGES_MAP).map(percent => ({
        text: `${percent} %`,
        value: percent,
    }));
}

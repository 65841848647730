import { useMemo } from 'react';
import { useMarketZoneListQuery } from '../market/queries';

export const useMarketOptions = ({ includeOther = false } = {}) => {
    const { data: marketZones } = useMarketZoneListQuery();

    return useMemo(() => {
        if (!marketZones) return [];

        const marketZoneOptions = Object.keys(marketZones).map(market => ({
            text: market.toUpperCase(),
            value: market,
        }));

        if (includeOther) {
            marketZoneOptions.push({ text: 'Without Market', value: 'null' });
        }

        return marketZoneOptions;
    }, [marketZones, includeOther]);
};

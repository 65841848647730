import DownloadOutlined from '@ant-design/icons/DownloadOutlined';
import ExclamationCircleOutlined from '@ant-design/icons/ExclamationCircleOutlined';
import HomeOutlined from '@ant-design/icons/HomeOutlined';
import CodeFilled from '@ant-design/icons/lib/icons/CodeFilled';
import HistoryOutlined from '@ant-design/icons/lib/icons/HistoryOutlined';
import PlusCircleOutlined from '@ant-design/icons/PlusCircleOutlined';
import App from 'antd/lib/app';
import Button from 'antd/lib/button';
import Select from 'antd/lib/select';
import Space from 'antd/lib/space';
import Table from 'antd/lib/table';
import { ColumnsType } from 'antd/lib/table/interface';
import Tag from 'antd/lib/tag';
import Tooltip from 'antd/lib/tooltip';
import Typography from 'antd/lib/typography';

import format from 'date-fns/format';
import { useContext, useMemo, useState } from 'react';
import { useLocation, useOutletContext } from 'react-router-dom';
import { AbilityContext } from '../../../components/ability/can';
import { AuditTrailModal } from '../../../components/modals/AuditTrailModal/AuditTrailModal';

import { PageHeader } from '../../../components/pageHeader/pageHeader';
import { SearchInput } from '../../../components/searchInput/searchInput';
import { ActionList } from '../../../components/table/actionList/actionList';
import { getColumnFilteredValue, getColumnSortOrder } from '../../../components/table/columnFormatHelpers';
import { WithEmptyDataTable } from '../../../components/table/withEmptyDataTable';
import { FORM_MODE, useFormMode } from '../../../components/useFormMode';
import { VenIcon } from '../../../components/VenIcon/VenIcon';
import { IView } from '../../../components/viewSwitcher/viewInterface';
import { ViewSwitcher } from '../../../components/viewSwitcher/ViewSwitcher';
import config from '../../../config';
import { AuditEntityType } from '../../../domain/audit-trail/interface';
import { DATE_FORMAT_CSV, DEFAULT_PAGINATION, PAGE_ACTION } from '../../../domain/commonConst';
import { IPaginationMeta } from '../../../domain/IPagination';
import { ISite } from '../../../domain/site/interface';
import { validatePagination } from '../../../domain/validatePagination';
import { exportCustomerVens } from '../../../domain/ven';
import { ICustomerVen, IFetchVenPageQuery } from '../../../domain/ven/interface';
import {
    useDeleteCustomerVenMutation,
    useCustomerVenListQuery,
    useCustomerVenControlProvidersListQuery,
} from '../../../domain/ven/queries';
import { toUsefulParams } from '../../toUsefulParams';
import { usePageLocation } from '../../usePageState';
import { CustomerVenFormModal } from '../components/CustomerVenFormModal';
import { RemoteAccessModal } from '../components/RemoteAccessModal/RemoteAccessModal';
import { venStatusFilter, viewOptions } from './venViewInterface';
import { IFetchPageQuery } from 'src/domain/IFetchQueryOptions';

export const CustomerVenView = () => {
    const { notification, modal } = App.useApp();
    const ability = useContext(AbilityContext);
    const location = useLocation();
    const { setPageQuery, queryToState } = usePageLocation();
    const pageState: any = queryToState(location.search);

    const [hardwareCustomerVen, setHardwareCustomerVen] = useState<ICustomerVen | Partial<ICustomerVen>>();
    const [customerVen, setCustomerVen] = useState<ICustomerVen | Partial<ICustomerVen>>();
    const [exportLoading, setExportLoading] = useState<boolean>(false);
    const [venAction, setVenAction] = useState<PAGE_ACTION | ''>();

    const { mutateAsync: deleteCustomerVen } = useDeleteCustomerVenMutation();

    const { onSetFormMode, isEditMode, formMode } = useFormMode();
    const { company, view, handleViewMode, companies, auth }: IView = useOutletContext();

    const listQuery: IFetchVenPageQuery = {
        hideRelatedToClosedSites: true,
        pagination: pageState.pagination,
        sorter: pageState.sorter,
        search: pageState.search,
        company_id: company,
        control_providers_id:
            pageState?.filter && pageState.filter.control_providers_id ? pageState.filter.control_providers_id : null,
        ...(pageState.status && { status: pageState.status }),
        ...(pageState.type && { type: pageState.type }),
    };

    const {
        data,
        isLoading,
        refetch: refetchCustomerVens,
    } = useCustomerVenListQuery(listQuery, {
        keepPreviousData: true,
    });
    const customerVens = data?.data || [];
    const meta = data?.meta || ({ total: 0 } as IPaginationMeta);

    const controlProvidersQuery: IFetchPageQuery = {
        company_id: company,
    };

    const { data: controlProvidersData } = useCustomerVenControlProvidersListQuery(controlProvidersQuery, {
        keepPreviousData: true,
    });

    const controlProviders = useMemo(() => {
        const _defaultFilterItem = {
            text: 'Without Provider',
            value: 'null',
        };

        const providersFilterItems =
            controlProvidersData?.map(provider => {
                return {
                    text: provider.company_name,
                    value: provider.company_id,
                };
            }) || [];

        return [_defaultFilterItem, ...providersFilterItems];
    }, [controlProvidersData]);

    async function exportCustomerVensAction() {
        try {
            setExportLoading(true);
            const currentCompany = companies!.find(c => c.company_id === company);
            const companyName = currentCompany?.company_name || '';
            const fileName = `${companyName}-customerVens--${format(new Date(), DATE_FORMAT_CSV)}.csv`;

            await exportCustomerVens(toUsefulParams(pageState), fileName);
        } catch (err: any) {
            notification.error({
                key: 'customer-ven-export-error',
                message: err.message || 'Cannot export customer VENs!',
            });
        }
        setExportLoading(false);
    }

    const handleFieldChange = (field: string) => (value: any) => {
        setPageQuery({ ...pageState, [field]: value });
    };

    const handleSearch = (value: string) => {
        if (value === pageState.search) {
            setPageQuery({
                ...pageState,
                pagination: { ...pageState.pagination, current: DEFAULT_PAGINATION.current },
            });
            return;
        }

        setPageQuery({ ...pageState, search: value });
    };

    async function onTableChange(pagination: any, filter: any, sorter: any) {
        const preparedParams = toUsefulParams({ pagination, filter, sorter });

        setPageQuery({ ...pageState, ...preparedParams });
    }

    function deleteCustomerVenWithConfirm(selectedVen: ICustomerVen) {
        return () => {
            modal.confirm({
                icon: <ExclamationCircleOutlined />,
                content: `Deleting customer VEN ${selectedVen.ven_username || ''}. Are you sure?`,
                onOk: async () => {
                    await deleteCustomerVenAction(selectedVen);
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        };
    }

    async function deleteCustomerVenAction(selectedVen: ICustomerVen) {
        try {
            await deleteCustomerVen(selectedVen);

            const validPagination = validatePagination(pageState.pagination, meta.total - 1);

            setPageQuery({ ...pageState, pagination: validPagination });
            await refetchCustomerVens({ ...pageState, pagination: validPagination });

            notification.info({ key: 'customer-ven-delete-info', message: 'Customer VEN deleted' });
        } catch (err: any) {
            notification.error({ key: 'customer-ven-delete-error', message: err.message || 'Cannot delete VEN!' });
        }
    }

    const showNewVenModal = () => {
        onSetFormMode(FORM_MODE.NEW);

        setCustomerVen({
            site_id: undefined,
            ven_username: undefined,
            ven_password: undefined,
            ven_id: undefined,
            ven_uri: `${config.HOST}/api/vtn`,
            ven_vtn_id: 'ENERSPONSE_VTN',
            ven_cert: undefined,
            ven_default_event_signal: undefined,
            ven_group_id: undefined,
            ven_key: undefined,
            ven_market_context: 'enersponse',
            ven_party_id: undefined,
            ven_profile: '2.0a',
            ven_registration_id: undefined,
            ven_resource_id: undefined,
            mac_address: undefined,
        });
    };

    function showEditVenModal(record: ICustomerVen) {
        return () => {
            onSetFormMode(FORM_MODE.EDIT);
            const siteWithCompany = { ...record.site, company: record.company };
            setCustomerVen({ ...record, site: siteWithCompany as ISite });
        };
    }

    async function onVenModalClose(selectedVen: ICustomerVen) {
        setCustomerVen(undefined);
        onSetFormMode('');

        if (selectedVen) {
            await refetchCustomerVens(toUsefulParams({ ...pageState }));
        }
    }

    function showRemoteAccessModal(record: ICustomerVen) {
        return () => {
            setHardwareCustomerVen(record);
        };
    }

    async function onRemoteAccessModalClose(selectedVen: ICustomerVen) {
        setHardwareCustomerVen(undefined);
    }

    function showAuditTrailModal(record: ICustomerVen) {
        return () => {
            setVenAction(PAGE_ACTION.AUDIT_TRAIL);
            setCustomerVen(record);
        };
    }

    function getActions(record: ICustomerVen) {
        const actions = [];

        if (ability.can('update', 'CustomerVen')) {
            actions.push({
                title: 'Edit',
                onClick: (record: ICustomerVen) => showEditVenModal(record),
            });
        }

        if (ability.can('delete', 'CustomerVen')) {
            actions.push({
                title: 'Delete',
                onClick: (record: ICustomerVen) => deleteCustomerVenWithConfirm(record),
            });
        }

        if (record.mac_address && ability.can('read', 'RemoteAccess')) {
            actions.push({
                title: 'Remote Access',
                icon: <CodeFilled />,
                onClick: (record: ICustomerVen) => showRemoteAccessModal(record),
            });
        }

        if (auth?.user?.isAdminRoleType()) {
            actions.push({
                title: 'Audit Trail',
                onClick: (record: ICustomerVen) => showAuditTrailModal(record),
                icon: <HistoryOutlined />,
            });
        }

        return actions;
    }

    const columns: ColumnsType<ICustomerVen> = [
        {
            width: 70,
            render: (type, record) => {
                return <VenIcon ven={record} />;
            },
        },
        {
            title: 'Username',
            dataIndex: 'ven_username',
            sorter: (a, b) => a.ven_username.localeCompare(b.ven_username),
            ...getColumnSortOrder('ven_username', pageState.sorter),
        },
        {
            title: 'VEN ID',
            dataIndex: 'ven_id',
            sorter: (a, b) => a.ven_id.localeCompare(b.ven_id),
            ...getColumnSortOrder('ven_id', pageState.sorter),
        },
        {
            title: 'VTN ID',
            dataIndex: 'ven_vtn_id',
            sorter: (a, b) => a.ven_vtn_id.localeCompare(b.ven_vtn_id),
            ...getColumnSortOrder('ven_vtn_id', pageState.sorter),
        },
        {
            title: 'Company / Site',
            render: (type, record) => {
                let title = record.site
                    ? `${record.company?.company_name} / ${record.site.site_name}`
                    : `${record.company?.company_name} / ALL SITES`;

                return (
                    <Tooltip title={title}>
                        <Typography className="ellipsis">
                            <Tag style={{ display: 'initial' }} icon={<HomeOutlined />}>
                                {title}
                            </Tag>
                        </Typography>
                    </Tooltip>
                );
            },
        },
        {
            title: 'Control Provider',
            dataIndex: 'control_providers_id',
            render: (type, record) => record?.site?.control_provider?.company_name ?? 'Without Provider',
            filters: controlProviders,
            filterMultiple: false,
            ...getColumnFilteredValue('control_providers_id', pageState.filter),
            filterSearch: (input, record) => !!record?.text?.toString()?.toLowerCase().includes(input.toLowerCase()),
        },
        {
            title: 'Profile',
            dataIndex: 'ven_profile',
            width: 75,
            sorter: (a, b) => a.ven_profile.localeCompare(b.ven_profile),
            ...getColumnSortOrder('ven_profile', pageState.sorter),
        },
        {
            key: 'action',
            sorter: false,
            width: 90,
            render: (text: string, record) => <ActionList actions={getActions(record)} item={record} />,
        },
    ];

    return (
        <>
            <PageHeader
                pageTitle="VEN"
                extra={[
                    <ViewSwitcher
                        viewOptions={viewOptions(ability!)}
                        key="ven-view-switcher"
                        view={view}
                        handleViewMode={handleViewMode}
                    />,
                ]}
                actions={[
                    <Space key="customer-ven-type-filter">
                        <Typography.Text>VEN Type:</Typography.Text>
                        <Select
                            size="large"
                            value={pageState?.type ?? ''}
                            onChange={handleFieldChange('type')}
                            style={{ width: '145px' }}
                            data-cy="ven-type-selector"
                            options={[
                                {
                                    label: 'All',
                                    value: '',
                                },
                                {
                                    label: 'Customer VEN',
                                    value: 'customer_ven',
                                },
                                {
                                    label: 'Enersponse GEM',
                                    value: 'hardware',
                                },
                            ]}
                        />
                    </Space>,
                    <Space key="customer-ven-filter">
                        <Typography.Text>VEN status:</Typography.Text>
                        <Select
                            size="large"
                            value={pageState?.status ?? ''}
                            onChange={handleFieldChange('status')}
                            style={{ width: '110px' }}
                            data-cy="ven-status-selector"
                            defaultValue={''}
                            options={[
                                {
                                    label: 'All',
                                    value: '',
                                },
                                ...venStatusFilter,
                            ]}
                        />
                    </Space>,
                    <SearchInput key="customer-ven-search" onSearch={handleSearch} defaultValue={pageState?.search} />,
                    <Button
                        size="large"
                        key="download-csv"
                        onClick={exportCustomerVensAction}
                        icon={<DownloadOutlined />}
                        loading={exportLoading}
                        disabled={!company}
                        data-cy="csv-download"
                    >
                        Download CSV
                    </Button>,
                    ability.can('create', 'CustomerVen') && (
                        <Button
                            size="large"
                            key="new-customer-ven"
                            type="primary"
                            onClick={showNewVenModal}
                            icon={<PlusCircleOutlined />}
                            data-cy="create-customer-ven"
                        >
                            New VEN
                        </Button>
                    ),
                ]}
            />
            <WithEmptyDataTable condition={isLoading}>
                <Table
                    size="small"
                    rowKey="id"
                    sticky
                    columns={columns}
                    dataSource={customerVens}
                    pagination={{
                        ...pageState.pagination,
                        total: meta.total,
                        showSizeChanger: true,
                        size: 'default',
                    }}
                    loading={isLoading}
                    onChange={onTableChange}
                />
            </WithEmptyDataTable>

            {customerVen && (formMode === FORM_MODE.EDIT || formMode === FORM_MODE.NEW) && (
                <CustomerVenFormModal ven={customerVen} onClose={onVenModalClose} isEditMode={isEditMode} />
            )}

            {hardwareCustomerVen && <RemoteAccessModal ven={hardwareCustomerVen} onClose={onRemoteAccessModalClose} />}

            {customerVen && venAction === PAGE_ACTION.AUDIT_TRAIL && (
                <AuditTrailModal
                    onClose={() => setVenAction('')}
                    entityId={customerVen.id!}
                    entityType={AuditEntityType.CUSTOMER_VEN}
                />
            )}
        </>
    );
};

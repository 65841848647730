export function getReadingsStatsCellColor(value: number): string {
    // Ensure the value is within the 0 to 100 range
    value = Math.max(0, Math.min(100, value));

    let hue;
    if (value <= 50) {
        // Transition from red (0°) to orange (30°)
        hue = (value / 50) * 30; // Hue from 0 to 30
    } else {
        // Transition from orange (30°) to green (120°)
        hue = 30 + ((value - 50) / 50) * 90; // Hue from 30 to 120
    }

    // Return the color in HSL format
    return `hsla(${hue}, 100%, 50%, 0.75)`;
}

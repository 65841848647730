export function parseBoolean(
    value: boolean | undefined | string | number,
    defaultValue: boolean | undefined = false
): boolean {
    if (typeof value === 'boolean') {
        return value;
    }

    if (typeof value === 'number') {
        return value === 1;
    }

    if (typeof value === 'string') {
        return value.toLowerCase() === 'true';
    }

    return defaultValue;
}

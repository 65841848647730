import { useEffect, useRef, useState } from 'react';
import sub from 'date-fns/sub';
import App from 'antd/lib/app';
import Button from 'antd/lib/button';
import Modal from 'antd/lib/modal';
import Skeleton from 'antd/lib/skeleton';
import Typography from 'antd/lib/typography';
import ArrowUpOutlined from '@ant-design/icons/lib/icons/ArrowUpOutlined';
import ArrowDownOutlined from '@ant-design/icons/lib/icons/ArrowDownOutlined';
import { ICloudVen } from 'src/domain/ven/interface';
import { useCloudVenLogsQuery } from 'src/domain/ven/logs/queries';
import { CloudVenLogsTimeline } from './CloudVenLogsTimeline';

interface ICloudVenLogsModalProps {
    onClose: Function;
    ven: ICloudVen | Partial<ICloudVen>;
}

export const CloudVenLogsModal = ({ onClose, ven }: ICloudVenLogsModalProps) => {
    const scrollableRef = useRef<HTMLDivElement>(null);
    const { notification } = App.useApp();
    const [datetimeRange, setDatetimeRange] = useState<IDateTimeRange>(getNewerDatetimeRange());

    const { data, isLoading, isFetching, error } = useCloudVenLogsQuery(
        { id: ven.id!, ...datetimeRange },
        {
            keepPreviousData: true,
        }
    );

    if (error) {
        notification.error({ key: 'cloud-ven-logs-fetch-error', message: error.message || 'Cannot fetch Logs!' });
    }

    const logs = data?.logs || [];

    useEffect(() => {
        if (scrollableRef.current) {
            // @ts-ignore
            scrollableRef.current.scrollIntoView({ behavior: 'instant', block: 'start' });
        }
    }, [data]);

    const handleClose = () => onClose();

    function getNewerLogs() {
        setDatetimeRange(getNewerDatetimeRange());
    }

    function getOlderLogs() {
        setDatetimeRange(getOlderDatetimeRange(datetimeRange));
    }

    return (
        <Modal
            open
            destroyOnClose
            title={`Logs for Cloud VEN "${ven.ven_id}"`}
            onCancel={handleClose}
            width={750}
            styles={{
                body: { maxHeight: '800px', overflow: 'auto' },
            }}
            footer={null}
            className="cloud-ven-logs-modal"
        >
            <div ref={scrollableRef}>
                {isLoading && logs.length === 0 ? (
                    <Skeleton loading active />
                ) : (
                    <div>
                        <Button
                            block
                            loading={isFetching}
                            disabled={isFetching}
                            key="get-newer-logs"
                            icon={<ArrowUpOutlined />}
                            onClick={getNewerLogs}
                        >
                            Get Recent Logs
                        </Button>
                        <Typography.Text key="logs-hint" type="secondary">
                            * date and time in your computer timezone, most recent logs on the top
                        </Typography.Text>
                        <CloudVenLogsTimeline data={logs} />
                        <Button
                            block
                            loading={isFetching}
                            disabled={isFetching || logs.length === 0}
                            key="get-older-logs"
                            icon={<ArrowDownOutlined />}
                            onClick={getOlderLogs}
                        >
                            Get Older Logs
                        </Button>
                    </div>
                )}
            </div>
        </Modal>
    );
};

interface IDateTimeRange {
    start: Date;
    end: Date;
}

function getNewerDatetimeRange() {
    const now = new Date();
    return {
        start: sub(now, { minutes: 30 }),
        end: now,
    };
}

function getOlderDatetimeRange(datetimeRange: IDateTimeRange) {
    return {
        start: sub(datetimeRange.start, { minutes: 30 }),
        end: datetimeRange.start,
    };
}

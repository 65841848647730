import React, { useEffect, useState } from 'react';
import { Collapse, Form } from 'antd';
import SidebarFilter from '../SidebarFilter/SidebarFilter';
import './sidebarPanel.css';
import { ISidebarFilterAndOptionsProps } from '../context/SidebarContext';
import { toUsefulParams } from 'src/pages/toUsefulParams';
import { usePageLocation } from 'src/pages/usePageState';
import { useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash';

const { Panel } = Collapse;

enum SIDEBAR_PANELS {
    FIELDS = 'fields-filter',
    OPTIONS = 'options-filter',
}

interface ISidebarOptionsPanels {
    rootPage: string;
    filters: ISidebarFilterAndOptionsProps[];
    options: ISidebarFilterAndOptionsProps[];
}

const SidebarOptionsPanels: React.FC<ISidebarOptionsPanels> = ({
    rootPage,
    filters,
    options,
}: ISidebarOptionsPanels) => {
    const location = useLocation();
    const { setPageQuery, queryToState } = usePageLocation();
    const pageState: any = queryToState(location.search);
    const [pageFilters, setPageFilters] = useState<Record<string, any>>(pageState || {});

    const [form] = Form.useForm();

    async function onFilterChange(key: string, value: any) {
        const newFilter = { [key]: value };
        const preparedParams = toUsefulParams({
            filter: {
                ...pageFilters?.filter,
                ...newFilter,
            },
        });

        setPageFilters(preparedParams);
        setPageQuery({ ...pageState, ...preparedParams });
    }

    useEffect(() => {
        if (isEmpty(pageState) && !isEmpty(pageFilters)) {
            setPageFilters({});
            form.resetFields();
        }
    }, [form, pageFilters, pageState]);

    return (
        <Form.Provider>
            <Form name="filters_form" form={form}>
                <Collapse
                    accordion
                    defaultActiveKey={SIDEBAR_PANELS.FIELDS}
                    bordered={false}
                    expandIconPosition="end"
                    rootClassName="sidebar-accordion-panels"
                >
                    <Panel className="accordion-panels-filters" header={'Filters'} key={SIDEBAR_PANELS.FIELDS}>
                        {filters?.map((filter: ISidebarFilterAndOptionsProps) => (
                            <SidebarFilter
                                rootKey={rootPage}
                                key={filter.key}
                                dataKey={filter.key}
                                header={filter.title || ''}
                                subHeader={filter.subHeader}
                                options={filter.items || []}
                                filterMultiple={filter.filterMultiple}
                                defaultValue={pageFilters?.filter?.[filter.key || '']}
                                onChange={(key, value) => onFilterChange(key, value)}
                                filterType={filter.filterType}
                            />
                        ))}
                    </Panel>
                    <Panel className="accordion-panels-options" header={'Options'} key={SIDEBAR_PANELS.OPTIONS}>
                        {options?.map((option: ISidebarFilterAndOptionsProps) => (
                            <SidebarFilter
                                rootKey={rootPage}
                                key={option.key}
                                dataKey={option.key}
                                header={option.title || ''}
                                subHeader={option.subHeader}
                                options={option.items || []}
                                filterMultiple={option.filterMultiple}
                                defaultValue={pageFilters?.filter?.[option.key || '']}
                                onChange={(key, value) => onFilterChange(key, value)}
                                filterType={option.filterType}
                            />
                        ))}
                    </Panel>
                </Collapse>
            </Form>
        </Form.Provider>
    );
};

export default SidebarOptionsPanels;

import Select, { SelectProps } from 'antd/lib/select';
import { supportedEnergyMarkets } from '../../../domain/market-prices/interface';
import { ProgramUtilityList } from '../../../domain/program/programUtility';
import { IsoSVG } from '../../icons/icons';

const getProgramUtilityISOoptions = () => {
    const isoList = supportedEnergyMarkets.map(market => ({
        label: market.toUpperCase(),
        value: market,
        key: market,
        iso: true,
    }));

    const utilityList = ProgramUtilityList.map(utility => ({
        label: utility.name,
        value: utility.utility_id,
        key: utility.utility_id,
        iso: false,
    }));

    return [...isoList, ...utilityList];
};

export const ProgramUtilityOrIsoSelector = (props: SelectProps) => {
    const utilityIsoOptionList = getProgramUtilityISOoptions();

    return (
        <Select
            size="large"
            placeholder="Select Utility/ISO"
            showSearch
            autoClearSearchValue
            optionFilterProp="label"
            {...props}
        >
            {utilityIsoOptionList.map(option => (
                <Select.Option value={option.value} key={option.key} label={option.label}>
                    {option.iso && <IsoSVG style={{ marginRight: '5px' }} />}
                    {option.label}
                </Select.Option>
            ))}
        </Select>
    );
};

import { differenceInDays } from 'date-fns';
import { ISite } from './interface';
import { getActiveSiteSan } from './getActiveSiteSan';

const ACTUAL_INTERVAL_DATA_IN_DAYS = 7;

export const hasActualIntervalData = (site: ISite): boolean => {
    const activeSan = getActiveSiteSan(site);

    if (!activeSan?.san_info) return false;
    if (!activeSan?.san_info.latestIntervalDataDate) return false;

    const latestIntervalDataDate = activeSan?.san_info.latestIntervalDataDate;
    const intervalDataDate = new Date(latestIntervalDataDate);
    const now = new Date();

    const diffDays = differenceInDays(now, intervalDataDate);

    return diffDays <= ACTUAL_INTERVAL_DATA_IN_DAYS;
};

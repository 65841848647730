import { useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { endOfYear, startOfYear } from 'date-fns';

import Col from 'antd/lib/col';
import Empty from 'antd/lib/empty';
import Row from 'antd/lib/row';
import Skeleton from 'antd/lib/skeleton';
import Typography from 'antd/lib/typography';
import Card from 'antd/lib/card';
import App from 'antd/lib/app';
import { Divider, Space } from 'antd/lib';
import EnvironmentOutlined from '@ant-design/icons/lib/icons/EnvironmentOutlined';
import CloudOutlined from '@ant-design/icons/CloudOutlined';

import { IDateRange } from '../interface';
import { usePageLocation } from '../usePageState';
import { PageHeader } from '../../components/pageHeader/pageHeader';
import { useCompanies } from '../../components/selectors/GlobalCompanySelector/CompanyContext';
import { EquivalencyCard } from './components/equivalencyCard';

import passVehicles from './icons/pass-vehicles.svg';
import milesDriven from './icons/miles-driven.svg';
import coalBurned from './icons/coal-burned.svg';
import homesElectricity from './icons/homes-electricity.svg';
import treeSeedings from './icons/tree-seedlings.svg';
import forestAcres from './icons/forest-acres.svg';
import stopWatch from '../../components/icons/stop-watch.svg';
import capacitySvg from '../../components/icons/capacity.svg';
import { CumulativeBarChart } from './components/cumulativeBarChart';
import { InformationCard } from '../../components/informationCard/informationCard';
import { InformationCardTitle } from '../../components/informationCard/informationCardTitle';
import { MetricData } from '../../components/metricData/metricData';
import {
    calculateTotalMetricsByMonths,
    formatTotalMetrics,
    toFixedValue,
} from '../../domain/impact/metricsCalculations';
import { numberFormatter } from '../../domain/common/numberFormatter';
import { useImpactListQuery } from '../../domain/impact/queries';
import { useAuth } from '../../domain/auth/useAuth';
import {
    DatePeriodSelector,
    DEFAULT_PERIOD_OPTIONS,
    DEFAULT_START_OF_PERIOD,
    getDateRangeOptions,
    toSelectedOption,
} from '../../components/selectors/DatePeriodSelector/DatePeriodSelector';
import { ImpactExportButton } from './components/ImpactExportButton';
import { useDocumentTitle } from '../../components/useDocumentTitle';
import { SECONDARY_COLOR } from '../../theme';
import './impact.css';
import Alert from 'antd/lib/alert/Alert';

// https://www.rapidtables.com/convert/power/kw-to-megaw.html
// 1MW = 1_000kW = 1_000_000W
export const formatWatt = (value: number) => {
    //transform to MW when value is more than 1_000_000_000W (1_000_000KW)
    if (value >= 1e9) {
        value = Math.trunc(value / 1e6);
        return { value, uom: 'MW' };
    }
    return { value: Math.trunc(value / 1000), uom: 'kW' };
};

export const formatWattHour = (value: number) => {
    if (value >= 1e9) {
        value = Math.trunc(value / 1e6);
        return { value, uom: 'MWH' };
    }
    return { value: Math.trunc(value / 1000), uom: 'kWH' };
};

export const formatLbs = (value: number) => {
    //transform to million when value is more than million (1_000_000)
    if (value >= 1e6) {
        return { value: Number((value / 1e6).toFixed(2)), uom: 'million lbs' };
    }

    return { value, uom: 'lbs' };
};

const getSelectedPeriodLabel = (selectedPeriod: IDateRange) => {
    const { optionValueMap } = getDateRangeOptions(DEFAULT_PERIOD_OPTIONS, DEFAULT_START_OF_PERIOD);
    const { period, datePeriod } = toSelectedOption(selectedPeriod, optionValueMap);
    const selectedDateOption = optionValueMap.get(period)?.find(el => el.value === datePeriod);
    return selectedDateOption ? selectedDateOption.label : new Date(selectedPeriod.end).getFullYear();
};

export const defaultDate = {
    start: startOfYear(new Date()),
    end: endOfYear(new Date()),
};

export const Impact = () => {
    const { notification } = App.useApp();
    const location = useLocation();
    const { queryToState, setPageQuery } = usePageLocation();
    const pageState: any = queryToState(location.search);

    useDocumentTitle('Impact');

    const { companyId, companies } = useCompanies()!;

    const [date, setDate] = useState<IDateRange>({
        ...defaultDate,
        ...(pageState?.date && { ...pageState?.date }),
    });

    const currentCompany = companies.find(c => c.company_id === companyId);
    const companyName = currentCompany?.company_name || 'All companies';

    const auth = useAuth();

    const isQueryData = auth?.user?.isAdminRoleType() || !!companyId;
    const {
        data: rawImpactData,
        isLoading,
        isError,
        error,
    } = useImpactListQuery(
        { companyId: companyId, date: date },
        {
            enabled: isQueryData,
        }
    );

    if (isError) {
        notification.error({
            key: 'impact-information-error',
            message: error?.message || 'Cannot load impact information!',
        });
    }

    const impactData = useMemo(() => {
        if (!rawImpactData || rawImpactData.length === 0) return undefined;

        return { ...rawImpactData[0], metrics: calculateTotalMetricsByMonths(rawImpactData[0].metrics) };
    }, [rawImpactData]);

    const onDateChange = (dateRange: IDateRange) => {
        setDate(dateRange);
        setPageQuery({ ...pageState, date: dateRange });
    };

    const renderContent = () => {
        if (!impactData) {
            return (
                <Col span={24}>
                    <Card bordered={false}>
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </Card>
                </Col>
            );
        }
        const totalMetrics = formatTotalMetrics(impactData.metrics);
        return (
            <div className="impact-page">
                <Card>
                    <Row gutter={[16, 16]} style={{ justifyContent: 'space-evenly' }} align="middle">
                        <InformationCard
                            classNames={['impact-metric-card']}
                            title={
                                <InformationCardTitle
                                    title="Locations"
                                    icon={<EnvironmentOutlined />}
                                    classNames={['impact-metric-title']}
                                />
                            }
                        >
                            <MetricData
                                valueClassName={['impact-value']}
                                value={numberFormatter.format(totalMetrics.locations)}
                            />
                        </InformationCard>
                        <Divider type="vertical" className="impact-information-card-divider" />
                        <InformationCard
                            classNames={['impact-metric-card']}
                            title={
                                <InformationCardTitle
                                    title="Event Hours"
                                    icon={<img src={stopWatch} alt="events Hours img" />}
                                    classNames={['impact-metric-title']}
                                />
                            }
                        >
                            <MetricData
                                valueClassName={['impact-value']}
                                value={numberFormatter.format(totalMetrics.eventsHours)}
                            />
                        </InformationCard>
                        <Divider type="vertical" className="impact-information-card-divider" />
                        <InformationCard
                            classNames={['impact-metric-card']}
                            title={
                                <InformationCardTitle
                                    title="Available Capacity"
                                    icon={<img src={capacitySvg} alt="Available Capacity img" />}
                                    classNames={['impact-metric-title']}
                                />
                            }
                        >
                            <MetricData
                                valueClassName={['impact-value']}
                                uom={formatWatt(totalMetrics.capacity * 1000).uom}
                                value={numberFormatter.format(formatWatt(totalMetrics.capacity * 1000).value)}
                            />
                        </InformationCard>
                        <Divider type="vertical" className="impact-information-card-divider" />
                        <InformationCard
                            classNames={['impact-metric-card']}
                            title={
                                <InformationCardTitle
                                    title="CO2 Impact"
                                    icon={<CloudOutlined />}
                                    classNames={['impact-metric-title']}
                                />
                            }
                        >
                            <MetricData
                                valueClassName={['impact-value']}
                                uom={formatLbs(totalMetrics.co2Impact).uom}
                                value={numberFormatter.format(formatLbs(totalMetrics.co2Impact).value)}
                            />
                        </InformationCard>
                    </Row>
                </Card>
                <Row style={{ paddingTop: '16px' }}>
                    <Col xs={24}>
                        <Card
                            className="content-background"
                            title={
                                <Typography.Title
                                    level={4}
                                    type="secondary"
                                    style={{ textAlign: 'center', color: SECONDARY_COLOR }}
                                >
                                    {companyName} Pounds of CO2 Avoided,&nbsp;{getSelectedPeriodLabel(date)}
                                </Typography.Title>
                            }
                            style={{ padding: 0 }}
                        >
                            <CumulativeBarChart
                                data={impactData.metrics}
                                timestamp={{ start: date.start.valueOf(), end: date.end.valueOf() }}
                            />
                        </Card>
                    </Col>
                </Row>
                <Row justify="center" style={{ paddingTop: '16px' }}>
                    <Col span={24}>
                        <Card
                            title={
                                <Typography style={{ fontWeight: 700 }}>Avoided Greenhouse Gas Emissions</Typography>
                            }
                        >
                            <Typography.Text strong>Equivalent to removing...</Typography.Text>
                            <Row gutter={[0, 16]} justify="space-between" align="middle">
                                <EquivalencyCard
                                    value={toFixedValue(impactData.comparisons.passengerVehicle)}
                                    description="Passenger vehicles driven for one year"
                                    link="https://www.epa.gov/energy/greenhouse-gases-equivalencies-calculator-calculations-and-references#vehicles"
                                    icon={passVehicles}
                                />
                                <Col style={{ padding: '0' }}>
                                    <Typography.Text strong>or</Typography.Text>
                                </Col>
                                <EquivalencyCard
                                    value={toFixedValue(impactData.comparisons.passengerVehicleMiles)}
                                    description="Miles driven by an average passenger vehicle"
                                    link="https://www.epa.gov/energy/greenhouse-gases-equivalencies-calculator-calculations-and-references#miles"
                                    icon={milesDriven}
                                />
                            </Row>
                        </Card>
                    </Col>
                </Row>
                <Row justify="center" style={{ paddingTop: '16px' }}>
                    <Col span={24}>
                        <Card title={<Typography style={{ fontWeight: 700 }}>Avoided CO2 Emissions</Typography>}>
                            <Typography.Text strong>Eliminating...</Typography.Text>
                            <Row gutter={[0, 16]} justify="space-between" align="middle">
                                <EquivalencyCard
                                    value={toFixedValue(impactData.comparisons.poundsOfCoalBurned)}
                                    description="Pounds of coal burned"
                                    link="https://www.epa.gov/energy/greenhouse-gases-equivalencies-calculator-calculations-and-references#lbscoal"
                                    icon={coalBurned}
                                />
                                <Col style={{ padding: '0' }}>
                                    <Typography.Text strong>or</Typography.Text>
                                </Col>
                                <EquivalencyCard
                                    value={toFixedValue(impactData.comparisons.homeElectricityPerYear)}
                                    description="homes electricity use for one year"
                                    link="https://www.epa.gov/energy/greenhouse-gases-equivalencies-calculator-calculations-and-references#houseelec"
                                    icon={homesElectricity}
                                />
                            </Row>
                        </Card>
                    </Col>
                </Row>
                <Row justify="center" style={{ paddingTop: '16px' }}>
                    <Col span={24}>
                        <Card title={<Typography style={{ fontWeight: 700 }}>Carbon Sequestered</Typography>}>
                            <Typography.Text strong>Equivalent to adding...</Typography.Text>
                            <Row gutter={[0, 16]} justify="space-between" align="middle">
                                <EquivalencyCard
                                    value={toFixedValue(impactData.comparisons.seedlingPlanted)}
                                    description="tree seedlings grown for 10 years"
                                    link="https://www.epa.gov/energy/greenhouse-gases-equivalencies-calculator-calculations-and-references#seedlings"
                                    icon={treeSeedings}
                                />
                                <Col style={{ padding: '0' }}>
                                    <Typography.Text strong>or</Typography.Text>
                                </Col>
                                <EquivalencyCard
                                    value={toFixedValue(impactData.comparisons.acresOfForest)}
                                    description="acres of U.S. forests in one year"
                                    link="https://www.epa.gov/energy/greenhouse-gases-equivalencies-calculator-calculations-and-references#pineforests"
                                    icon={forestAcres}
                                />
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    };

    if (auth?.user?.isControlProvider() && !companyId) {
        return (
            <Card size="small">
                <Row justify="center">
                    <Space direction="vertical">
                        <Alert showIcon message="Please select a company" type="warning" />
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </Space>
                </Row>
            </Card>
        );
    }

    return (
        <>
            <PageHeader
                pageTitle="Impact"
                actions={[
                    <DatePeriodSelector key="date-period-selector" onChange={onDateChange} selectedPeriod={date} />,
                    <ImpactExportButton
                        disabled={!Boolean(impactData)}
                        key="impact-export"
                        filter={{ companyId: companyId, date }}
                        companyName={companyName}
                    />,
                ]}
            />
            <Row justify="center" gutter={[16, 16]} data-cy="impact-reporting">
                {isLoading ? <Skeleton loading={isLoading} paragraph active /> : renderContent()}
            </Row>
        </>
    );
};

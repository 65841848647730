import App from 'antd/lib/app';
import Button from 'antd/lib/button';
import Modal from 'antd/lib/modal';
import Spin from 'antd/lib/spin';
import Transfer from 'antd/lib/transfer';
import { isNull } from 'lodash';
import orderBy from 'lodash/orderBy';
import React, { useEffect, useMemo, useState } from 'react';
import { IProduct } from '../../domain/product/interface';
import { useProductListQuery } from '../../domain/product/queries';
import { IProgram } from '../../domain/program/interface';

interface IManageProductModalProps {
    onClose: () => void;
    onOk: (selectedProducts: IProduct[]) => void;
    products: IProduct[];
    program: IProgram | null;
}

export const ManageProductModal = ({ onClose, onOk, products: initialProducts, program }: IManageProductModalProps) => {
    const { notification } = App.useApp();
    const [targetKeys, setTargetKeys] = useState<React.Key[]>([]);
    const [transferOptions, setTransferOptions] = useState<IRecordType[]>([]);

    const { data, error, isError, isLoading } = useProductListQuery({ include: 'program' });
    const products = useMemo(() => data?.data || [], [data]);

    if (isError) {
        notification.error({ key: 'manage-product-modal-error', message: error?.message || 'Cannot load products!' });
    }

    const handleOk = () => {
        const selectedProducts = products.filter(product => targetKeys.includes(product.salesforce_id));

        onOk(selectedProducts);
    };

    const handleCancel = () => {
        onClose();
    };

    const handleChange = (newTargetKeys: React.Key[]) => {
        setTargetKeys(newTargetKeys);
    };

    const filterOption = (inputValue: string, option: IRecordType) => {
        const searchValue = inputValue.toLocaleLowerCase();

        return (
            option.title.toLocaleLowerCase().indexOf(searchValue) > -1 ||
            option.key.toLocaleLowerCase().indexOf(searchValue) > -1
        );
    };

    const renderItem = (item: IRecordType) => {
        if (item.programName) {
            return (
                <span title={`${item.title} (${item.programName})`}>
                    {item.title} ({item.programName})
                </span>
            );
        }

        return <span title={item.title}>{item.title}</span>;
    };

    useEffect(() => {
        const sortedProducts = orderBy(
            products,
            [
                (product: IProduct) => !!product.program_id, // sort by attached program
                (product: IProduct) => product.name, // then by product name
            ],
            ['asc', 'asc']
        );
        const sortedInitialProducts = orderBy(initialProducts, ['name'], ['asc']);

        setTransferOptions(sortedProducts.map(product => toTransferOptions(product, program)));
        setTargetKeys(
            sortedInitialProducts.map(product => toTransferOptions(product, program)).map(option => option.key)
        );
    }, [products, initialProducts, program]);

    return (
        <Modal
            open
            title="Manage Salesforce Products"
            destroyOnClose
            onCancel={handleCancel}
            width={800}
            styles={{
                mask: { background: 'rgba(0, 0, 0, 0.1)' }, // to not make background very dark
                footer: { padding: '8px 24px' },
            }}
            footer={[
                <Button key="program-product-modal-cancel" onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button key="program-product-modal-submit" type="primary" onClick={handleOk}>
                    Continue
                </Button>,
            ]}
        >
            <Spin tip="Loading..." spinning={isLoading}>
                <Transfer
                    showSearch
                    style={{ width: '100%' }}
                    listStyle={{ width: '48%', height: 300 }}
                    dataSource={transferOptions}
                    targetKeys={targetKeys}
                    titles={['All Salesforce Products', 'Linked Products']}
                    render={renderItem}
                    filterOption={filterOption}
                    onChange={handleChange}
                />
            </Spin>
        </Modal>
    );
};

interface IRecordType {
    key: string;
    title: string;
    programName?: string;
    disabled: boolean;
}

function toTransferOptions(product: IProduct, program: IProgram | null): IRecordType {
    return {
        key: product.salesforce_id,
        title: product.name,
        programName: product?.program?.name,
        disabled: isProductSelectDisabled(product, program),
    };
}

function isProductSelectDisabled(product: IProduct, program: IProgram | null): boolean {
    return !isNull(product.program_id) && product.program_id !== program?.program_id;
}

import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '../../query-client';
import { IWeatherForecastDaily, OpenWeatherProvider } from './openWeather';

const openWeatherProvider = new OpenWeatherProvider();

export const useForecastWeatherQuery = (query: { lat: number; lng: number }, options = {}) => {
    return useQuery<IWeatherForecastDaily[], Error>({
        queryKey: [QueryKey.WEATHER_FORECAST, query],
        queryFn: () => openWeatherProvider.getForecast(query.lat, query.lng),
        refetchOnWindowFocus: false,
        ...options,
    });
};

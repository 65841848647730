import inRange from 'lodash/inRange';
import { signalTypes } from '../../../../domain/event/interface';
import { getTimeAsMinutes } from '../../../../domain/common/timeHelpers';
import { CleanResponseMarket } from '../../../../domain/clean-response/interface';

interface IRowData {
    carbonIntensity: number;
    market: string;
    duration: number;
    signal: number;
    triggerStartTime: string;
    triggerEndTime: string;
    generalColumnAmount: number;
    cancelWhenIntensityLower: string;
}

const formatTriggerTime = (time: string) => {
    if (!time) return '';

    const [hour, minutes] = time.trim().split(':');
    const preparedHour = (hour || '').padStart(2, '0');
    const preparedMinutes = (minutes || '').padStart(2, '0');

    return [preparedHour, preparedMinutes].join(':');
};

const marketValidator = (market: string) => {
    // check for required both fields
    if (!market) {
        return 'Market should be defined. ';
    }

    const markets = Object.keys(CleanResponseMarket).map(market => market.toLowerCase());
    //check if market is correct
    if (!markets.includes(market)) {
        return `Please enter correct market. One of ${markets.join(',')}. `;
    }

    return null;
};

const isValidTimeFormat = (timeStr: string) => {
    const formatPattern = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
    return timeStr.length === 5 && formatPattern.test(timeStr);
};

const startEndTimeValidator = (startTime: string, endTime: string) => {
    if (!startTime && !endTime) return null;

    //check if both startTime && endTime exist
    if (!startTime || !endTime) {
        return 'Both start and end time should be defined or both be empty. Allowed format hh:mm. ';
    }

    // check for correct format hh:mm
    if (!isValidTimeFormat(startTime) || !isValidTimeFormat(endTime)) {
        return 'Both start and end time should be in format hh:mm. And in range from 00:00 to 23:59. ';
    }

    //check startTime smaller than endTime
    if (getTimeAsMinutes(startTime) >= getTimeAsMinutes(endTime)) {
        return 'End time should be after start time. ';
    }

    return null;
};

const validateCleanResponseTriggerData = (column: IRowData, numberOfFields: number) => {
    const rowErrors: string[] = [];

    if (column.generalColumnAmount !== numberOfFields) {
        rowErrors.push(
            `Number of fields mismatch: expected ${numberOfFields} fields but parsed ${column.generalColumnAmount}. `
        );
        return rowErrors;
    }

    if (isNaN(column.carbonIntensity) || column.carbonIntensity < 0 || !Number.isInteger(column.carbonIntensity)) {
        rowErrors.push('Carbon Intensity should be a positive integer number. ');
    }

    if (
        isNaN(column.duration) ||
        column.duration < 1 ||
        !inRange(column.duration, 1, 481) ||
        !Number.isInteger(column.duration)
    ) {
        rowErrors.push('Event Duration should be a positive integer number and be in range [1-480](minutes). ');
    }

    if (isNaN(column.signal) || !signalTypes.includes(column.signal) || !Number.isInteger(column.signal)) {
        rowErrors.push(
            'Event Signal should be a integer number. Allowed value is 0-normal,1-moderate,2-high,3-special. '
        );
    }

    if (!column.cancelWhenIntensityLower || !['true', 'false'].includes(column.cancelWhenIntensityLower)) {
        rowErrors.push(
            'Field "Cancel when Carbon Intensity Lower" should be filled out. Allowed value true or false. '
        );
    }

    const startEndTimeValidatorError = startEndTimeValidator(column.triggerStartTime, column.triggerEndTime);
    startEndTimeValidatorError && rowErrors.push(startEndTimeValidatorError);

    const marketValidatorError = marketValidator(column.market);
    marketValidatorError && rowErrors.push(marketValidatorError);

    return rowErrors;
};

const transformParsedRowToData = (columns: string[]): IRowData => {
    // remove first element of array
    // in reason this is company name
    if (columns.length === 9) {
        columns.shift();
    }

    const [market, carbonIntensity, duration, signal, cancelWhenIntensityLower, triggerStartTime, triggerEndTime] =
        columns;

    return {
        market: market.toLowerCase(),
        carbonIntensity: carbonIntensity ? Math.round(Number(carbonIntensity)) : NaN,
        duration: duration ? Math.round(parseFloat(duration)) : NaN,
        signal: signal ? Number(signal) : NaN,
        triggerStartTime: formatTriggerTime(triggerStartTime),
        triggerEndTime: formatTriggerTime(triggerEndTime),
        cancelWhenIntensityLower: cancelWhenIntensityLower.toLowerCase(),
        generalColumnAmount: columns.length,
    };
};

export const parseCleanResponseTriggersCsvData = (content: string, company: number | null) => {
    const errors: string[] = [];
    const result: any = [];
    const rows = content.split('\n').filter(row => row);
    const headers = (rows.shift() || '').split(',');
    // remove first element of array
    // in reason this is company name
    if (headers.length === 9) {
        headers.shift();
    }
    const numberOfColumns = headers.length;

    if (!rows.length) {
        errors.push('File should contains data ');
    }

    rows.forEach((row, rowIndex) => {
        const rowContent = row
            .replace(/[\r\n]+/gm, '')
            .replaceAll('"', '')
            .split(',');
        const rowData = transformParsedRowToData(rowContent);
        const rowErrors = validateCleanResponseTriggerData(rowData, numberOfColumns);

        if (rowErrors?.length) {
            errors.push(`In row ${rowIndex + 1}: ${rowErrors?.join('')} `);
            return;
        }

        const triggerItem = {
            company_id: company,
            carbon_intensity: rowData.carbonIntensity,
            market: rowData.market,
            duration: rowData.duration,
            signal: rowData.signal,
            start_time: rowData.triggerStartTime,
            end_time: rowData.triggerEndTime,
            cancel_when_intensity_lower: rowData.cancelWhenIntensityLower === 'true',
        };
        result.push(triggerItem);
    });

    return [errors, result];
};

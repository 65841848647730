import WarningFilled from '@ant-design/icons/WarningFilled';
import Empty from 'antd/lib/empty';
import Timeline from 'antd/lib/timeline/Timeline';
import { Typography } from 'antd';
import { groupBy, orderBy } from 'lodash';
import { getComputerFormattedTime } from 'src/domain/date/date';
import { IVenLogsLine } from 'src/domain/ven/logs/interface';
import './CloudVenLogsTimeline.css';

const { Text } = Typography;

interface CloudVenLogsTimelineProps {
    data: IVenLogsLine[];
}

export const CloudVenLogsTimeline = ({ data }: CloudVenLogsTimelineProps) => {
    const grouped = groupLogsByStream(data);
    const content = [];

    for (let logs of grouped) {
        logs = orderBy(logs, ['eventId'], ['desc']);
        const stream = logs[0].logStreamName;

        const items = logs.map(makeTimelineItem);

        content.push(
            <div key={'logs-stream-' + stream} style={{ margin: '20px 0 0 0' }}>
                <div className="stream-divider">
                    <Text keyboard>{stream}</Text>
                </div>
                <Timeline items={items} />
            </div>
        );
    }

    if (content.length === 0) {
        return <Empty key="logs-empty" image={Empty.PRESENTED_IMAGE_SIMPLE} />;
    }

    return <div>{content}</div>;
};

function makeTimelineItemIcon(it: IVenLogsLine) {
    switch (it.level) {
        case 'INFO':
            return { color: 'blue' };
        case 'DEBUG':
            return { color: 'grey' };
        case 'ERROR':
            return { color: 'red', dot: <WarningFilled color="red" style={{ fontSize: '18px' }} /> };
        case 'WARNING':
            return { color: 'orange', dot: <WarningFilled color="orange" style={{ fontSize: '18px' }} /> };
        default:
            return { color: 'blue' };
    }
}

function makeTimelineItem(it: IVenLogsLine) {
    const formattedDateTime = getComputerFormattedTime(new Date(it.datetime), 'hh:mm:ss.SSS a MMM dd, yyyy');
    return {
        ...makeTimelineItemIcon(it),
        children: (
            <>
                <div className="logs-line-datetime">{formattedDateTime}</div>
                <div className="logs-line">{it.message}</div>
            </>
        ),
    };
}

/**
 * We group logs by stream and order these groups
 * so that the first stream group has the most recent logs
 */
function groupLogsByStream(logs: IVenLogsLine[]) {
    const groupedByStream = groupBy(logs, 'logStreamName');

    const visited = new Set<string>();
    const grouped: IVenLogsLine[][] = [];

    const logsByEventId = orderBy(logs, ['eventId'], ['desc']);
    logsByEventId.forEach(item => {
        const stream = item.logStreamName;
        if (visited.has(stream)) return;

        const logsOfStream = groupedByStream[stream];
        grouped.push(logsOfStream);
        visited.add(stream);
    });

    return grouped;
}

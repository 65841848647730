import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '../../query-client';
import { fetchMarketZones, identifyMarketZone } from './index';
import { IIdentifyMarketZoneQuery } from './interface';
import { IMarket } from './IMarket';

export const useMarketZoneListQuery = (options?: {}) => {
    return useQuery<{ [key: string]: string[] }, Error>(
        [QueryKey.MARKET_ZONES],
        ({ signal }) => fetchMarketZones(signal),
        {
            ...options,
        }
    );
};

export const useIdentifyMarketZoneQuery = (query: IIdentifyMarketZoneQuery, options?: {}) => {
    return useQuery<IMarket | null, Error>(
        [QueryKey.IDENTIFY_MARKET_ZONE, query],
        ({ signal }) => identifyMarketZone(query, signal),
        {
            ...options,
        }
    );
};

import React from 'react';
import BankOutlined from '@ant-design/icons/BankOutlined';
import { InfoWindow } from '@react-google-maps/api';
import { currencyUSDFormatter } from '../../../../domain/common/currencyFormatters';
import { ISite, ISiteLocation } from '../../../../domain/site/interface';

interface ISelectedSiteInfoProps {
    activeSiteLocation: ISiteLocation & { site_lat: number; site_long: number };
    setActiveSiteLocation: (site: ISite | null) => void;
}

export const SelectedSiteInfo: React.FC<ISelectedSiteInfoProps> = ({ activeSiteLocation, setActiveSiteLocation }) => {
    return (
        <InfoWindow
            onCloseClick={() => setActiveSiteLocation(null)}
            position={{
                lat: +activeSiteLocation.site_lat,
                lng: +activeSiteLocation.site_long,
            }}
        >
            <div className="marker-info-window">
                <div className="info-window-title">
                    <BankOutlined /> {activeSiteLocation.company_name}
                </div>
                <div className="info-window-subtitle">{activeSiteLocation.site_name}</div>
                <div>{activeSiteLocation.site_address}</div>

                {activeSiteLocation?.lmp_price_rt && activeSiteLocation?.lmp_price_da && (
                    <>
                        <br />
                        <div>
                            <span className="info-window-subtitle">Real Time:&nbsp;</span>
                            {currencyUSDFormatter.format(activeSiteLocation.lmp_price_rt as number)}
                        </div>
                        <div>
                            <span className="info-window-subtitle">Day Ahead:&nbsp;</span>
                            {currencyUSDFormatter.format(activeSiteLocation.lmp_price_da as number)}
                        </div>
                    </>
                )}
            </div>
        </InfoWindow>
    );
};

import { ColumnFilterItem } from 'antd/es/table/interface';
import React, { createContext, useContext, useState, useCallback, useEffect, useMemo } from 'react';
import { DEFAULT_SIDEBAR_WIDTH, LOCAL_STORAGE_PREFIX } from 'src/domain/commonConst';

interface ISidebarProvider {
    children: React.ReactNode;
}

interface ISidebarConfig {
    width?: number;
    collapsed?: boolean;
}

export enum SidebarFilterType {
    CHECKBOX = 'checkbox',
    TOGGLE = 'toggle',
}
export interface ISidebarFilterAndOptionsProps {
    title?: string;
    subHeader?: string;
    items?: ColumnFilterItem[];
    key?: string;
    filteredValue?: any | null;
    filterMultiple?: boolean;
    filterType?: SidebarFilterType;
}

export interface ISidebarFilterAndOptions {
    rootPage?: string;
    props?: ISidebarFilterAndOptionsProps[];
}

interface ISidebarContext {
    // Temporary value (not stored)
    resultAmountValue: number;
    setResultAmountValue: (value: number) => void;

    // Persistent config object
    config: ISidebarConfig;
    setConfig: (config: Record<string, any>) => void;
    updateConfig: (partialConfig: Record<string, any>) => void;

    // set filter and options into storage
    setFilterItem: <T>(key: string, value: T) => void;
    getFilterItem: (key: string) => Record<string, any> | null;
    removeFilterItem: (key: string) => void;
    getAllFilterItems: () => Record<string, any>;

    // filters and options values
    filters: ISidebarFilterAndOptions;
    setFilters: (items: ISidebarFilterAndOptions) => void;
    options: ISidebarFilterAndOptions;
    setOptions: (items: ISidebarFilterAndOptions) => void;

    // Utility functions
    resetAll: () => void;
}

const SidebarContext = createContext<ISidebarContext | undefined>(undefined);

// Constants for storage keys
const CONFIG_STORAGE_KEY = `${LOCAL_STORAGE_PREFIX}.sidebar_config`;
const FILTERS_STORAGE_PREFIX = `${LOCAL_STORAGE_PREFIX}.filters.`;

export const SidebarProvider: React.FC<ISidebarProvider> = ({ children }) => {
    // State for temporary value (not persisted)
    const [resultAmountValue, setResultAmountValue] = useState<number>(0);
    const [options, setOptions] = useState<ISidebarFilterAndOptions>({});
    const [filters, setFilters] = useState<ISidebarFilterAndOptions>({});

    const defaultConfig = useMemo(() => {
        return {
            width: DEFAULT_SIDEBAR_WIDTH,
            collapsed: false,
        };
    }, []);

    // State for persistent config
    const [config, setConfigState] = useState<Record<string, any>>(() => {
        try {
            const storedConfig = localStorage.getItem(CONFIG_STORAGE_KEY);
            return storedConfig
                ? {
                      ...defaultConfig,
                      ...JSON.parse(storedConfig),
                  }
                : defaultConfig;
        } catch (error) {
            console.error('Error loading config from local storage:', error);
            return {};
        }
    });

    // Update localStorage when config changes
    useEffect(() => {
        try {
            localStorage.setItem(CONFIG_STORAGE_KEY, JSON.stringify(config));
        } catch (error) {
            console.error('Error saving config to local storage:', error);
        }
    }, [config]);

    // Update config partially
    const updateConfig = useCallback((partialConfig: Record<string, any>) => {
        setConfigState(prevConfig => ({
            ...prevConfig,
            ...partialConfig,
        }));
    }, []);

    // Dynamic storage operations
    const setFilterItem = useCallback(<T,>(key: string, value: T) => {
        try {
            const storageKey = `${FILTERS_STORAGE_PREFIX}${key}`;
            localStorage.setItem(storageKey, JSON.stringify(value));
        } catch (error) {
            console.error(`Error saving dynamic item ${key} to localStorage:`, error);
        }
    }, []);

    const getFilterItem = useCallback(<T,>(key: string): T | null => {
        try {
            const storageKey = `${FILTERS_STORAGE_PREFIX}${key}`;
            const item = localStorage.getItem(storageKey);
            return item ? JSON.parse(item) : null;
        } catch (error) {
            console.error(`Error loading filter item ${key} from local storage:`, error);
            return null;
        }
    }, []);

    const removeFilterItem = useCallback((key: string) => {
        try {
            const storageKey = `${FILTERS_STORAGE_PREFIX}${key}`;
            localStorage.removeItem(storageKey);
        } catch (error) {
            console.error(`Error removing filter item ${key} from local storage:`, error);
        }
    }, []);

    const getAllFilterItems = useCallback(() => {
        const items: Record<string, any> = {};
        try {
            for (let i = 0; i < localStorage.length; i++) {
                const key = localStorage.key(i);
                if (key?.startsWith(FILTERS_STORAGE_PREFIX)) {
                    const value = localStorage.getItem(key);
                    if (value) {
                        const actualKey = key.replace(FILTERS_STORAGE_PREFIX, '');
                        items[actualKey] = JSON.parse(value);
                    }
                }
            }
        } catch (error) {
            console.error('Error loading all filters from local storage:', error);
        }
        return items;
    }, []);

    const resetAll = useCallback(() => {
        try {
            // Clear only our app's storage items
            for (let i = localStorage.length - 1; i >= 0; i--) {
                const key = localStorage.key(i);
                if (key === CONFIG_STORAGE_KEY || key?.startsWith(FILTERS_STORAGE_PREFIX)) {
                    localStorage.removeItem(key);
                }
            }
            setConfigState(defaultConfig);
        } catch (error) {
            console.error('Error clearing storage:', error);
        }
    }, [defaultConfig]);

    const value = {
        resultAmountValue,
        setResultAmountValue,
        config,
        setConfig: setConfigState,
        updateConfig,
        setFilterItem,
        getFilterItem,
        removeFilterItem,
        getAllFilterItems,
        resetAll,
        options,
        filters,
        setOptions,
        setFilters,
    };

    return <SidebarContext.Provider value={value}>{children}</SidebarContext.Provider>;
};

// Custom hook for using the sidebar context
export const useSidebar = () => {
    const context = useContext(SidebarContext);
    if (context === undefined) {
        throw new Error('useSidebar must be used within a SidebarProvider');
    }
    return context;
};

import App from 'antd/lib/app';
import Col from 'antd/lib/col';
import Row from 'antd/lib/row';
import Skeleton from 'antd/lib/skeleton';
import Typography from 'antd/lib/typography';
import React from 'react';
import { useGreenbuttonListQuery } from '../../domain/greenbutton/queries';
import './greenButtonConnect.css';

export function GreenButtonConnect() {
    const { notification } = App.useApp();

    const { data, isLoading, isError, error } = useGreenbuttonListQuery({}, {});
    const greenButtons = data || [];

    if (isError) {
        notification.error({ key: 'greenbutton', message: error?.message || 'Cannot load greenbutton utilities list' });
    }

    return (
        <>
            <Row gutter={[16, 32]} className="green-btn-container">
                <Col span={24}>
                    <Row justify="center">
                        <Col style={{ textAlign: 'center' }}>
                            <Typography.Title level={2} style={{ color: '#fff' }}>
                                Enersponse Greenbutton - Share My Data
                            </Typography.Title>
                            <Typography.Paragraph className="shared-green-btn-subtitle">
                                Greenbutton is a utility data service which allows smart meter usage to be shared with
                                us daily.
                            </Typography.Paragraph>
                            <Typography.Paragraph className="shared-green-btn-subtitle">
                                Please select your utility below to start the workflow where you will be redirected to
                                their website to grant Enersponse access.
                            </Typography.Paragraph>
                        </Col>
                    </Row>
                </Col>

                {isLoading ? (
                    <Col span={12}>
                        <Skeleton paragraph={{ rows: 4, width: ['100%', '100%'] }} active />{' '}
                    </Col>
                ) : (
                    greenButtons.map(item => (
                        <Col key={item.name} className="green-btn">
                            <a href={item.config.url} target="_blank" rel="noreferrer">
                                <div className="green-btn-img-wrapper">
                                    <img alt={item.name} src={item.logoUrl} />
                                </div>
                                <Typography.Paragraph strong type="secondary">
                                    {item.name}
                                </Typography.Paragraph>
                            </a>
                        </Col>
                    ))
                )}
            </Row>
        </>
    );
}

export const MemoizedGreenButtonConnect = React.memo(GreenButtonConnect);

import inRange from 'lodash/inRange';
import { signalTypes } from '../../../../domain/event/interface';
import { PRICE_RESPONSE_MARKETS_DATA, PriceType } from '../../../../domain/price-response/interface';
import { getTimeAsMinutes } from '../../../../domain/common/timeHelpers';
import { marketMaxPrices } from '../../../../domain/market-prices/interface';

interface IRowData {
    price: number;
    priceType: string;
    market: string;
    loadZone: string;
    duration: number;
    signal: number;
    triggerStartTime: string;
    triggerEndTime: string;
    generalColumnAmount: number;
    cancelWhenPriceLower: string;
}

const formatTriggerTime = (time: string) => {
    if (!time) return '';

    const [hour, minutes] = time.trim().split(':');
    const preparedHour = (hour || '').padStart(2, '0');
    const preparedMinutes = (minutes || '').padStart(2, '0');

    return [preparedHour, preparedMinutes].join(':');
};

const marketZoneValidator = (market: string, zone: string) => {
    // check for required both fields
    if (!market || !zone) {
        return 'Both market and zone should be defined.';
    }
    const marketLst = Object.keys(PRICE_RESPONSE_MARKETS_DATA).map(market => market.toUpperCase());
    //check if market is correct
    if (!marketLst.includes(market.toUpperCase())) {
        return `Please enter correct market. One of ${marketLst.join(', ')}. `;
    }
    // check if zone is part of market
    if (!PRICE_RESPONSE_MARKETS_DATA[market.toUpperCase()].includes(zone)) {
        return `Please enter the correct market's zone. `;
    }

    return null;
};

const marketPriceValidator = (market: string, price: number) => {
    if (typeof market !== 'string' || typeof price !== 'number') {
        //only check types to prevent crash without error message, because that is already controlled by other validators
        return null;
    }
    const max = marketMaxPrices[market.toUpperCase() as keyof typeof marketMaxPrices] || Infinity;
    if (price > max) {
        return `The price is too high for this market. ${market} maximum bid is $${max}`;
    }
    return null;
};

const startEndTimeValidator = (startTime: string, endTime: string) => {
    if (!startTime && !endTime) return null;

    //check if both startTime && endTime exist
    if (!startTime || !endTime) {
        return 'Both start and end time should be defined or both be empty. Allowed format hh:mm. ';
    }
    // check for correct format hh:mm
    const formatPattern = /[0-9]{2}:[0-9]{2}$/;
    if (!formatPattern.test(startTime) || !formatPattern.test(endTime)) {
        return 'Both start and end time should be in format hh:mm. ';
    }
    //check startTime smaller than endTime
    if (getTimeAsMinutes(startTime) >= getTimeAsMinutes(endTime)) {
        return 'End time should be after start time.';
    }

    return null;
};

export const validateRowData = (column: IRowData, numberOfFields: number) => {
    const rowErrors: string[] = [];

    if (column.generalColumnAmount !== numberOfFields) {
        rowErrors.push(
            `Number of fields mismatch: expected ${numberOfFields} fields but parsed ${column.generalColumnAmount}. `
        );
        return rowErrors;
    }

    if (isNaN(column.price) || column.price <= 0 || !Number.isInteger(column.price)) {
        rowErrors.push('Price should be a positive integer number. ');
    }

    if (!column.priceType || ![PriceType.DAY_AHEAD, PriceType.REAL_TIME].includes(column.priceType as PriceType)) {
        rowErrors.push('Price type should be string. Allowed value is real-time or day-ahead. ');
    }

    if (
        isNaN(column.duration) ||
        column.duration < 0 ||
        !inRange(column.duration, 0, 481) ||
        !Number.isInteger(column.duration)
    ) {
        rowErrors.push('Event Duration should be a positive integer number and be in range [0-480](minutes). ');
    }

    if (isNaN(column.signal) || !signalTypes.includes(column.signal) || !Number.isInteger(column.signal)) {
        rowErrors.push(
            'Event Signal should be a integer number. Allowed value is 0-normal, 1-moderate, 2-high, 3-special. '
        );
    }

    if (!column.cancelWhenPriceLower || !['true', 'false'].includes(column.cancelWhenPriceLower)) {
        rowErrors.push('Field "Cancel When Price Lower" should be filled out. Allowed value true or false. ');
    }

    const startEndTimeValidatorError = startEndTimeValidator(column.triggerStartTime, column.triggerEndTime);
    startEndTimeValidatorError && rowErrors.push(startEndTimeValidatorError);

    const marketZoneValidationError = marketZoneValidator(column.market, column.loadZone);
    marketZoneValidationError && rowErrors.push(marketZoneValidationError);

    const marketPriceValidationError = marketPriceValidator(column.market, column.price);
    marketPriceValidationError && rowErrors.push(marketPriceValidationError);

    return rowErrors;
};

const transformParsedRowToData = (columns: string[]): IRowData => {
    // remove first element of array
    // in reason this is company name
    if (columns.length === 11) {
        columns.shift();
    }
    const [
        market,
        loadZone,
        price,
        priceType,
        duration,
        signal,
        triggerStartTime,
        triggerEndTime,
        cancelWhenPriceLower,
    ] = columns;

    return {
        market: market.toLowerCase(),
        loadZone: loadZone.toUpperCase(),
        price: Number(price),
        priceType: priceType,
        duration: Number(duration),
        signal: Number(signal),
        triggerStartTime: formatTriggerTime(triggerStartTime),
        triggerEndTime: formatTriggerTime(triggerEndTime),
        cancelWhenPriceLower: cancelWhenPriceLower.toLowerCase(),
        generalColumnAmount: columns.length,
    };
};

export const parsePriceResponseTriggerCsvData = (content: string, company: number | null) => {
    const errors: string[] = [];
    const result: any = [];
    const rows = content.split('\n').filter(row => row);
    const headers = (rows.shift() || '').split(',');
    // remove first element of array
    // in reason this is company name
    if (headers.length === 11) {
        headers.shift();
    }
    const numberOfColumns = headers.length;

    if (!rows.length) {
        errors.push('File should contains data ');
    }

    rows.forEach((row, rowIndex) => {
        const rowContent = row
            .replace(/[\r\n]+/gm, '')
            .replaceAll('"', '')
            .split(',');
        const preparedRowData = transformParsedRowToData(rowContent);
        const rowErrors = validateRowData(preparedRowData, numberOfColumns);

        if (rowErrors?.length) {
            errors.push(`In row ${rowIndex + 1}: ${rowErrors?.join('')} `);
            return;
        }
        const triggerItem = {
            company_id: company,
            price: preparedRowData.price,
            price_type: preparedRowData.priceType,
            market: preparedRowData.market,
            load_zone: preparedRowData.loadZone,
            duration: preparedRowData.duration,
            signal: preparedRowData.signal,
            start_time: preparedRowData.triggerStartTime,
            end_time: preparedRowData.triggerEndTime,
            cancel_when_price_lower: preparedRowData.cancelWhenPriceLower === 'true',
        };
        result.push(triggerItem);
    });

    return [errors, result];
};

import { useMemo } from 'react';
import App from 'antd/lib/app';
import Select, { SelectProps } from 'antd/lib/select';
import { useAccessPartnerListQuery } from '../../../domain/access-partner/queries';
import { IAccessPartner } from '../../../domain/access-partner/interface';

export const AccessPartnerSelector = (props: SelectProps) => {
    const { notification } = App.useApp();
    const { isLoading, data, isError, error } = useAccessPartnerListQuery({});

    if (isError) {
        notification.error({
            key: 'access-partner-list-error',
            message: error.message || 'Cannot load program operators!',
        });
    }

    const accessPartnerOptions = useMemo(
        () =>
            data?.data?.map((accessPartner: IAccessPartner) => ({
                label: accessPartner.name,
                value: accessPartner.id,
            })),
        [data]
    );

    return (
        <Select
            showSearch
            autoClearSearchValue
            optionFilterProp="label" // to search by label not value
            loading={isLoading}
            placeholder="Select Program Operator"
            options={accessPartnerOptions}
            size="large"
            {...props}
        />
    );
};

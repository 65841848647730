import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '../../query-client';
import { IAnalyticsChartsData } from '../site/interface';
import { fetchSitesEnergyUsageForCharts } from './index';
import { IFetchSitesEnergyDataParams } from './interface';

export const useSitesEnergyUsageForChartsQuery = (query: IFetchSitesEnergyDataParams, options = {}) => {
    return useQuery<IAnalyticsChartsData, Error>({
        queryKey: [QueryKey.ENERGY_USAGES, query],
        queryFn: () => fetchSitesEnergyUsageForCharts(query),
        ...options,
    });
};

import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '../../query-client';
import { fetchRealTimePrice } from './index';
import { IRealTimeNodesPrice, IRealTimeZonesPrice } from './interface';

export const useRealTimePriceListQuery = (options = {}) => {
    return useQuery<(IRealTimeZonesPrice | IRealTimeNodesPrice)[], Error>({
        queryKey: [QueryKey.REAL_TIME_PRICES],
        queryFn: ({ signal }) => fetchRealTimePrice(signal),
        ...options,
    });
};

import * as echarts from 'echarts/core';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { TooltipComponent, GridComponent, MarkAreaComponent } from 'echarts/components';
import { LineChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import { Col, Empty } from 'antd';
import { useMemo } from 'react';
import { IIntervalDataReporting, INTERVAL_DATA_INITIAL_UTILITY } from '../../../../domain/interval-data/interface';
import { toAllowedStringLength } from '../../../../domain/common/formattersToAllowedValueLength';
import { calculateChartHeight } from '../../../../domain/common/chart';
import { omit, pick, sortBy } from 'lodash';
import Card from 'antd/lib/card';
import Typography from 'antd/lib/typography';
import BarChartOutlined from '@ant-design/icons/lib/icons/BarChartOutlined';
import { numberFormatter } from '../../../../domain/common/numberFormatter';
import { SECONDARY_COLOR } from '../../../../theme';

echarts.use([LineChart, TooltipComponent, GridComponent, CanvasRenderer, MarkAreaComponent]);

const commonSettingsForSeriesOfData = {
    type: 'bar',
    stack: 'total',
    barMinHeight: 25,
    barMaxWidth: 20,
    barCategoryGap: '10%',
    emphasis: {
        focus: 'series',
    },
    label: {
        show: true,
        formatter: ({ value }: { value: number }) => numberFormatter.format(value),
    },
};

const initialUtilitiesList = [
    INTERVAL_DATA_INITIAL_UTILITY['CAISO/PGE'],
    INTERVAL_DATA_INITIAL_UTILITY['CAISO/SCE'],
    INTERVAL_DATA_INITIAL_UTILITY['CAISO/SDG1'],
];

const getOtherUtilities = (utility: IIntervalDataReporting['utility']) => {
    const utilityMap = new Map<string, any>();

    Object.entries(utility).forEach(([utilityName, utilityData]) => {
        const key = utilityName.toUpperCase().includes(INTERVAL_DATA_INITIAL_UTILITY.ERCOT)
            ? INTERVAL_DATA_INITIAL_UTILITY.ERCOT
            : 'Other';

        if (!utilityMap.has(key)) {
            utilityMap.set(key, { enrolled: 0, canBeEnrolled: 0 });
        }

        let currentUtilityValue = utilityMap.get(key);
        currentUtilityValue.enrolled += utilityData.enrolled;
        currentUtilityValue.canBeEnrolled += utilityData.canBeEnrolled;
        utilityMap.set(key, currentUtilityValue);
    });

    return [...utilityMap];
};

const prepareData = (utility: IIntervalDataReporting['utility']) => {
    let utilities: string[] = [];
    let enrolled: number[] = [];
    let canBeEnrolled: number[] = [];

    let initialUtilities = pick(utility, initialUtilitiesList);
    let otherUtilities = getOtherUtilities(omit(utility, initialUtilitiesList));

    const sortedUtilityByName = sortBy(
        [...otherUtilities, ...Object.entries(initialUtilities)],
        [([utilityName]) => utilityName]
    ).reverse();
    sortedUtilityByName.forEach(([utilityName, utilityData]) => {
        utilities.push(utilityName);
        enrolled.push(utilityData.enrolled);
        canBeEnrolled.push(utilityData.canBeEnrolled);
    });

    const maxEnrolledValue: number = Math.max(...enrolled) || 0;
    const maxCanBeEnrolledValue: number = Math.max(...canBeEnrolled) || 0;
    const maxValue = Math.max(maxEnrolledValue, maxCanBeEnrolledValue) * 1.1;

    return {
        maxValue,
        utilities,
        preparedData: [
            {
                ...commonSettingsForSeriesOfData,
                name: 'Active Sites',
                data: enrolled,
                itemStyle: {
                    color: '#4a7088',
                    borderRadius: 5,
                },
            },
            {
                ...commonSettingsForSeriesOfData,
                name: 'Inactive Sites',
                data: canBeEnrolled,
                itemStyle: {
                    color: '#fac42a',
                    borderRadius: 5,
                },
            },
        ],
    };
};

const getChartHeight = (utility: any[]) => {
    const numberOfRows = Object.keys(utility).length;
    const height = calculateChartHeight(numberOfRows);

    return height;
};

export const UtilitySummary = ({ utility }: { utility: IIntervalDataReporting['utility'] }) => {
    const { maxValue, utilities, preparedData } = useMemo(() => prepareData(utility), [utility]);
    const height = getChartHeight(preparedData);
    const option = {
        legend: {
            bottom: 30,
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow',
            },
        },
        xAxis: {
            type: 'value',
            splitNumber: 2,
            max: maxValue,
            splitLine: {
                show: false,
            },
            axisLabel: {
                show: false,
                overflow: 'truncate',
            },
        },
        yAxis: {
            type: 'category',
            data: utilities,
            axisLabel: {
                width: 150,
                overflow: 'truncate',
                hideOverlap: true,
                formatter: (label: string) => toAllowedStringLength(label),
            },
            axisLine: {
                show: false,
            },
            axisTick: {
                show: false,
            },
        },
        series: preparedData,
        grid: {
            left: '20%',
            top: 0,
        },
    };

    return (
        <Col xs={24} md={12}>
            <Card
                title={
                    <Typography.Title level={4} style={{ color: SECONDARY_COLOR }}>
                        <BarChartOutlined /> Market/Utility Summary
                    </Typography.Title>
                }
                headStyle={{ borderBottom: 'none' }}
            >
                {Object.keys(utility).length ? (
                    <ReactEChartsCore
                        echarts={echarts}
                        option={option}
                        notMerge={true}
                        lazyUpdate={true}
                        style={{ height: `${height}px`, minHeight: '250px' }}
                    />
                ) : (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
            </Card>
        </Col>
    );
};

import { ISite } from '../../../domain/site/interface';
import { IAffectedSites } from '../eventInterface';

export const transformSitesToResources = (sites: ISite[]): IAffectedSites[] => {
    return sites.map(site => ({
        company: site.company?.company_name!,
        company_id: site.company_id,
        site: site.site_name,
        id: site.site_id.toString(),
    }));
};

import Button from 'antd/lib/button';
import Modal from 'antd/lib/modal';
import Spin from 'antd/lib/spin';
import Tooltip from 'antd/lib/tooltip';
import Typography from 'antd/lib/typography';
import format from 'date-fns/format';
import { useEffect, useState } from 'react';
import { useInterval } from '../../../../components/useInterval';
import { fetchRemoteAccessState, requestRemoteAccess, terminateRemoteAccess } from '../../../../domain/remote-access';
import { IRemoteAccessState, RemoteAccessStateCode } from '../../../../domain/remote-access/interface';
import { ICustomerVen } from '../../../../domain/ven/interface';
import './RemoteAccessModal.css';
import App from 'antd/lib/app';

export interface IRemoteAccessProps {
    ven: ICustomerVen | Partial<ICustomerVen>;
    onClose: Function;
}

export const RemoteAccessModal = ({ ven, onClose }: IRemoteAccessProps) => {
    const { notification } = App.useApp();
    const [remoteState, setRemoteState] = useState<IRemoteAccessState | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [actionLoading, setActionLoading] = useState<boolean>(false);

    useInterval(() => {
        (async () => {
            console.info(`[useInterval] fetchRemoteAccessState`);
            const state = await fetchRemoteAccessState(ven.mac_address!);
            setRemoteState(state);
        })();
    }, 10 * 1000);

    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                const state = await fetchRemoteAccessState(ven.mac_address!);
                setRemoteState(state);
            } catch (err: any) {
                notification.error({ key: 'remote-access-error', message: err.message });
            }
            setLoading(false);
        })();
    }, [ven]);

    async function request(remoteState: IRemoteAccessState) {
        setActionLoading(true);
        try {
            const state = await requestRemoteAccess(ven.mac_address!);
            setRemoteState(state);
            notification.success({
                key: 'remote-access-success',
                message: 'Remote Access is requested, wait a minute until its granted',
            });
        } catch (err: any) {
            notification.error({ key: 'remote-access-error', message: err.message });
        }
        setActionLoading(false);
    }

    async function terminate(remoteState: IRemoteAccessState) {
        setActionLoading(true);
        try {
            const state = await terminateRemoteAccess(ven.mac_address!);
            setRemoteState(state);
            notification.success({ key: 'remote-access-success', message: 'Remote Access will be terminated soon' });
        } catch (err: any) {
            notification.error({ key: 'remote-access-error', message: err.message });
        }
        setActionLoading(false);
    }

    function makeRemoteStateView(remoteState: IRemoteAccessState) {
        if (remoteState.state === RemoteAccessStateCode.IDLE) {
            return (
                <>
                    <Typography.Text keyboard className="remote-access-state">
                        {remoteState.state_text}
                    </Typography.Text>

                    <Tooltip title="Requesting remote access is async operation, it will take a minute to open access">
                        <Button
                            key="remote-access-modal-request"
                            className="remote-access-action"
                            loading={actionLoading}
                            type="primary"
                            onClick={() => request(remoteState)}
                        >
                            Request Remote Access
                        </Button>
                    </Tooltip>
                </>
            );
        }

        if (remoteState.state === RemoteAccessStateCode.REQUESTED) {
            const browserTime = format(new Date(remoteState.datetime_requested!), 'hh:mm a MMM dd, yyyy');

            return (
                <>
                    <Typography.Text keyboard className="remote-access-state">
                        {remoteState.state_text}
                    </Typography.Text>
                    <div>Requested at: {browserTime}</div>
                    <p></p>
                    <div className="remote-access-hint">
                        Wait a minute, the device need a time to setup the remote access. When it is done you will see
                        the connection details here.
                    </div>
                    <Tooltip title="Terminating remote access is async operation, it will take a minute to close access">
                        <Button
                            key="remote-access-modal-terminate"
                            className="remote-access-action"
                            loading={actionLoading}
                            type="primary"
                            danger
                            onClick={() => terminate(remoteState)}
                        >
                            Terminate Remote Access
                        </Button>
                    </Tooltip>
                </>
            );
        }

        if (remoteState.state === RemoteAccessStateCode.ACTIVE) {
            return (
                <>
                    <Typography.Text keyboard className="remote-access-state">
                        {remoteState.state_text}
                    </Typography.Text>

                    <Typography.Text copyable mark>
                        {remoteState.url}
                    </Typography.Text>

                    <div className="remote-access-hint">
                        if not terminated, the remote access will be automatically closed after 1 hour
                    </div>
                    <Tooltip title="Terminating remote access is async operation, it will take a minute to close access">
                        <Button
                            key="remote-access-modal-terminate"
                            className="remote-access-action"
                            loading={actionLoading}
                            type="primary"
                            danger
                            onClick={() => terminate(remoteState)}
                        >
                            Terminate Remote Access
                        </Button>
                    </Tooltip>
                </>
            );
        }
    }

    return (
        <Modal
            open
            destroyOnClose
            width={300}
            className="remote-access-modal"
            title="Remote Access"
            onCancel={() => onClose()}
            footer={[]}
        >
            {loading && (
                <div className="loading-container">
                    <Spin />
                </div>
            )}

            {remoteState ? makeRemoteStateView(remoteState) : ''}
        </Modal>
    );
};

import React from 'react';
import Cascader from 'antd/lib/cascader';
import type { DefaultOptionType } from 'antd/es/cascader';
import { useMarketZoneOptions } from '../../../domain/site/useMarketZoneOptions';

interface IMarketZoneSelectorProps {
    value: string[] | undefined;
    onChange: (value: string[]) => void;
    supportPriceResponse?: boolean;
    includeOther?: boolean;
    [key: string]: any;
}

export const MarketZoneSelector: React.FC<IMarketZoneSelectorProps> = ({
    value,
    onChange,
    supportPriceResponse,
    includeOther,
    ...props
}) => {
    const marketZoneOptions = useMarketZoneOptions({ supportPriceResponse, includeOther });

    const filter = (inputValue: string, path: DefaultOptionType[]) =>
        path.some(option => (option.label as string).toLowerCase().indexOf(inputValue.toLowerCase()) > -1);

    const handleChange = (newValue: unknown) => {
        onChange(newValue as string[]);
    };

    return (
        <Cascader
            allowClear
            size="large"
            placeholder="Select Load Zone"
            data-cy="market-zone-selector"
            showSearch={{ filter }}
            options={marketZoneOptions}
            onChange={handleChange}
            value={value}
            {...props}
        />
    );
};

import HomeOutlined from '@ant-design/icons/HomeOutlined';
import FolderOutlined from '@ant-design/icons/lib/icons/FolderOutlined';
import ToolOutlined from '@ant-design/icons/lib/icons/ToolOutlined';
import Select from 'antd/lib/select';
import Space from 'antd/lib/space';
import Typography from 'antd/lib/typography';
import { COMPANY_TYPE } from 'src/domain/company/interface';

interface ICompanyTypeSelectorProps {
    onChange: (value: COMPANY_TYPE) => void;
}

export function CompanyTypeSelector({ onChange }: ICompanyTypeSelectorProps) {
    return (
        <Select
            defaultValue={COMPANY_TYPE.CUSTOMER_COMPANY}
            size="large"
            onChange={onChange}
            options={[
                {
                    value: COMPANY_TYPE.CUSTOMER_COMPANY,
                    label: 'Customer Company',
                    icon: <HomeOutlined />,
                    desc: 'A regular company for our customers',
                },
                {
                    value: COMPANY_TYPE.CONTROL_PROVIDER,
                    label: 'Control Provider',
                    icon: <ToolOutlined />,
                    desc: 'A company for Control Provider users',
                },
                {
                    value: COMPANY_TYPE.UTILITY_CUSTOMER,
                    label: 'Utility Customer',
                    icon: <FolderOutlined />,
                    desc: 'A company for Utility customer',
                },
            ]}
            optionRender={option => (
                <Space>
                    <span role="img" aria-label={option.data.label} style={{ fontSize: 24 }}>
                        {option.data.icon}
                    </span>
                    <div>
                        <div>{option.data.label}</div>
                        <Typography.Text type="secondary">{option.data.desc}</Typography.Text>
                    </div>
                </Space>
            )}
        />
    );
}

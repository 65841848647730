import { useMemo } from 'react';
import { supportedMarkets } from '../market-prices/interface';
import { PRICE_RESPONSE_MARKETS_DATA } from '../price-response/interface';
import { useMarketZoneListQuery } from '../market/queries';
import { marketLoadZoneToLabel } from '../../components/site/SiteMarketAndLoadZone';

export const useMarketZoneOptions = ({
    supportPriceResponse,
    includeOther,
}: IUseMarketZoneOptionsProps = {}): IOption[] => {
    const { data: loadedMarketZones = {} } = useMarketZoneListQuery();

    return useMemo(() => {
        const marketZones = supportPriceResponse
            ? filterSupportedPriceResponseMarketsAndLoadZones(loadedMarketZones)
            : loadedMarketZones;

        const marketZoneOptions = Object.entries(marketZones).map(([market, zones]) => ({
            value: market,
            label: market,
            children: zones
                .map(zone => ({
                    value: zone,
                    label: marketLoadZoneToLabel(market, zone),
                }))
                .sort((a, b) => a.label.localeCompare(b.label)),
        }));

        if (includeOther) {
            for (const marketZoneOption of marketZoneOptions) {
                marketZoneOption.children.unshift({ value: `undefined-${marketZoneOption.value}`, label: 'All' });
                marketZoneOption.children.push({ value: `null-${marketZoneOption.value}`, label: 'Without Load Zone' });
            }

            marketZoneOptions.push({
                label: 'Without Market',
                value: 'null',
                children: [{ value: 'null', label: 'All' }],
            });
        }

        return marketZoneOptions;
    }, [loadedMarketZones, supportPriceResponse, includeOther]);
};

export function checkIfMarketSupportsPriceResponse(market: string): boolean {
    return Object.keys(PRICE_RESPONSE_MARKETS_DATA).includes(market.toUpperCase());
}

export function checkIfLoadZoneSupportsPriceResponse(market?: string | null, loadZone?: string | null): boolean {
    if (!market || !loadZone) return false;

    return PRICE_RESPONSE_MARKETS_DATA[market.toUpperCase()]?.includes(loadZone.toUpperCase());
}

export function checkIfMarketSupportsCleanResponse(market?: string | null): boolean {
    if (!market) return false;

    return supportedMarkets.includes(market.toLowerCase());
}

function filterSupportedPriceResponseMarketsAndLoadZones(marketZones: IMarketZones): IMarketZones {
    const supportedPriceResponseMarketZones: IMarketZones = {};

    for (const [market, loadZones] of Object.entries(marketZones)) {
        if (!checkIfMarketSupportsPriceResponse(market)) continue;

        supportedPriceResponseMarketZones[market] = loadZones.filter(loadZone =>
            checkIfLoadZoneSupportsPriceResponse(market, loadZone)
        );
    }

    return supportedPriceResponseMarketZones;
}

interface IUseMarketZoneOptionsProps {
    supportPriceResponse?: boolean;
    includeOther?: boolean;
}

interface IMarketZones {
    [key: string]: string[];
}

interface IOption {
    value: string;
    label: string;
    children?: IOption[];
}

import { useCallback, useContext, useEffect, useState } from 'react';
import { Link, Outlet } from 'react-router-dom';

import Layout from 'antd/lib/layout';
import Dropdown from 'antd/lib/dropdown';
import Avatar from 'antd/lib/avatar';
import ExportOutlined from '@ant-design/icons/ExportOutlined';
import UserOutlined from '@ant-design/icons/UserOutlined';
import DownOutlined from '@ant-design/icons/lib/icons/DownOutlined';
import Button from 'antd/lib/button';
import App from 'antd/lib/app';
import Typography from 'antd/lib/typography';

import { useAuth } from '../../domain/auth/useAuth';
import { UserProfile } from '../../pages/userProfile/userProfile';
import { AbilityContext } from '../../components/ability/can';
import { GlobalCompanySelector } from '../../components/selectors/GlobalCompanySelector/GlobalCompanySelector';

import { IUser } from '../../domain/user/interface';
import { fetchUser } from '../../domain/user';
import eLogoSmall from '../../components/icons/e-small-logo.svg';
import { AppNavigation } from '../../components/AppNavigation/AppNavigation';
import logoImg from '../../logo.png';
import config from '../../config';
import { FeedbackModal } from '../../components/modals/feedbackModal/FeedbackModal';
import { initializePendo } from '../../domain/analytics/pendo';
import './PrivateLayout.css';
import Sidebar from '../../components/Sidebar/Sidebar';
import { Col, Flex } from 'antd';
import { SidebarProvider } from 'src/components/Sidebar/context/SidebarContext';

const { Header, Content, Footer } = Layout;

enum APP_ACTION {
    FEEDBACK = 'feedback',
    USER_PROFILE = 'user_profile',
}

const FEATURE_FLAG_SIDEBAR = 'enable_sidebar';

if (localStorage.getItem(FEATURE_FLAG_SIDEBAR) === null) {
    localStorage.setItem(FEATURE_FLAG_SIDEBAR, 'false');
}
const isSidebarEnabled = () => {
    return localStorage.getItem(FEATURE_FLAG_SIDEBAR) === 'true';
};

export function PrivateLayout() {
    const { notification } = App.useApp();
    const [appAction, setAppAction] = useState<APP_ACTION | ''>('');
    const [user, setUser] = useState<IUser>();
    const [shouldDisplaySidebar, setShouldDisplaySidebar] = useState(isSidebarEnabled());

    const auth = useAuth()!;
    const ability = useContext(AbilityContext);

    const fetchUserAction = useCallback(async () => {
        try {
            const user = await fetchUser(auth.user!.user_id);
            setUser(user);
            initializePendo(user);
        } catch (error: any) {
            notification.error({ key: 'fetch-user-error', message: error.message || 'Cannot fetch user!' });
        }
    }, []);

    useEffect(() => {
        fetchUserAction();
    }, []);

    const signout = () => {
        auth.signout();
    };

    const closeUserProfile = (user?: IUser) => {
        closeAppActionModal();
        if (user) {
            fetchUserAction();
        }
    };

    const closeAppActionModal = () => setAppAction('');
    const showAppActionModal = (action: APP_ACTION) => setAppAction(action);

    const userMenu = [
        {
            key: 'profile',
            onClick: () => showAppActionModal(APP_ACTION.USER_PROFILE),
            label: (
                <Typography>
                    <UserOutlined /> Profile
                </Typography>
            ),
        },
        {
            key: 'signout',
            onClick: signout,
            label: (
                <Typography>
                    <ExportOutlined /> Logout
                </Typography>
            ),
        },
    ];

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Header className="header">
                <Link to="/">
                    <img src={eLogoSmall} className="app-logo-small" alt="Enersponse logo" />
                    <img className="app-logo full-logo" src={logoImg} alt="Enersponse logo" />
                </Link>
                {ability.can('filter', 'Company') && <GlobalCompanySelector />}
                <AppNavigation />
                <Dropdown key="user-menu" trigger={['click']} menu={{ items: userMenu }}>
                    <div className="user-menu">
                        <Avatar size={32} icon={<UserOutlined />} />
                        <span className="user-name">{user?.user_fname} </span>
                        <DownOutlined className="user-menu-icon" />
                    </div>
                </Dropdown>
            </Header>
            <Content className={`${shouldDisplaySidebar ? 'layout-container' : ''}`}>
                <SidebarProvider>
                    <Flex>
                        {shouldDisplaySidebar ? (
                            <Sidebar
                                onSidebar={shouldDisplay => {
                                    setShouldDisplaySidebar(shouldDisplay);
                                }}
                            />
                        ) : null}
                        <Col className={`${shouldDisplaySidebar ? 'sidebar-layout-content' : 'layout-content'}`}>
                            <Outlet />

                            <Footer className="footer">
                                <span>(C) Enersponse, LLC. All rights reserved.</span>
                                <br />
                                <span>
                                    Need help? Email{' '}
                                    <a href={`mailto:${config.SUPPORT_EMAIL}`}>{config.SUPPORT_EMAIL}</a> or call +1
                                    (949) 734-0043
                                </span>
                            </Footer>
                        </Col>
                    </Flex>
                    <Button
                        size="large"
                        className="global-feedback-button"
                        onClick={() => showAppActionModal(APP_ACTION.FEEDBACK)}
                        type="primary"
                    >
                        Feedback
                    </Button>
                </SidebarProvider>
            </Content>
            {appAction === APP_ACTION.USER_PROFILE && <UserProfile user={user} onClose={closeUserProfile} />}
            {appAction === APP_ACTION.FEEDBACK && <FeedbackModal onClose={closeAppActionModal} />}
        </Layout>
    );
}

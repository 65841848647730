import React, { useState, useRef, useMemo, useEffect } from 'react';
import { Button, Layout, Typography } from 'antd';
import { useLocation } from 'react-router-dom';

import { DoubleLeftOutlined, DoubleRightOutlined, FilterOutlined } from '@ant-design/icons';
import './sidebar.css';
import { useSidebarResize } from './hooks/Sidebar.hook';
import { useSidebar } from './context/SidebarContext';
import { DEFAULT_SIDEBAR_MAX_WIDTH, DEFAULT_SIDEBAR_MIN_WIDTH, DEFAULT_SIDEBAR_WIDTH } from 'src/domain/commonConst';
import SidebarOptionsPanels from './SidebarPanels/SidebarPanels';
import { parseQueryParams } from 'src/domain/parseQueryParams';
import { usePageLocation } from 'src/pages/usePageState';
import { toUsefulParams } from 'src/pages/toUsefulParams';

const { Sider } = Layout;
const { Text } = Typography;

interface SidebarProps {
    minWidth?: number;
    maxWidth?: number;
    defaultWidth?: number;
    onSidebar?: (shouldDisplay: boolean) => void;
}

// TODO move this to a better place like a configuration file
const AllowedPaths: { [key: string]: boolean } = {
    '/sites': true,
};

const Sidebar: React.FC<SidebarProps> = ({
    minWidth = DEFAULT_SIDEBAR_MIN_WIDTH,
    maxWidth = DEFAULT_SIDEBAR_MAX_WIDTH,
    defaultWidth = DEFAULT_SIDEBAR_WIDTH,
    onSidebar,
}) => {
    const { resultAmountValue, config, setConfig, resetAll, filters, options, setFilterItem, getFilterItem } =
        useSidebar();
    const [collapsed, setCollapsed] = useState(config?.collapsed);
    const [sidebarConfig, setSidebarConfig] = useState<Record<string, any> | null>(null);
    const location = useLocation();

    const siderRef = useRef<HTMLDivElement>(null);
    const { setPageQuery } = usePageLocation();
    const { width, setIsResizing, resetDefault } = useSidebarResize({ minWidth, maxWidth, defaultWidth });

    const shouldDisplay = useMemo(() => {
        const pathAllowed = AllowedPaths[location.pathname as string];
        onSidebar?.(pathAllowed);
        return pathAllowed;
    }, [location, onSidebar]);

    const handleCollapse = (value: boolean) => {
        setCollapsed(value);
        setConfig({
            ...config,
            collapsed: value,
        });
    };

    const startResizing = (e: React.MouseEvent) => {
        setIsResizing(true);
        document.body.style.cursor = 'ew-resize';
        document.body.style.userSelect = 'none';
    };

    const handleOnSave = () => {
        const parsedParams = parseQueryParams(location.search);
        setFilterItem(filters.rootPage || 'filters', {
            path: filters.rootPage,
            ...parsedParams,
        });
    };

    useEffect(() => {
        if (!sidebarConfig && shouldDisplay) {
            const config = getFilterItem(filters.rootPage || '');
            if (config?.filter) {
                const preparedParams = toUsefulParams({
                    filter: {
                        ...config.filter,
                    },
                });

                setTimeout(() => {
                    setPageQuery({ ...preparedParams });
                }, 100);

                setSidebarConfig(config);
            }
        }
    }, [sidebarConfig, shouldDisplay, getFilterItem, setPageQuery]);

    const handleOnClear = () => {
        resetAll();
        resetDefault();
        setPageQuery({});
    };

    const SidebarHeader = () => {
        const resultLabel = useMemo(() => {
            return Number(resultAmountValue) > 1 || Number(resultAmountValue) === 0
                ? `${resultAmountValue} Results`
                : `${resultAmountValue} Result`;
        }, []);

        return (
            <div className={`sidebar-header ${collapsed ? 'collapsed' : ''}`}>
                {!collapsed && <Text className="results">{resultLabel}</Text>}
                <div className="collapse-trigger" onClick={() => handleCollapse(!collapsed)}>
                    {!collapsed && <DoubleLeftOutlined className="collapse-trigger-arrow-left" />}
                    <FilterOutlined className="collapse-trigger-filter-icon" />
                    {collapsed && <DoubleRightOutlined className="collapse-trigger-arrow-right" />}
                </div>
            </div>
        );
    };

    const SidebarActions = () => {
        return (
            <div className="sidebar-footer">
                <Button type="text" onClick={handleOnSave}>
                    Save Filters
                </Button>
                <Button type="primary" onClick={handleOnClear}>
                    Clear Filters
                </Button>
            </div>
        );
    };

    return shouldDisplay ? (
        <Sider
            ref={siderRef}
            trigger={null}
            collapsible
            collapsed={collapsed}
            width={width}
            collapsedWidth={40}
            className={`sidebar ${collapsed ? 'collapsed' : ''}`}
        >
            {/* Header with collapse button */}
            <SidebarHeader />
            <div className="sidebar-options-panels-container">
                {/* Fields and Options filters */}
                {!collapsed && (
                    <SidebarOptionsPanels
                        rootPage={filters.rootPage || ''}
                        filters={filters.props || []}
                        options={options.props || []}
                    />
                )}
                {/* Sidebar Actions */}
            </div>
            {!collapsed && <SidebarActions /> }
            {/* Resizer */}
            {!collapsed && <div className="resizer" onMouseDown={startResizing} />}
        </Sider>
    ) : null;
};

export default Sidebar;

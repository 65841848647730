import eachMonthOfInterval from 'date-fns/eachMonthOfInterval';
import { useMemo } from 'react';

export const useGetFullMonthsFromRange = (startDate: Date, endDate: Date) => {
    return useMemo(() => {
        return eachMonthOfInterval({
            start: startDate,
            end: endDate,
        }).reverse();
    }, [endDate, startDate]);
};

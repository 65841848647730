import { isNull } from 'lodash';
import { IPriceResponseEventMetrics } from './interface';
const getZeroEventMetrics = () => ({
    count: 0,
    days: 0,
    months: 0,
});

export const calculateTotalEventsMetrics = (metrics: IPriceResponseEventMetrics[]) => {
    const result = getZeroEventMetrics();

    if (!metrics) {
        return result;
    }

    metrics.forEach(metricItem => {
        result.count += metricItem.count;
        result.days += metricItem.days;
        !isNull(metricItem.days) && (result.months += 1);
    });

    return result;
};

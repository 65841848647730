import React, { useEffect, useMemo, useState } from 'react';
import { Dictionary, groupBy, isEqual, orderBy } from 'lodash';
import Radio, { RadioChangeEvent } from 'antd/lib/radio';
import Spin from 'antd/lib/spin';
import Empty from 'antd/lib/empty';
import Tooltip from 'antd/lib/tooltip';
import { usePrevious } from '../../usePrevious';
import { fetchSites } from '../../../domain/site';
import { ISite } from '../../../domain/site/interface';
import { CleanResponseMarket } from '../../../domain/clean-response/interface';
import './clean-response-target-sites-selector.css';
import { PRIMARY_COLOR } from '../../../theme';
import { toAllowedStringLength } from '../../../domain/common/formattersToAllowedValueLength';

interface ISelectOption {
    value: string;
    label: string | React.ReactNode;
    sites: ISite[];
    lmp_market?: string;
    site_load_zone?: string;
}

const CLEAN_RESPONSE_MARKETS = Object.values(CleanResponseMarket);

interface ICleanResponseTargetSitesSelectorProps {
    companyId: number;
    market: string;
    onChange: Function;
    hasError: boolean;
}

export const CleanResponseTargetSitesSelector: React.FC<ICleanResponseTargetSitesSelectorProps> = ({
    companyId,
    market,
    onChange,
    hasError,
}) => {
    const prevValue = usePrevious(market);
    const [selectedValue, setSelectedValue] = useState<string | null>(null);
    const { options, loading } = useCleanResponseGroupedSites({ companyId });
    const sortedOptions = useMemo(
        () =>
            orderBy(
                options,
                [
                    option => option.value === selectedValue, // selected value first
                    option => option.label, // then sort alphabetical by label
                    option => option.sites.length, // then sort by number of sites
                ],
                ['desc', 'desc', 'desc']
            ),
        [selectedValue, options]
    );

    const handleChange = (e: RadioChangeEvent) => {
        const value = e.target.value;
        const selectedOption = options.find(option => option.value === value);

        setSelectedValue(value);
        if (!selectedOption) {
            onChange(null);
        }

        onChange(selectedOption!.lmp_market!.toUpperCase());
    };

    useEffect(() => {
        if (!isEqual(prevValue, market)) {
            setSelectedValue(market);
        }
    }, [prevValue, market]);

    if (loading) {
        return (
            <div className="clean-response-target-sites-selector loader-wrapper">
                <Spin spinning size="large" />
            </div>
        );
    }

    if (sortedOptions.length === 0) {
        return <Empty description="No sites enrolled to Clean Response" />;
    }

    return (
        <Radio.Group
            onChange={handleChange}
            value={selectedValue}
            className="clean-response-target-sites-selector wrapper"
        >
            <div
                className="clean-response-target-sites-selector radio-group-options-wrapper"
                style={{ borderColor: hasError ? 'red' : '#d9d9d9' }}
            >
                {sortedOptions.map(option => (
                    <Radio
                        key={option.value}
                        value={option.value}
                        className="clean-response-target-sites-selector radio-item"
                        style={{ border: `1px solid ${option.value === selectedValue ? PRIMARY_COLOR : '#d9d9d9'}` }}
                    >
                        {option.label}
                    </Radio>
                ))}
            </div>
        </Radio.Group>
    );
};

export function useCleanResponseGroupedSites({ companyId }: { companyId: number }): {
    options: ISelectOption[];
    loading: boolean;
} {
    const [options, setOptions] = useState<ISelectOption[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (!companyId) return;

        (async () => {
            setLoading(true);

            const { data } = await fetchSites({
                pagination: { pageSize: 10000, current: 1 },
                company_id: companyId,
            });

            // Filter out sites without market
            let sites: ISite[] = data.filter(site => !!site.lmp_market);

            // Filter out sites from not supported markets
            sites = sites.filter(site => CLEAN_RESPONSE_MARKETS.includes(site.lmp_market! as any));

            // Sites grouped by market
            const result: Dictionary<ISite[]> = groupBy(sites, site => site.lmp_market);
            const sortedMarkets = orderBy(
                Object.keys(result),
                [record => result[record].length, record => record.toString()],
                ['desc', 'desc']
            );

            const newOptions = sortedMarkets.map(groupName => transformSiteGroupToOption(groupName, result));

            setOptions(newOptions);
            setLoading(false);
        })();
    }, [companyId]);

    return {
        loading,
        options,
    };
}

function displaySiteNames(sites: ISite[]) {
    return sites.slice(0, 5).map((site, index) => (
        <span key={index} style={{ display: 'block', whiteSpace: 'nowrap' }}>
            <Tooltip title={site.site_name} placement="right">
                {index + 1}. {toAllowedStringLength(site.site_name, 18)}
            </Tooltip>
        </span>
    ));
}

function transformSiteGroupToOption(groupName: string, sitesDict: Dictionary<ISite[]>) {
    const sites: ISite[] = sitesDict[groupName];
    const site = sites[0];
    const sitesLength = sites.length;

    return {
        value: groupName,
        label: (
            <>
                <b>{groupName.toUpperCase()}</b>
                <br />
                <div>
                    {displaySiteNames(sites)}
                    <small>{sitesLength > 5 ? `And ${sitesLength - 5} more...` : ''}</small>
                </div>
            </>
        ),
        sites: sitesDict[groupName],
        lmp_market: site?.lmp_market,
    };
}

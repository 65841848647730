import { useContext, createContext, useState, useMemo, useEffect } from 'react';
import { BrowserRouter as Router, Navigate, Outlet, useLocation } from 'react-router-dom';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import isAfter from 'date-fns/isAfter';
import { AbilityContext } from '../../components/ability/can';
import { buildAbilityFor, RouteSubject } from '../../components/ability/ability';
import { CompanyProvider } from '../../components/selectors/GlobalCompanySelector/CompanyContext';
import { Auth, Session, ISignin } from './auth';
import { Actor } from './actor';
import { IUser } from '../user/interface';
import '../api';

export interface IUseAuth {
    user: Actor | null;
    signin: ({ data, confirmationCode, reCaptchaToken }: ISignin) => Promise<IUser>;
    signout: () => void;
    refetch: () => Promise<void>;
    checkUserSession: () => boolean;
}

const authContext = createContext<IUseAuth | null>(null);

export function ProvideAuth({ children }: { children: React.ReactNode }) {
    const auth = useProvideAuth();

    return (
        <authContext.Provider value={auth}>
            <AbilityContext.Provider value={auth.ability}>
                <Router>{auth.checkUserSession() ? <CompanyProvider>{children}</CompanyProvider> : children}</Router>
            </AbilityContext.Provider>
        </authContext.Provider>
    );
}

export const useAuth = () => {
    return useContext(authContext);
};

function useProvideAuth() {
    useEffect(() => {
        (async () => refetch())();
    }, []);

    const userState = Session.getUser();
    const actor = userState && new Actor(userState);
    const [user, setUser] = useState<Actor | null>(actor);
    const { executeRecaptcha } = useGoogleReCaptcha();

    const ability = useMemo(() => {
        return buildAbilityFor(user);
    }, [user]);

    const getReCaptchaToken = async () => {
        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return;
        }

        const token = await executeRecaptcha('login');
        return token;
    };

    const signin = async ({ data, confirmationCode }: ISignin) => {
        try {
            const reCaptchaToken = await getReCaptchaToken();
            const user = await Auth.signin({ data, confirmationCode, reCaptchaToken });
            setUser(new Actor(user));
            return user;
        } catch (err: any) {
            throw err;
        }
    };

    const refetch = async () => {
        const user = await Auth.refetch();
        setUser(user ? new Actor(user) : null);
    };

    const signout = () => {
        Auth.signout();
        setUser(null);
    };

    const checkUserSession = () => {
        const token = Session.getToken();
        if (!token) {
            return false;
        }

        const expiresAt = token && new Date(token.expiresAt * 1000);
        const isUserSession = !!user?.user_id && isAfter(expiresAt, new Date());

        if (!isUserSession) {
            Auth.terminateSession();
        }

        return isUserSession;
    };

    return {
        user,
        ability,
        refetch,
        signin,
        signout,
        checkUserSession,
    };
}

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
// or redirect to dashboard if you dont have proper permission
export function PrivateRoute() {
    const auth = useAuth();
    const ability = useContext(AbilityContext);
    const { pathname } = useLocation();

    const checkUserAccessToPage = () =>
        ability.can('view-route', pathname as RouteSubject) ? <Outlet /> : <Navigate to="/" />;

    return auth?.checkUserSession() ? checkUserAccessToPage() : <Navigate to="/login" />;
}

import { endOfHour, startOfHour } from 'date-fns';
import { HOUR_MS } from '../../../../domain/commonConst';
import { max, mean, sum } from 'lodash';
import { formatEnergyValueToFixed } from '../../../../domain/common/formattersToAllowedValueLength';
import { IDateRange } from '../../../interface';
import { IEnergyData } from '../../../../domain/energy/interface';

export enum ENERGY_DATA_TIME_INTERVAL {
    RAW = 'raw',
    HOUR = 'hour',
}

const getOpacity = (value: number, maxValue: number) => {
    return Number((value / maxValue).toFixed(2));
};

export const initHourMap = (dateRange: IDateRange) => {
    const hourMap = new Map<number, number[]>();
    const startTimestamp = startOfHour(dateRange.start).valueOf();
    const endTimestamp = endOfHour(dateRange.end).valueOf();

    let cursor = startTimestamp;
    while (cursor < endTimestamp) {
        hourMap.set(cursor, []);
        cursor += HOUR_MS;
    }
    return hourMap;
};

const calculateSiteAvgUsageByHour = (dateRange: IDateRange, energyData: [number, number][]) => {
    if (!energyData.length) return null;

    const hourMap = initHourMap(dateRange);
    const sitesEnergyAvgByHour = new Map<number, number | null>();

    energyData.forEach(([timestamp, value]) => {
        const startOfHourInterval = startOfHour(timestamp).valueOf();

        if (!hourMap.has(startOfHourInterval)) {
            hourMap.set(startOfHourInterval, [value]);
        } else {
            hourMap.set(startOfHourInterval, [...(hourMap.get(startOfHourInterval) as number[]), value]);
        }
    });

    hourMap.forEach((energy, timestamp) => {
        let avg = mean(energy);
        sitesEnergyAvgByHour.set(timestamp, formatEnergyValueToFixed(avg, 1));
    });

    return sitesEnergyAvgByHour;
};

export const calculateTotalUsageByHour = (dateRange: IDateRange, energyData: IEnergyData[]) => {
    const totalUsageByHour = new Map<number, number | null>();

    energyData.forEach(siteEnergyData => {
        const siteEnergyUsage = calculateSiteAvgUsageByHour(dateRange, siteEnergyData.values);
        if (siteEnergyUsage) {
            siteEnergyUsage.forEach((usage, timestamp) => {
                if (usage) {
                    if (totalUsageByHour.has(timestamp)) {
                        let energyUsage = sum([usage, totalUsageByHour.get(timestamp)]);
                        totalUsageByHour.set(timestamp, formatEnergyValueToFixed(energyUsage, 1));
                    } else {
                        totalUsageByHour.set(timestamp, usage);
                    }
                }
            });
        }
    });

    return totalUsageByHour;
};

const calculateRawSiteAvgUsage = (energyData: [number, number][]) => {
    if (!energyData.length) return null;

    const timeMap = new Map<number, number[]>();
    const sitesEnergyRawAvgByTime = new Map<number, number | null>();

    energyData.forEach(([timestamp, value]) => {
        if (!timeMap.has(timestamp)) {
            timeMap.set(timestamp, [value]);
        } else {
            timeMap.set(timestamp, [...(timeMap.get(timestamp) as number[]), value]);
        }
    });

    timeMap.forEach((energy, timestamp) => {
        let avg = mean(energy);
        sitesEnergyRawAvgByTime.set(timestamp, formatEnergyValueToFixed(avg, 1));
    });

    return sitesEnergyRawAvgByTime;
};

const calculateRawTotalUsage = (energyData: IEnergyData[]) => {
    const totalUsage = new Map<number, number | null>();
    energyData.forEach(siteEnergyData => {
        const siteEnergyUsage = calculateRawSiteAvgUsage(siteEnergyData.values);
        if (siteEnergyUsage) {
            siteEnergyUsage.forEach((usage, timestamp) => {
                if (totalUsage.has(timestamp)) {
                    let energyUsage = sum([usage, totalUsage.get(timestamp)]);
                    totalUsage.set(timestamp, formatEnergyValueToFixed(energyUsage, 1));
                } else {
                    totalUsage.set(timestamp, usage);
                }
            });
        }
    });

    return totalUsage;
};

const calculateTotalUsage = (
    dateRange: IDateRange,
    energyData: IEnergyData[],
    timeInterval: ENERGY_DATA_TIME_INTERVAL
) => {
    const calculateUsage = {
        [ENERGY_DATA_TIME_INTERVAL.HOUR]: calculateTotalUsageByHour(dateRange, energyData),
        [ENERGY_DATA_TIME_INTERVAL.RAW]: calculateRawTotalUsage(energyData),
    };

    return calculateUsage[timeInterval];
};

export const prepareEnergyDataForBarChart = (
    dateRange: IDateRange,
    energyData: IEnergyData[],
    timeInterval: ENERGY_DATA_TIME_INTERVAL
) => {
    const totalEnergyUsage = calculateTotalUsage(dateRange, energyData, timeInterval);

    // let maxUsage = max([...totalEnergyUsage.values()]);
    return [...totalEnergyUsage].map(([timestamp, usage]) => {
        // const opacity = maxUsage && usage ? getOpacity(usage, maxUsage) : 0;
        return { value: [timestamp, usage], itemStyle: { color: `rgba(39,113,173, 0.7)` } };
    });
};

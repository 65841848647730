import Alert from 'antd/lib/alert';
import App from 'antd/lib/app';
import Button from 'antd/lib/button';
import Col from 'antd/lib/col';
import Form from 'antd/lib/form';
import InputNumber from 'antd/lib/input-number';
import Modal from 'antd/lib/modal';
import Row from 'antd/lib/row';
import Select from 'antd/lib/select';
import Space from 'antd/lib/space';
import Typography from 'antd/lib/typography';
import inRange from 'lodash/inRange';
import { useContext, useMemo, useState } from 'react';
import { useAuth } from '../../domain/auth/useAuth';
import { timeToMinutes } from '../../domain/common/timeHelpers';
import { COMPANY_TYPE, ICompany } from '../../domain/company/interface';
import { updateBatchSites } from '../../domain/site';
import { IBatchUpdatedFields, ISite } from '../../domain/site/interface';
import { ResourceCollapsedList } from '../../pages/events/components/ResourceCollapsedList';
import { transformSitesToResources } from '../../pages/events/components/transformSitesToResources';
import { handleLettersNumberInput } from '../../pages/sites/siteInterface';
import { AbilityContext } from '../ability/can';
import {
    filterCompaniesByType,
    transformCompaniesToSelectOption,
} from '../selectors/GlobalCompanySelector/companyHelpers';

interface IUpdateBatchModalProps {
    sitesId: number[];
    companies: ICompany[];
    selectedSites: ISite[];
    onCancel: Function;
}

const preparedUpdatedFieldsValue = (key: string, value: number) => {
    let preparedValue = value;
    if (key === 'event_max_duration') {
        preparedValue = timeToMinutes(value);
    }
    return preparedValue;
};

const prepareFields = (fields: IBatchUpdatedFields) => {
    let preparedUpdatedFields: { [key: string]: number } = {};
    Object.entries(fields).forEach(([key, value]) => {
        if (value) {
            preparedUpdatedFields[key] = preparedUpdatedFieldsValue(key, value);
        }
    });
    return preparedUpdatedFields;
};

export const UpdateSitesBatchModal = ({ selectedSites, onCancel, companies }: IUpdateBatchModalProps) => {
    const { notification } = App.useApp();
    const [form] = Form.useForm();
    const auth = useAuth()!;
    const ability = useContext(AbilityContext);

    const [loading, setLoading] = useState<boolean>(false);
    const controlProviderCompanies = filterCompaniesByType(companies, COMPANY_TYPE.CONTROL_PROVIDER);
    const utilityCustomerCompanies = filterCompaniesByType(companies, COMPANY_TYPE.UTILITY_CUSTOMER);

    const handleCancel = () => onCancel();

    const onFormFinish = async (updatedSiteFields: IBatchUpdatedFields) => {
        setLoading(true);
        try {
            const preparedUpdatedFields = prepareFields(updatedSiteFields);
            const sitesId = selectedSites.map(site => site.site_id);

            await updateBatchSites(sitesId, preparedUpdatedFields);
            onCancel(true);
        } catch (error: any) {
            notification.error({ key: 'enroll-program-error', message: error.message || 'Cannot update sites batch!' });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            title="Update Sites Batch"
            open
            destroyOnClose
            onCancel={handleCancel}
            footer={[
                <Button key="update-sites-batch-modal-cancel" onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button key="update-sites-batch-modal-submit" type="primary" loading={loading} onClick={form.submit}>
                    Update
                </Button>,
            ]}
        >
            <Alert
                message="Next changes will be applied to all selected sites."
                type="warning"
                style={{ marginBottom: '8px' }}
                showIcon
            />
            <ResourceCollapsedList resources={transformSitesToResources(selectedSites)} />
            <br />
            <Form form={form} name="update-sites-batch-form" preserve={false} layout="vertical" onFinish={onFormFinish}>
                {ability.can('attach', 'ControlProvider') && (
                    <Form.Item
                        name="control_provider_id"
                        label={<Typography.Text strong>Control Provider</Typography.Text>}
                    >
                        <Select
                            allowClear
                            placeholder="Please select Control Provider"
                            size="large"
                            options={transformCompaniesToSelectOption(controlProviderCompanies)}
                        />
                    </Form.Item>
                )}

                {ability.can('attach', 'UtilityCustomer') && (
                    <Form.Item
                        name="utility_customer_id"
                        label={<Typography.Text strong>Utility Customer</Typography.Text>}
                    >
                        <Select
                            allowClear
                            placeholder="Please select Utility Customer"
                            size="large"
                            options={transformCompaniesToSelectOption(utilityCustomerCompanies) as any}
                        />
                    </Form.Item>
                )}
                {auth.user?.isAdminRoleType() && (
                    <>
                        <Form.Item
                            name="site_estimated_kw"
                            label={<Typography.Text strong>Site estimated KW</Typography.Text>}
                            rules={[
                                {
                                    validator(_, value) {
                                        if (!value) {
                                            form.setFieldsValue({ site_estimated_kw: 0 });
                                            return Promise.resolve();
                                        }
                                        if (inRange(value, 0, 2147483648)) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('Value should be in range[0-2147483647]'));
                                    },
                                },
                            ]}
                        >
                            <InputNumber type="number" min={0} size="large" onKeyPress={handleLettersNumberInput} />
                        </Form.Item>

                        <Row gutter={8}>
                            <Col span={12}>
                                <Form.Item
                                    label={<Typography.Text strong>Event maximum duration</Typography.Text>}
                                    tooltip={
                                        <>
                                            <p style={{ marginBottom: 0 }}>
                                                Allow a maximum duration to be applied to the event time.
                                            </p>
                                            <p>Allowed interval is 0-4 hours</p>
                                        </>
                                    }
                                >
                                    <Space>
                                        <Form.Item name="event_max_duration" noStyle>
                                            <InputNumber
                                                min={0}
                                                max={4}
                                                step={0.25}
                                                size="large"
                                                onKeyPress={handleLettersNumberInput}
                                            />
                                        </Form.Item>
                                        <Typography.Text>hours</Typography.Text>
                                    </Space>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label={<Typography.Text strong>Event Offset</Typography.Text>}
                                    tooltip={
                                        <>
                                            <p style={{ marginBottom: 0 }}>
                                                Allow an offset to be applied to the event time to start earlier than
                                                scheduled.
                                            </p>
                                            <p style={{ marginTop: 0 }}>Allowed interval is 0-30 min</p>
                                        </>
                                    }
                                >
                                    <Space>
                                        <Form.Item
                                            name="event_offset"
                                            rules={[
                                                {
                                                    validator(_, value) {
                                                        if (!value) {
                                                            form.setFieldsValue({ event_offset: 0 });
                                                            return Promise.resolve();
                                                        }
                                                        if (inRange(value, 0, 31)) {
                                                            return Promise.resolve();
                                                        }

                                                        return Promise.reject(
                                                            new Error('Value should be in range[0-30]')
                                                        );
                                                    },
                                                },
                                            ]}
                                            noStyle
                                        >
                                            <InputNumber
                                                type="number"
                                                size="large"
                                                onKeyPress={handleLettersNumberInput}
                                            />
                                        </Form.Item>
                                        <Typography.Text>min</Typography.Text>
                                    </Space>
                                </Form.Item>
                            </Col>
                        </Row>
                    </>
                )}
            </Form>
        </Modal>
    );
};

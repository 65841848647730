import React, { useEffect, useMemo, useState } from 'react';

import Button from 'antd/lib/button';
import TreeSelect from 'antd/lib/tree-select';
import Tag from 'antd/lib/tag';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import Row from 'antd/lib/row';
import Spin from 'antd/lib/spin';
import App from 'antd/lib/app';

import { fetchSites } from '../../../domain/site';
import { ISite } from '../../../domain/site/interface';
import './reportingSiteSelector.css';

interface IReportingSiteSelector {
    companyId: number | null;
    onChange: Function;
    selectedSitesId: number[];
    disabled: boolean;
    setSelectedSites?: Function;
}

interface ITreeSelectOption {
    title: string;
    key: number | string | number[];
    value: number | string | number[];
    children: any;
}

const groupByLoadZone = (data: ISite[]) => {
    const result: ITreeSelectOption[] = [];
    const getLoadZone = (item: ISite) => item?.site_load_zone;
    const groupedData = groupBy(data, getLoadZone);

    Object.keys(groupedData).forEach(key => {
        const isGroupWithoutZone = key === 'null';

        const elementsOfGroup = groupedData[key].map(el => ({
            title: el.site_name,
            value: el.site_id,
            key: el.site_id,
        }));

        const preparedItem = {
            title: isGroupWithoutZone ? 'WITHOUT LOAD ZONE' : key,
            key: `zone-${key}`,
            value: `zone-${key}`,
            children: elementsOfGroup,
        };
        isGroupWithoutZone ? result.unshift(preparedItem) : result.push(preparedItem);
    });

    return sortBy(result, 'title');
};

export const ReportingSiteSelector = ({
    companyId,
    selectedSitesId,
    onChange,
    disabled,
    setSelectedSites,
}: IReportingSiteSelector) => {
    const { notification } = App.useApp();
    const [sites, setSites] = useState<ISite[]>([]);
    const [currentSelection, setCurrentSelection] = useState<number[]>([]);
    const [allSitesIds, setAllSitesIds] = useState<number[]>([]);
    const [loading, setLoading] = useState(false);
    const preparedSitesOptions = useMemo(() => groupByLoadZone(sites) as any, [sites]);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setSites([]);
        setCurrentSelection([]);
        if (!companyId) {
            return;
        }

        (async () => {
            try {
                setLoading(true);
                let { data } = await fetchSites({
                    pagination: { pageSize: 10000, current: 1 },
                    filter: { company_id: companyId },
                });
                const companySitesIds = data.map(site => site.site_id);
                const companySelectedSitesId = (selectedSitesId || []).filter(siteId =>
                    companySitesIds.includes(siteId)
                );
                setCurrentSelection(companySelectedSitesId);
                if (!companySelectedSitesId.length) {
                    onChange([]);
                }
                if (setSelectedSites && companySelectedSitesId.length) {
                    let selectedSites = data.filter(site => (companySelectedSitesId || []).includes(site.site_id));
                    setSelectedSites(selectedSites);
                }
                setAllSitesIds(companySitesIds);
                setSites(data);
            } catch (error: any) {
                notification.error({
                    key: 'fetch-sites-error',
                    message: error.message || 'Cannot fetch sites data!',
                });
            } finally {
                setLoading(false);
            }
        })();
    }, [companyId]);

    const submitSelectedSites = () => {
        onChange(currentSelection);

        if (setSelectedSites) {
            let selectedSites = sites.filter(site => (currentSelection || []).includes(site.site_id));
            setSelectedSites(selectedSites);
        }
        setOpen(false);
    };

    const customDropdownRender = (menu: React.ReactElement) => {
        return (
            <div>
                {loading ? (
                    <Row justify="center" style={{ width: '100%', padding: '8px 0' }}>
                        <Spin size="large" />
                    </Row>
                ) : (
                    menu
                )}
                <Row justify="space-around" style={{ paddingTop: '8px', paddingBottom: '8px' }}>
                    <Button
                        type="text"
                        onClick={() => setCurrentSelection(allSitesIds)}
                        disabled={currentSelection.length === sites.length}
                    >
                        Select all
                    </Button>
                    <Button
                        type="text"
                        onClick={() => setCurrentSelection([])}
                        disabled={currentSelection.length === 0}
                    >
                        Deselect all
                    </Button>
                    <Button type="primary" onClick={submitSelectedSites}>
                        OK
                    </Button>
                </Row>
            </div>
        );
    };

    return (
        <TreeSelect
            allowClear
            multiple
            treeCheckable
            maxTagCount={1}
            size="large"
            style={{ width: '300px' }}
            popupClassName="reportingSiteSelector"
            className="reporting-site-selector"
            dropdownRender={customDropdownRender}
            listHeight={300}
            placeholder="Select site"
            value={currentSelection}
            data-cy="sites-selector"
            onChange={value => {
                setCurrentSelection(value);
                !value.length && onChange([]);
            }}
            disabled={disabled}
            tagRender={props => <Tag className="site-tag">{props.label}</Tag>}
            maxTagPlaceholder={props => <Tag>{`+${props.length}...`}</Tag>}
            treeData={[...preparedSitesOptions]}
            treeNodeFilterProp="title"
            loading={loading}
            onDropdownVisibleChange={setOpen}
            open={open}
        />
    );
};

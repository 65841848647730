import { Empty, Typography } from 'antd';
import Card from 'antd/lib/card/Card';
import { IEnergyData } from '../../../../domain/energy/interface';
import { useMemo } from 'react';
import { flatten, isNull } from 'lodash';
import { addMinutes, format } from 'date-fns';
import { IDateRange } from '../../../interface';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { BarChart, LineChart } from 'echarts/charts';
import {
    DataZoomComponent,
    GridComponent,
    LegendScrollComponent,
    MarkAreaComponent,
    TooltipComponent,
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import { ENERGY_DATA_TIME_INTERVAL, prepareEnergyDataForBarChart } from './prepareEnergyDataForBarChart';
import { formatEnergyUsageData } from './prepareEnergyDataForHeatMap';
import { IEventData } from '../../../../domain/event/getEventsDataService';
import { SECONDARY_COLOR } from '../../../../theme';

echarts.use([
    BarChart,
    LineChart,
    TooltipComponent,
    GridComponent,
    CanvasRenderer,
    MarkAreaComponent,
    DataZoomComponent,
    LegendScrollComponent,
]);

interface IEnergyDemandWidget {
    energyData: IEnergyData[];
    dateRange: IDateRange;
    energyBaselineData: [number, number][];
    eventData: IEventData[];
    energyPerformanceData: [number, number][];
}

const buildMarkAreaItem = (start: Date | number, end: Date | number, color: string) => [
    { xAxis: start, itemStyle: { color } },
    { xAxis: end },
];

const preparedEventData = (eventsData: IEventData[]) => {
    let preparedData: any = [];

    if (!eventsData.length) return [];

    eventsData.forEach(siteEvents =>
        siteEvents.data.forEach(event => {
            const eventStart = new Date(event.event_datetime_start);
            const eventEnd = addMinutes(eventStart, event.event_duration);
            const eventArea = buildMarkAreaItem(eventStart, eventEnd, '#fac42a');

            preparedData.push([eventArea]);
        })
    );

    return flatten(preparedData);
};

const filteredPerformanceData = (data: [number, number | null][], eventData: IEventData[]) => {
    if (!data.length || !eventData.length) return [];

    return data.map(([timestamp, performance]) => {
        let preparedPerformance = performance;
        if (!isNull(preparedPerformance)) {
            const isInEvent = eventData.some(siteEvents =>
                siteEvents.data.some(event => {
                    const eventStart = new Date(event.event_datetime_start).getTime();
                    const eventEnd = eventStart + event.event_duration * 60 * 1000;
                    const windowStart = timestamp - 59 * 60 * 1000;
                    const windowEnd = timestamp + 59 * 60 * 1000;

                    return eventStart <= windowEnd && eventEnd >= windowStart;
                })
            );
            if (!isInEvent) {
                preparedPerformance = null;
            }
        }
        return [timestamp, preparedPerformance];
    });
};

export const EnergyDemandWidget = ({
    dateRange,
    energyData,
    energyBaselineData,
    eventData,
    energyPerformanceData,
}: IEnergyDemandWidget) => {
    const isDataExist = useMemo(() => {
        // Filter out data that is not in the date range
        const filteredEnergyData = energyData.filter(
            el =>
                el.values.filter(
                    ([timestamp, usage]) =>
                        timestamp >= dateRange.start.valueOf() && timestamp <= dateRange.end.valueOf() && usage > 0
                ).length
        );

        return filteredEnergyData.length || energyBaselineData.length || energyPerformanceData.length;
    }, [dateRange.end, dateRange.start, energyBaselineData.length, energyData, energyPerformanceData.length]);

    const baselineChartData = useMemo(
        () => formatEnergyUsageData(dateRange, energyBaselineData),
        [dateRange, energyBaselineData]
    );
    const performanceChartData = useMemo(
        () => formatEnergyUsageData(dateRange, energyPerformanceData),
        [dateRange, energyPerformanceData]
    );
    const preparedEnergyData = useMemo(
        () => prepareEnergyDataForBarChart(dateRange, energyData, ENERGY_DATA_TIME_INTERVAL.RAW),
        [energyData, dateRange]
    );

    const option = {
        legend: {},
        tooltip: {
            formatter: (params: any) => {
                let tooltipText = '';
                if (params.value) {
                    const [date, usage] = params.value;
                    const formattedDate = format(new Date(date), 'yyyy-MM-dd  HH:mm:ss');
                    const uom = 'kW';
                    tooltipText = `${formattedDate}</br> ${params.marker}<strong>${usage} ${uom}</strong></br>`;
                }

                return tooltipText;
            },
        },
        grid: {
            bottom: '7%',
            right: '10%',
        },
        xAxis: {
            type: 'time',
            axisLabel: {
                formatter: {
                    year: '{yyyy}',
                    month: '{MMM}',
                    day: '{dd}-{MMM}',
                    hour: '{HH}:{mm}',
                    minute: '{HH}:{mm}',
                },
            },
            boundaryGap: true,
            max: dateRange.end.valueOf(),
            min: dateRange.start.valueOf(),
        },
        yAxis: [
            {
                type: 'value',
                name: 'kW',
                nameLocation: 'middle',
                nameGap: 40,
                position: 'left',
                alignTicks: true,
                axisLabel: { show: true },
            },
        ],
        series: [
            {
                type: 'bar',
                name: 'kW',
                legend: {
                    itemStyle: {
                        color: '#2771ad',
                    },
                },
                showSymbol: false,
                emphasis: {
                    focus: 'series',
                },
                data: preparedEnergyData,
                markArea: {
                    data: preparedEventData(eventData),
                },
                itemStyle: {
                    color: '#2771ad',
                },
            },
            {
                type: 'line',
                emphasis: { focus: 'series' },
                smooth: true,
                name: 'Site energy baseline',
                data: baselineChartData,
                itemStyle: {
                    color: '#ce7553',
                },
            },
            {
                type: 'line',
                emphasis: { focus: 'series' },
                smooth: true,
                name: 'Site energy performance',
                data: filteredPerformanceData(performanceChartData, eventData),
                itemStyle: {
                    color: '#ad4840',
                },
            },
        ],
        dataZoom: [
            {
                filterMode: 'weakFilter',
                type: 'inside',
                minSpan: 2,
            },
        ],
    };

    return (
        <Card style={{ marginBottom: '16px' }} className="energy-usage-widget">
            <Typography.Title level={4} style={{ marginTop: 0, color: SECONDARY_COLOR }}>
                Energy Demand Over Time
            </Typography.Title>
            <div className="site-energy-chart-title">
                {isDataExist ? (
                    <ReactEChartsCore echarts={echarts} option={option} notMerge lazyUpdate />
                ) : (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
            </div>
        </Card>
    );
};

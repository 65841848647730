import { Col, Divider, Row } from 'antd';
import { IIntervalDataReporting } from '../../../../domain/interval-data/interface';
import BankOutlined from '@ant-design/icons/BankOutlined';
import Card from 'antd/lib/card';
import Typography from 'antd/lib/typography';
import Progress from 'antd/lib/progress';
import { numberFormatter } from '../../../../domain/common/numberFormatter';
import { SECONDARY_COLOR } from '../../../../theme';

const calcPercent = (num: number, totalNum: number) => {
    if (!totalNum) return 0;
    const res = (num / totalNum) * 100;
    return parseFloat(res.toFixed());
};

export const SitesSummary = ({ sites }: { sites: IIntervalDataReporting['sites'] }) => {
    const tolalActiveAndInactiveSites = sites.enrolled + sites.canBeEnrolled;
    const activeSitesPercent = calcPercent(sites.enrolled, tolalActiveAndInactiveSites);
    const inactiveSitesPercent = calcPercent(sites.canBeEnrolled, tolalActiveAndInactiveSites);

    return (
        <Col xs={24} md={12}>
            <Card>
                <Row justify="space-between">
                    <Col xs={12}>
                        <Typography.Title level={4} style={{ margin: 0, color: SECONDARY_COLOR }}>
                            <BankOutlined /> Sites
                        </Typography.Title>
                    </Col>
                    <Col xs={12}>
                        <Typography.Text style={{ fontSize: '1.875rem' }}>
                            {numberFormatter.format(sites.total)}
                        </Typography.Text>
                        <Typography.Paragraph style={{ marginBottom: 0, fontSize: '16px' }}>
                            Total Sites on DRMS
                        </Typography.Paragraph>
                    </Col>
                    <Divider style={{ margin: '16px 0' }} />
                    <Col>
                        <Row justify="start">
                            <div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <span
                                        className="progress-marker"
                                        style={{ background: '#4a7088', marginRight: 5 }}
                                    />
                                    <Typography.Title type="secondary" level={5} style={{ margin: 0 }}>
                                        Active Sites
                                    </Typography.Title>
                                </div>
                                <Typography className="content-value">{activeSitesPercent}%</Typography>
                                <Typography>{numberFormatter.format(sites.enrolled)}</Typography>
                            </div>
                        </Row>
                    </Col>
                    <Divider type="vertical" style={{ height: '70px' }} />
                    <Col>
                        <Row justify="end">
                            <div style={{ textAlign: 'end' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography.Title type="secondary" level={5} style={{ margin: 0 }}>
                                        Inactive Sites
                                    </Typography.Title>
                                    <span
                                        className="progress-marker"
                                        style={{ background: '#fac42a', marginLeft: 5 }}
                                    />
                                </div>
                                <Typography className="content-value">{inactiveSitesPercent}%</Typography>
                                <Typography>{numberFormatter.format(sites.canBeEnrolled)}</Typography>
                            </div>
                        </Row>
                    </Col>
                    <Col xs={24}>
                        <Row justify="space-between">
                            <Col style={{ width: `${activeSitesPercent}%` }}>
                                <Progress percent={100} showInfo={false} strokeColor={'#4a7088'} />
                            </Col>
                            <Col style={{ width: `${inactiveSitesPercent}%` }}>
                                <Progress percent={100} showInfo={false} strokeColor={'#fac42a'} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card>
        </Col>
    );
};

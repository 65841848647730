import { DownOutlined } from '@ant-design/icons';
import Button from 'antd/lib/button';
import Tooltip from 'antd/lib/tooltip';
import useLocalStorage from '../../../domain/useLocalStorage';
import { EventLegendList } from './EventLegendList';

const STORAGE_KEY = 'event-legend-overlay';

export const EventLegendOverlay: React.FC = () => {
    const [open, setOpen] = useLocalStorage<boolean>(STORAGE_KEY, false);

    return (
        <div
            style={{
                position: 'absolute',
                right: 12,
                bottom: 10,
                zIndex: 5,

                height: open ? 380 : 42,
                width: 270,

                background: 'white',
                padding: 12,
                borderRadius: 6,
                overflow: 'hidden',
                transition: 'all 0.5s ease-in-out',
                border: '1px solid rgb(221, 221, 221)',
                backgroundColor: 'rgba(255, 255, 255, 1)',
            }}
        >
            <EventLegendList vertical />

            <Tooltip title={`${open ? 'Minimize' : 'Show all'} Legends`}>
                <Button
                    size="small"
                    icon={<DownOutlined rotate={open ? 0 : 180} />}
                    style={{ position: 'absolute', top: 9, right: '5%' }}
                    onClick={() => setOpen(!open)}
                />
            </Tooltip>
        </div>
    );
};

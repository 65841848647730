import React, { useEffect, useState } from 'react';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import Form from 'antd/lib/form';
import Typography from 'antd/lib/typography';
import InputNumber from 'antd/lib/input-number';
import { handleLettersNumberInput } from '../../pages/sites/siteInterface';
import { CarbonIntensityPresetSelector } from '../selectors/CarbonIntensityPresetSelector/CarbonIntensityPresetSelector';

interface ICarbonIntensityInputProps {
    value: number;
    onChange: (value: number) => void;
}

const SUGGESTED_CARBON_INTENSITY_VALUES = [800, 600, 300];

export const CarbonIntensityInput: React.FC<ICarbonIntensityInputProps> = ({ value, onChange }) => {
    const form = Form.useFormInstance();
    const [selectedPreset, setSelectedPreset] = useState<number | null>(null);

    const handleInputChange = async (value: number) => {
        const updatedPreset = SUGGESTED_CARBON_INTENSITY_VALUES.includes(value) ? value : null;

        setSelectedPreset(updatedPreset);
        onChange(value);

        await form.validateFields(['carbon_intensity']);
    };

    useEffect(() => {
        const v = SUGGESTED_CARBON_INTENSITY_VALUES.includes(value) ? value : null;
        setSelectedPreset(v);
    }, [value]);

    return (
        <Row justify="space-between">
            <Col span={12}>
                <Form.Item
                    required
                    htmlFor="carbon_intensity"
                    label={
                        <Typography.Text strong>
                            Carbon Intensity (CO<sub>2</sub>/MWh)
                        </Typography.Text>
                    }
                >
                    <Form.Item
                        noStyle
                        name="carbon_intensity"
                        rules={[
                            {
                                required: true,
                                message: 'Please select carbon intensity!',
                                validator: () => {
                                    if (!value && value !== 0) {
                                        return Promise.reject();
                                    }

                                    return Promise.resolve();
                                },
                            },
                        ]}
                    >
                        <InputNumber
                            id="carbon_intensity"
                            min={0}
                            max={2000}
                            step={100}
                            size="large"
                            placeholder="0"
                            value={value}
                            style={{ width: '125px' }}
                            onChange={handleInputChange as any}
                            onKeyPress={handleLettersNumberInput}
                        />
                    </Form.Item>
                </Form.Item>
            </Col>

            <Col span={12}>
                <Form.Item label={<Typography.Text strong>Select Trigger Preset</Typography.Text>}>
                    <CarbonIntensityPresetSelector value={selectedPreset} onSelect={handleInputChange} />
                </Form.Item>
            </Col>
        </Row>
    );
};

import App from 'antd/lib/app';
import Button from 'antd/lib/button';
import DatePicker from 'antd/lib/date-picker';
import Modal from 'antd/lib/modal';
import Space from 'antd/lib/space';
import Typography from 'antd/lib/typography';
import eachMonthOfInterval from 'date-fns/eachMonthOfInterval';
import format from 'date-fns/format';
import startOfDay from 'date-fns/startOfDay';
import dayjs, { Dayjs } from 'dayjs';
import isEmpty from 'lodash/isEmpty';
import { useMemo, useState } from 'react';
import { exportXMLResources } from '../../../domain/caiso-resource';
import { IDateRange } from '../../interface';
import { defaultDate, IDownloadXMLModal, validateRequiredMWValue } from '../interface';

export const DownloadXMLModal = ({ onCancel, resources, monthsPeriod }: IDownloadXMLModal) => {
    const { notification } = App.useApp();
    const [dateRange, setDateRange] = useState<IDateRange>({ ...defaultDate });
    const handleDate = (dates: any, formattedDate: [string, string]) => {
        const selectedDate = { start: startOfDay(new Date(formattedDate[0])), end: new Date(formattedDate[1]) };
        setDateRange(selectedDate);
    };
    const [errors, setErrors] = useState<string[]>([]);
    const [exportLoading, setExportLoading] = useState<boolean>(false);

    const handleCancel = () => onCancel();
    const lastAllowedMonth = useMemo(() => monthsPeriod[monthsPeriod.length - 1], [monthsPeriod]);

    const validateResources = () => {
        const selectedMonthPeriod = eachMonthOfInterval({ start: dateRange.start, end: dateRange.end }).map(date =>
            format(date, 'yyyy/MM')
        );
        const resourceErrors: string[] = [];

        resources.forEach(resource => {
            const resourceError = validateRequiredMWValue(resource.months, selectedMonthPeriod);
            resourceError.length && resourceErrors.push(`${resource.name}: ${resourceError.join(' ')}`);
        });

        return resourceErrors;
    };

    const handleExportXMLFile = async () => {
        try {
            const resourcesErrors = validateResources();
            if (!isEmpty(resourcesErrors)) {
                setErrors(resourcesErrors);
                return;
            }

            setExportLoading(true);
            await exportXMLResources(dateRange);
            handleCancel();
        } catch (error: any) {
            notification.error({ key: 'sites-export-error', message: error.message || 'Cannot export resources!' });
        }
        setExportLoading(false);
    };

    const disabledDate = (current: Dayjs) => {
        const startDate = dayjs().startOf('month');
        const endDate = dayjs(lastAllowedMonth).endOf('month');

        return current < startDate || current > endDate;
    };

    return (
        <Modal
            open
            destroyOnClose
            title="Download XML"
            onCancel={handleCancel}
            footer={[
                <Button key="download-xml-modal-cancel" onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button
                    key="download-xml-modal-submit"
                    type="primary"
                    loading={exportLoading}
                    onClick={handleExportXMLFile}
                >
                    Download XML
                </Button>,
            ]}
        >
            <Typography.Paragraph>You can choose date period for CAISO SC resources XML file.</Typography.Paragraph>
            <DatePicker.RangePicker
                size="large"
                disabledDate={disabledDate}
                onChange={handleDate}
                onCalendarChange={handleDate}
                // @ts-ignore
                value={[dayjs(dateRange.start), dayjs(dateRange.end)]}
                format="MM/DD/YYYY"
                allowClear={false}
                changeOnBlur
            />

            {Boolean(errors.length) && (
                <Space direction="vertical">
                    {errors.map((error, i) => (
                        <Typography.Text type="danger" key={`resource-err-${i}`}>
                            {error}
                        </Typography.Text>
                    ))}
                </Space>
            )}
        </Modal>
    );
};

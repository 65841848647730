import _, { get, set, uniqBy } from 'lodash';
import { ISite } from '../../../domain/site/interface';
import { IEventsCountBySiteAndYears } from '../../../domain/event/interface';
import { ICloudVen, ICustomerVen } from '../../../domain/ven/interface';
import { IMergingSite, IResultsColumnData, ITransformedTableItem } from './interface';

export class MergeSitesTableUtils {
    public static MAPPED_HEADERS = {
        control_provider_name: 'Control Provider Name',
        utility_customer_name: 'Utility Customer Name',

        location: 'Location',
        marketAndLoadZone: 'Load Zone',

        site_store_number: 'Store Number',
        site_utility: 'Utility',
        site_network: 'Network',
        site_resource_id: 'Resource ID',
        partner_id: 'Partner ID',

        site_estimated_kw: 'Estimated KW',
        event_offset: 'Event Offset',
        event_max_duration: 'Event Max Duration',

        site_highlight: 'Highlight',
        email_only_integration: 'Email Only Integration',

        sans: 'Enrollment IDs',
        programs: 'Programs',

        site_price_response_enrolled: 'Price Response Enrolled',
        site_clean_response_enrolled: 'Clean Response Enrolled',

        customerVenFromSiteId: 'Customer VEN',
        cloudVensFromSiteIds: 'Cloud VENs',

        eventsFromSiteId: 'Events',

        site_label: 'Labels',
    };

    public static COLUMNS = Object.keys(this.MAPPED_HEADERS);

    public static MERGEABLE_COLUMNS = ['sans', 'programs', 'site_label', 'cloudVensFromSiteIds'];

    public static SELECTED_CELL_BG_COLOR = 'rgb(223, 237, 235)'; // Light green

    public static transformSiteToTableView(mergingSites: IMergingSite[]): ITransformedTableItem[] {
        return this.COLUMNS.map(header => {
            const tableData: ITransformedTableItem = { header, result: 'Result' };

            mergingSites.forEach((site, index) => {
                // set(tableData, 'key', `site_${site.site_id}_${index}`);
                set(tableData, `site_${site.site_id}`, get(site, header, ''));
            });

            return tableData;
        });
    }

    public static mergeResultsColumnValues(obj: IResultsColumnData): IMergingSite {
        const result: IResultsColumnData = {};

        // Iterate over each entry in the object
        _.each(obj, (value, key) => {
            const propertyName = key.replace(/^\d+_/, ''); // Strip the siteId prefix

            if (Array.isArray(_.get(result, propertyName))) {
                // Concatenate arrays, ensure unique elements for arrays
                result[propertyName] = _.union(get(result, propertyName, []) as string[], value as string[]);
            } else if (_.isObject(value) && !Array.isArray(value) && _.isObject(_.get(result, propertyName))) {
                // Merge objects into an array of objects
                result[propertyName] = _.concat(result[propertyName] || [], value);
            } else {
                // Set or overwrite the value
                result[propertyName] = value;
            }
        });

        return result as unknown as IMergingSite;
    }

    public static getCloudVensBySiteIds(siteIds: number[], sites: ISite[]): ICloudVen[] {
        const cloudVens = sites
            .filter(site => siteIds.includes(site.site_id))
            .map(site => site?.cloud_vens || [])
            .flat();

        return uniqBy(cloudVens, 'id');
    }

    public static getCustomerVenBySiteId(siteId: number, sites: ISite[]): ICustomerVen | null {
        return sites.find(site => site.site_id === siteId)?.customer_ven || null;
    }

    public static getEventsCountDataBySiteId(
        siteId: number,
        eventsCountBySites: IEventsCountBySiteAndYears
    ): IEventsCountBySiteAndYears[string] {
        return eventsCountBySites[siteId.toString()] || {};
    }
}

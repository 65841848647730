import { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../../domain/auth/useAuth';
import { ICompany } from '../../../domain/company/interface';
import { UserType } from '../../../domain/user/interface';
import { usePageLocation } from '../../../pages/usePageState';
import { useCompanyListQuery } from '../../../domain/company/queries';

export interface ICompanyValue {
    companyId: number | null;
    companies: ICompany[];
    isLoading?: boolean;
    setCompanyId: (companyId: number | null) => void;
    handleCompany: (company: number | null) => void;
}

const CompanyContext = createContext<ICompanyValue | null>(null);

export const CompanyProvider = ({ children }: { children: React.ReactNode }) => {
    const urlLocation = useLocation();
    const { setPageQuery, queryToState } = usePageLocation();
    const pageState: any = queryToState(urlLocation.search);

    const auth = useAuth()!;
    const isAllowedRoleType = [
        UserType.ADMIN,
        UserType.ACCOUNT_MANAGER,
        UserType.CONTROL_PROVIDER,
        UserType.UTILITY_CUSTOMER,
    ].includes(auth.user!.user_type);

    const [companyId, setCompanyId] = useState<ICompanyValue['companyId']>(pageState?.companyId || null);

    const setActiveCompany = () => {
        const activeCompany = pageState?.companyId || null;
        const currentActiveCompany = isAllowedRoleType ? activeCompany : auth.user?.company_id;
        setCompanyId(currentActiveCompany);
    };

    const { data: companies, isLoading } = useCompanyListQuery({});

    useEffect(() => {
        setActiveCompany();
    }, []);

    // to set active company when we logIn after expired session
    useEffect(() => {
        if (pageState?.companyId && !companyId) {
            setActiveCompany();
        }
    }, [pageState?.companyId]);

    const handleCompany = (selectedCompany: ICompanyValue['companyId']) => {
        setCompanyId(selectedCompany);
        const copyState = { ...pageState };

        const preparedParams = {
            ...copyState,
            companyId: selectedCompany,
        };
        setPageQuery(preparedParams);
    };

    return (
        <CompanyContext.Provider
            value={{
                companyId,
                companies: companies?.data || [],
                setCompanyId,
                handleCompany,
                isLoading,
            }}
        >
            {children}
        </CompanyContext.Provider>
    );
};

export const useCompanies = () => {
    return useContext(CompanyContext);
};

import React, { useMemo } from 'react';
import isEqual from 'lodash/isEqual';
import Alert from 'antd/lib/alert';
import Button from 'antd/lib/button';
import { useIdentifyMarketZoneQuery } from '../../../domain/market/queries';
import { MarketZoneSelector } from '../MarketZoneSelector/MarketZoneSelector';

interface ISmartMarketZoneSelectorProps {
    setSelectedValues: (values: string[] | undefined) => void;
    selectedValues: string[] | undefined;

    zipcode?: string;
    lat?: number | null;
    long?: number | null;
    county?: string | null;
}

export const SmartMarketZoneSelector: React.FC<ISmartMarketZoneSelectorProps> = ({
    selectedValues,
    setSelectedValues,
    zipcode,
    lat,
    long,
    county,
    ...props
}) => {
    const isEnabled = useMemo(() => !!(zipcode && lat && long), [zipcode, lat, long]);

    const { data: marketData, status } = useIdentifyMarketZoneQuery(
        {
            zipcode: zipcode!,
            lat: lat!,
            long: long!,
            county: county!,
        },
        {
            enabled: isEnabled,
        }
    );

    const market = useMemo(() => {
        if (!marketData) return null;

        return {
            ...marketData,
            market: marketData?.market,
        };
    }, [marketData]);

    const canSelectSuggestedValue = useMemo(
        () =>
            status === 'success' &&
            !!market?.market &&
            !!market?.loadZone &&
            !isEqual(selectedValues, [market?.market.toUpperCase(), market?.loadZone.toUpperCase()]),
        [market?.loadZone, market?.market, selectedValues, status]
    );

    const handleSetSuggestedValue = () => {
        // Allow to pre-select only valid markets with load zones
        if (!market?.market || !market?.loadZone) return;

        setSelectedValues([market.market.toUpperCase(), market.loadZone.toUpperCase()]);
    };

    return (
        <>
            <MarketZoneSelector value={selectedValues} onChange={setSelectedValues} {...props} />

            {canSelectSuggestedValue && (
                <Alert
                    type="info"
                    message={`For this address, we suggest using the following market and load zone: ${market?.market?.toUpperCase()} / ${market?.loadZone?.toUpperCase()}`}
                    style={{ marginTop: 12, flexDirection: 'column' }}
                    action={
                        <Button size="small" onClick={handleSetSuggestedValue} data-cy="select-suggested-value">
                            Use suggested
                        </Button>
                    }
                />
            )}
        </>
    );
};

import Table from 'antd/lib/table';
import { ColumnsType } from 'antd/lib/table/interface';

interface IRecord {
    field: string;
    required: string;
    description?: string;
}

export function EnergyUsageImportFormatDescription() {
    const records: IRecord[] = [
        {
            field: 'Enrollment ID*',
            required: 'Yes',
            description: 'Utility identifier (service point number, ESID etc.)',
        },
        {
            field: 'Interval Start (UTC)*',
            required: 'Yes',
            description: '2024-01-01T05:00:00.000Z',
        },
        {
            field: 'Interval End (UTC)*',
            required: 'Yes',
            description: '2024-01-01T05:15:00.000Z',
        },
        {
            field: 'Energy Demand (kW)*',
            required: 'Yes',
            description: '',
        },
        {
            field: 'Source',
            required: 'Optional',
            description: 'This field could have details about the source of this data',
        },
        {
            field: 'Customer Name',
            required: 'Optional',
            description: '',
        },
        {
            field: 'Customer Account',
            required: 'Optional',
            description: '',
        },
        {
            field: 'Customer Address',
            required: 'Optional',
            description: '',
        },
    ];

    const columns: ColumnsType<IRecord> = [
        {
            title: 'Field Name',
            dataIndex: 'field',
            width: 300,
            ellipsis: true,
        },
        {
            title: 'Required',
            dataIndex: 'required',
            width: 200,
            ellipsis: true,
        },
        {
            title: 'Description',
            dataIndex: 'description',
            ellipsis: true,
        },
    ];
    return (
        <Table
            rowKey="field"
            size="small"
            scroll={{ x: 'scroll' }}
            sticky
            title={() => 'CSV format description'}
            columns={columns}
            dataSource={records}
            pagination={false}
        ></Table>
    );
}

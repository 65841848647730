import Col from 'antd/es/grid/col';
import Row from 'antd/lib/row';
import { IIntervalDataReporting } from '../../../../domain/interval-data/interface';
import { ContentBlock } from '../../../../components/ContentBlock/ContentBlock';
import Card from 'antd/lib/card';
import Typography from 'antd/lib/typography';
import { LinkOutlined } from '@ant-design/icons';
import { numberFormatter } from '../../../../domain/common/numberFormatter';
import { SECONDARY_COLOR } from '../../../../theme';

const PARTNER: { [key: string]: string } = {
    greenbutton: 'Greenbutton',
    leap: 'Leap',
    smartMeterTx: 'Smart Meter TX',
    other: 'Other Sources',
};

export const PartnerSummary = ({ partner }: { partner: IIntervalDataReporting['partner'] }) => (
    <Col xs={24} md={12}>
        <Card
            title={
                <Typography.Title style={{ color: SECONDARY_COLOR }} level={4}>
                    <LinkOutlined /> Partner Connection Summary
                </Typography.Title>
            }
            headStyle={{ borderBottom: 'none' }}
        >
            <Row gutter={[0, 24]}>
                {Object.entries(partner).map(([partnerName, value]) => (
                    <Col xs={12} key={`${PARTNER[partnerName]}-${value}`}>
                        <ContentBlock
                            value={numberFormatter.format(value)}
                            description={PARTNER[partnerName]}
                            valueClass="content-value"
                        />
                    </Col>
                ))}
            </Row>
        </Card>
    </Col>
);

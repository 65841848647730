import CopyOutlined from '@ant-design/icons/lib/icons/CopyOutlined';
import UploadOutlined from '@ant-design/icons/UploadOutlined';
import App from 'antd/lib/app';
import Button from 'antd/lib/button';
import Col from 'antd/lib/col';
import Divider from 'antd/lib/divider';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import Modal from 'antd/lib/modal';
import Row from 'antd/lib/row';
import Select from 'antd/lib/select';
import Switch from 'antd/lib/switch';
import Tooltip from 'antd/lib/tooltip';
import Typography from 'antd/lib/typography';
import Upload from 'antd/lib/upload';
import uniq from 'lodash/uniq';
import { useEffect, useState } from 'react';
import { CompanySelector } from '../../../components/selectors/CompanySelector/CompanySelector';
import { filterCompaniesByType } from '../../../components/selectors/GlobalCompanySelector/companyHelpers';
import { SiteSelector } from '../../../components/selectors/SiteSelector/SiteSelector';
import { VenResourceSelector } from '../../../components/selectors/VenResourceSelector/VenResourceSelector';
import { useAuth } from '../../../domain/auth/useAuth';
import { COMPANY_TYPE, ICompany } from '../../../domain/company/interface';
import { ISite } from '../../../domain/site/interface';
import { useSiteQuery } from '../../../domain/site/queries';
import { copyVenToClipboard } from '../../../domain/ven/copyVenToClipboard';
import { ICustomerVen, profileOptions } from '../../../domain/ven/interface';
import { makeCustomerVenForSite } from '../../../domain/ven/makeCustomerVen';
import { parseResourceIdString } from '../../../domain/ven/parseResourceIdString';
import { useSaveCustomerVenMutation } from '../../../domain/ven/queries';

interface IVenFormProps {
    ven: ICustomerVen | Partial<ICustomerVen>;
    onClose: Function;
    isEditMode: boolean;
}

export function CustomerVenFormModal({ ven, onClose, isEditMode }: IVenFormProps) {
    const { notification } = App.useApp();
    const [form] = Form.useForm();
    const [cert, setCert] = useState<string | null | undefined>(ven.ven_cert);
    const [key, setKey] = useState<string | null | undefined>(ven.ven_key);
    const [venResourceIds, setVenResourceIds] = useState<string[]>(parseResourceIdString(ven.ven_resource_id));

    const auth = useAuth();
    const isControlProvider = auth?.user?.isControlProvider();

    const companyId = Form.useWatch('company_id', form);
    const selectedSiteId = Form.useWatch('site_id', form);

    const isSiteLoadEnabled = !!selectedSiteId && selectedSiteId !== -1; // all sites option
    const { data: loadedSite, isLoading: isSiteLoading } = useSiteQuery(selectedSiteId, {
        include: 'company',
        enabled: isSiteLoadEnabled,
    });
    const [selectedSite, setSelectedSite] = useState<ISite | null | undefined>(loadedSite);

    const { mutateAsync: saveCustomerVen, isLoading } = useSaveCustomerVenMutation();

    useEffect(() => {
        if (selectedSiteId === -1) {
            setSelectedSite(undefined);
            return;
        }

        if (loadedSite) {
            setSelectedSite(loadedSite);
        }
    }, [loadedSite, selectedSiteId]);

    async function onFormFinish(updatedVen: ICustomerVen) {
        try {
            // TODO: Refactor with VenFormData.toEntity
            const preparedVen = {
                ...updatedVen,
                ...(ven?.id && { id: ven.id }),
                site_id: !!updatedVen.site_id && updatedVen.site_id !== -1 ? updatedVen.site_id : null,
                ven_cert: cert,
                ven_key: key,
                mac_address: (updatedVen.mac_address || '').replaceAll(/[-:.]/gm, ''),
                ven_resource_id: uniq(
                    ((updatedVen.ven_resource_id as any) || []).map((resource: string) => resource.split('::')[0])
                ).join(','),
            } as ICustomerVen;

            await saveCustomerVen(preparedVen);

            notification.info({ key: 'ven-save-info', message: 'VEN saved' });
            onClose(ven);
        } catch (err: any) {
            notification.error({ key: 'ven-save-error', message: err.message || 'Cannot save VEN!' });
        }
    }

    function closeModal() {
        onClose();
    }

    function pemToVirtualFile(pem: string | undefined, filename: string) {
        if (pem) {
            return [{ uid: '1', name: filename }];
        }
        return [];
    }

    const sslCertProps = {
        multiple: false,
        accept: '.pem',
        maxCount: 1,
        onRemove: (file: any) => {
            console.info('sslCertProps onRemove', file);
            setCert('');
            return false;
        },
        beforeUpload: (file: any) => {
            console.info('sslCertProps beforeUpload', file);
            return false;
        },
        onChange: (info: any) => {
            console.log('sslCertProps onChange', info);
            if (info.file.status || !info.fileList.length) {
                return;
            }

            let reader = new FileReader();
            reader.readAsText(info.file, 'UTF-8');
            reader.onload = function () {
                console.info('reader.result', reader.result);
                setCert(reader.result as any);
            };
        },
        defaultFileList: pemToVirtualFile(ven.ven_cert, 'cert.pem'),
    };

    const sslKeyProps = {
        multiple: false,
        accept: '.pem',
        maxCount: 1,
        onRemove: (file: any) => {
            console.info('sslKeyProps onRemove', file);
            setKey('');
            return false;
        },
        beforeUpload: (file: any) => {
            console.info('sslKeyProps beforeUpload', file);
            return false;
        },
        onChange: (info: any) => {
            console.log('sslKeyProps onChange', info);
            if (info.file.status || !info.fileList.length) {
                return;
            }

            let reader = new FileReader();
            reader.readAsText(info.file, 'UTF-8');
            reader.onload = function () {
                console.info('reader.result', reader.result);
                setKey(reader.result as any);
            };
        },
        defaultFileList: pemToVirtualFile(ven.ven_key, 'key.pem'),
    };

    const validateVenResources = () => {
        return {
            validator(_: any, value: any) {
                if (!value.length) {
                    return Promise.resolve();
                }

                const isAllResourcesBelongCompany = value
                    .map((selectedResource: string) => selectedResource.split('::')[1])
                    .every((val: string, i: number, arr: string[]) => val === arr[0]);

                return isAllResourcesBelongCompany
                    ? Promise.resolve()
                    : Promise.reject(new Error('All selected resources should belong to the same company.'));
            },
        };
    };

    const onCopy = async () => {
        await copyVenToClipboard(form.getFieldsValue());
    };

    const onPrefillForm = () => {
        if (!selectedSite) return;

        const ven = makeCustomerVenForSite(selectedSite as ISite & { company: ICompany });

        form.setFieldsValue({
            ven_username: ven.ven_username,
            ven_id: ven.ven_id,
            ven_password: ven.ven_password,
            ven_uri: ven.ven_uri,
            ven_vtn_id: ven.ven_vtn_id,
            ven_market_context: ven.ven_market_context,
        });
    };

    const onResourceIdChange = (value: string[]) => {
        setVenResourceIds(value);
    };

    const companyListTransformer = (companies: ICompany[]) =>
        filterCompaniesByType(companies, COMPANY_TYPE.CUSTOMER_COMPANY);

    const validateCompanySite = () => {
        return {
            required: true,
            message: 'Please select site',
            validator(_: any, value: any) {
                return value && !selectedSite ? Promise.reject('Please select site!') : Promise.resolve();
            },
        };
    };

    const handleCompanyChange = (value: number | null) => {
        form.setFieldValue('company_id', value);

        if (companyId !== value) {
            form.setFieldValue('site_id', null);
            setSelectedSite(undefined);
        }
    };

    const handleSiteChange = (value: number | null) => {
        form.setFieldValue('site_id', value);
    };

    return (
        <Modal
            title={isEditMode ? 'Edit Customer VEN' : 'New Customer VEN'}
            open
            destroyOnClose
            onCancel={closeModal}
            footer={[
                <Button key="ven-modal-cancel" onClick={closeModal}>
                    Cancel
                </Button>,
                <Button key="ven-modal-submit" type="primary" loading={isLoading} onClick={form.submit}>
                    Save
                </Button>,
            ]}
            data-cy="customer-ven-modal"
        >
            <Form
                form={form}
                name="ven-form"
                preserve={false}
                layout="vertical"
                onFinish={onFormFinish}
                initialValues={{
                    ...ven,
                    ...(!isEditMode && { ven_enabled: true }),
                    ven_resource_id: venResourceIds,
                }}
            >
                <Row gutter={8}>
                    <Col span={12}>
                        <Form.Item
                            hasFeedback
                            name="company_id"
                            label={<Typography.Text strong>Company</Typography.Text>}
                            rules={[{ required: true, message: 'Please select company!' }]}
                        >
                            <CompanySelector
                                onChange={handleCompanyChange}
                                onlyTypes={[COMPANY_TYPE.CUSTOMER_COMPANY]}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            name="site_id"
                            tooltip={'If you do not select specific site, they all will be dispatched'}
                            label={<Typography.Text strong>Site</Typography.Text>}
                            rules={[...(isControlProvider ? [validateCompanySite] : [])]}
                        >
                            <SiteSelector allSitesOption companyId={companyId} onChange={handleSiteChange} />

                            {!companyId && (
                                <Typography.Text type="secondary">Select company first to see sites</Typography.Text>
                            )}
                        </Form.Item>
                    </Col>

                    {selectedSite && (
                        <Col span={12} style={{ marginBottom: 12, marginTop: -8 }}>
                            <Tooltip title="Fill the form with generated username, password and venId...">
                                <Button type="link" onClick={onPrefillForm} loading={isSiteLoading}>
                                    Prefill the form
                                </Button>
                            </Tooltip>
                        </Col>
                    )}
                </Row>

                <Row gutter={8}>
                    <Col span={12}>
                        <Form.Item
                            name="ven_username"
                            label={<Typography.Text strong>Username</Typography.Text>}
                            hasFeedback
                            rules={[
                                { required: true, message: 'Please enter username!' },
                                { max: 50, message: 'Number of characters should be less than 50' },
                            ]}
                        >
                            <Input placeholder="Username" size="large" autoComplete="customer-ven-username" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="ven_password"
                            label={<Typography.Text strong>Password</Typography.Text>}
                            hasFeedback
                            rules={[
                                { required: true, message: 'Please enter password!' },
                                { max: 50, message: 'Number of characters should be less than 50' },
                            ]}
                        >
                            <Input.Password placeholder="Password" size="large" autoComplete="new-password" />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={8}>
                    <Col span={12}>
                        <Form.Item
                            name="ven_id"
                            label={<Typography.Text strong>VEN ID</Typography.Text>}
                            hasFeedback
                            rules={[
                                { required: true, message: 'Please enter VEN ID!' },
                                { max: 50, message: 'Number of characters should be less than 50' },
                            ]}
                        >
                            <Input placeholder="VEN ID" size="large" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="ven_vtn_id"
                            label={<Typography.Text strong>VTN ID</Typography.Text>}
                            hasFeedback
                            rules={[
                                { required: true, message: 'Please enter VTN ID!' },
                                { max: 30, message: 'Number of characters should be less than 30' },
                            ]}
                        >
                            <Input placeholder="VTN ID" size="large" />
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item
                    name="ven_uri"
                    label={<Typography.Text strong>URI</Typography.Text>}
                    hasFeedback
                    rules={[
                        { required: true, message: 'Please enter VEN URI!' },
                        { max: 200, message: 'Number of characters should be less than 200' },
                    ]}
                >
                    <Input placeholder="URI" size="large" />
                </Form.Item>

                <Form.Item
                    name="mac_address"
                    tooltip="If it is an Enersponse hardware device, set its MAC address"
                    label={<Typography.Text strong>MAC Address</Typography.Text>}
                    hasFeedback
                    rules={[
                        {
                            pattern:
                                /^(?:[0-9A-Fa-f]{2}([:-]?)[0-9A-Fa-f]{2})(?:(?:\1|\.)(?:[0-9A-Fa-f]{2}([:-]?)[0-9A-Fa-f]{2})){2}$/gm,
                            message:
                                'MAC address should be represented in one of the formats: six pairs of the characters separated with a hyphen (-) or colon(:); or three groups of four hexadecimal digits separated by dots(.); or 12 hexadecimal digits.',
                        },
                    ]}
                >
                    <Input placeholder="FFFFFFFFFFFF" size="large" />
                </Form.Item>

                <Row justify="end">
                    <Button key="copy-ven-info" type="primary" onClick={onCopy} icon={<CopyOutlined />}>
                        Copy VEN Properties
                    </Button>
                </Row>

                <Divider plain>OpenADR Options</Divider>

                <Form.Item
                    name="ven_market_context"
                    rules={[{ max: 1000, message: 'Number of characters should be less than 1000' }]}
                    label={<Typography.Text strong>Market Context</Typography.Text>}
                >
                    <Input placeholder="Market Context" size="large" />
                </Form.Item>

                <Form.Item
                    tooltip="You can select resources related to ONLY one company."
                    label={<Typography.Text strong>Resource ID</Typography.Text>}
                >
                    <Form.Item rules={[validateVenResources]} name="ven_resource_id" noStyle>
                        <VenResourceSelector
                            ids={ven.ven_resource_id}
                            onChange={onResourceIdChange}
                            companyListTransformer={companyListTransformer}
                        />
                    </Form.Item>
                </Form.Item>

                <Row gutter={8}>
                    <Col span={12}>
                        <Form.Item
                            name="ven_group_id"
                            rules={[{ max: 100, message: 'Number of characters should be less than 100' }]}
                            label={<Typography.Text strong>Group ID</Typography.Text>}
                        >
                            <Input placeholder="Group ID" size="large" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="ven_party_id"
                            rules={[{ max: 100, message: 'Number of characters should be less than 100' }]}
                            label={<Typography.Text strong>Party ID</Typography.Text>}
                        >
                            <Input placeholder="Party ID" size="large" />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={8}>
                    <Col span={12}>
                        <Form.Item
                            labelCol={{ span: 24 }}
                            label={<Typography.Text strong>SSL Cert (PEM)</Typography.Text>}
                        >
                            <Upload {...sslCertProps}>
                                <Button icon={<UploadOutlined />} size="large">
                                    Upload
                                </Button>
                            </Upload>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            labelCol={{ span: 24 }}
                            label={<Typography.Text strong>SSL Key (PEM)</Typography.Text>}
                        >
                            <Upload {...sslKeyProps}>
                                <Button icon={<UploadOutlined />} size="large">
                                    Upload
                                </Button>
                            </Upload>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={8}>
                    <Col span={12}>
                        <Form.Item name="ven_profile" label={<Typography.Text strong>OpenADR Profile</Typography.Text>}>
                            <Select size="large" placeholder="Please select profile" options={profileOptions}></Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="ven_registration_id"
                            label={<Typography.Text strong>Registration ID</Typography.Text>}
                        >
                            <Input size="large" />
                        </Form.Item>
                    </Col>
                </Row>

                {auth?.user?.isAdmin() && (
                    <>
                        <Form.Item
                            name="ven_detailed_logging"
                            tooltip="If enabled, more details about communication will be logged"
                            label={<Typography.Text strong>Detailed Logging</Typography.Text>}
                            valuePropName="checked"
                        >
                            <Switch />
                        </Form.Item>
                    </>
                )}

                <Form.Item
                    name="ven_enabled"
                    tooltip="You can temporary disable VEN, instead of deleting it"
                    label={<Typography.Text strong>Enable VEN</Typography.Text>}
                    valuePropName="checked"
                >
                    <Switch />
                </Form.Item>
            </Form>
        </Modal>
    );
}

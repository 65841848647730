export const LOCAL_STORAGE_PREFIX = 'drms';
export const DEFAULT_PAGINATION = {
    current: 1,
    pageSize: 10,
    total: 0,
};
export const DAY_MS = 1000 * 60 * 60 * 24;
export const HOUR_MS = 60 * 60 * 1000;
export const MINUTE_MS = 60 * 1000;
export const HOUR_MINUTES = 60;
export const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
export const HOURS = [
    '12-AM',
    '1-AM',
    '2-AM',
    '3-AM',
    '4-AM',
    '5-AM',
    '6-AM',
    '7-AM',
    '8-AM',
    '9-AM',
    '10-AM',
    '11-AM',
    '12-PM',
    '1-PM',
    '2-PM',
    '3-PM',
    '4-PM',
    '5-PM',
    '6-PM',
    '7-PM',
    '8-PM',
    '9-PM',
    '10-PM',
    '11-PM',
];

export enum PAGE_ACTION {
    ENROLL_PROGRAM = 'enroll_program',
    NEW_EDIT = 'new_edit',
    UPLOAD_FILE = 'upload_file',
    AUDIT_TRAIL = 'audit_trail',
    SHOW_LOGS = 'show_logs',
    DELETE = 'delete',
}

export enum SORT_ORDER {
    DESCEND = 'descend',
    ASCEND = 'ascend',
}

export const DATE_FORMAT_CSV = 'MM-dd-yyyy-HH-mm';

export const SEPARATOR = ':::';

export const ALPHANUMERIC_REGEX = new RegExp(/^[a-zA-Z0-9-]+$/); // Allows alphanumeric and dash

export const EVENT_COLORS = {
    DEFAULT: '#2fadab',
    PRE_EVENT: '#fac42a',
    POST_EVENT: '#fa675c',
    OPT_IN: '#2fadab',
    OPT_OUT: '#888787',
    CANCELLED: '#444444',
    VOLUNTARY: '#2fadab',
    EMERGENCY: '#2fadab',
    TEST: '#3FEBE8',
    PRICE_RESPONSE: '#2771ad',
    CLEAN_RESPONSE: '#26ad5c',
    COINCIDENT_PEAK: '#ad4093',
};

export const DEFAULT_SIDEBAR_WIDTH = 250;
export const DEFAULT_SIDEBAR_MIN_WIDTH = 250;
export const DEFAULT_SIDEBAR_MAX_WIDTH = 400;
export function sortingComparator(a?: string | number, b?: string | number) {
    if (typeof a === 'string' && typeof b === 'string') {
        return a?.localeCompare(b ?? '');
    }

    if (a === b) {
        return 0;
    }

    if (a === undefined) {
        return 1;
    }

    if (b === undefined) {
        return -1;
    }

    return a < b ? -1 : 1;
}

import FieldNumberOutlined from '@ant-design/icons/lib/icons/FieldNumberOutlined';
import QuestionCircleOutlined from '@ant-design/icons/QuestionCircleOutlined';
import App from 'antd/lib/app';
import Empty from 'antd/lib/empty';
import Row from 'antd/lib/row';
import Skeleton from 'antd/lib/skeleton';
import Tooltip from 'antd/lib/tooltip';
import Typography from 'antd/lib/typography';
import { format } from 'date-fns';
import React from 'react';
import stopWatch from '../../../../components/icons/stop-watch.svg';
import { InformationCard } from '../../../../components/informationCard/informationCard';
import { InformationCardTitle } from '../../../../components/informationCard/informationCardTitle';
import { MetricData } from '../../../../components/metricData/metricData';
import { useCompanies } from '../../../../components/selectors/GlobalCompanySelector/CompanyContext';
import { numberFormatter } from '../../../../domain/common/numberFormatter';
import { prepareEventReqDateParams } from '../../../../domain/event/prepareEventReqDateParams';
import { useEventMetricsQuery } from '../../../../domain/event/queries';
import { formatWattHour } from '../../../impact/impact';
import './EventsMetrics.css';

interface IEventMetricsProps {
    date: {
        start: Date;
        end: Date;
    };
}

export const EventsMetrics: React.FC<IEventMetricsProps> = ({ date }) => {
    const { notification } = App.useApp();
    const { companyId } = useCompanies()!;

    const {
        data: metricsData,
        isLoading,
        isError,
        error,
    } = useEventMetricsQuery({
        companyId: companyId,
        ...prepareEventReqDateParams(date),
    });

    if (isError) {
        notification.error({
            key: 'fetch-events-metrics-error',
            message: error.message || 'Cannot fetch event metrics data!',
        });
    }

    if (isLoading) {
        return <Skeleton loading={isLoading} paragraph active />;
    }

    if (!metricsData) {
        return (
            <Row justify="center">
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </Row>
        );
    }

    return (
        <div>
            <Row gutter={[16, 16]} align="middle" justify="center">
                <InformationCard
                    classNames={['metric-information-card']}
                    title={
                        <InformationCardTitle
                            title="of Event Days"
                            icon={<FieldNumberOutlined style={{ fontSize: '16px' }} />}
                        />
                    }
                >
                    <MetricData value={metricsData.days} />
                </InformationCard>
                <InformationCard
                    classNames={['metric-information-card']}
                    title={
                        <InformationCardTitle
                            title="States with Events"
                            icon={<FieldNumberOutlined style={{ fontSize: '16px' }} />}
                        />
                    }
                >
                    <MetricData value={numberFormatter.format(metricsData.locations)} />
                </InformationCard>
                <InformationCard
                    classNames={['metric-information-card']}
                    title={
                        <InformationCardTitle
                            title="Total Site Events"
                            icon={<FieldNumberOutlined style={{ fontSize: '16px' }} />}
                        />
                    }
                >
                    <MetricData value={numberFormatter.format(metricsData.eventsCount)} />
                </InformationCard>
                <InformationCard
                    classNames={['metric-information-card']}
                    title={
                        <InformationCardTitle
                            title="Estimated kWH"
                            icon={<img src={stopWatch} alt="events Hours img" />}
                        />
                    }
                >
                    <MetricData
                        uom={formatWattHour(metricsData.estimatedKWH * 1000).uom}
                        value={numberFormatter.format(formatWattHour(metricsData.estimatedKWH * 1000).value)}
                    />
                </InformationCard>
            </Row>
            <Row justify="center" style={{ paddingTop: '16px', paddingBottom: '16px' }}>
                <Typography.Text>
                    This Summary was last updated:&nbsp;
                    <>{format(new Date(metricsData.date_modified), 'dd/LLL/yyyy hh:mm a')}</>
                </Typography.Text>
                &nbsp;
                <Tooltip title="Calculated for each company by month">
                    <QuestionCircleOutlined />
                </Tooltip>
            </Row>
        </div>
    );
};

import React from 'react';
import Select, { SelectProps } from 'antd/lib/select';

interface ICarbonIntensityPresetSelectorProps extends SelectProps {}

const carbonIntensitySelectorOptions = [
    { label: 'Save a little (800)', value: 800 },
    { label: 'Save some more (600)', value: 600 },
    { label: 'Save a lot (300)', value: 300 },
];

export const CarbonIntensityPresetSelector: React.FC<ICarbonIntensityPresetSelectorProps> = props => {
    return <Select size="large" placeholder="Select preset" options={carbonIntensitySelectorOptions} {...props} />;
};

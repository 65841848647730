import { useEffect, useState } from 'react';
import Alert from 'antd/lib/alert';
import Button from 'antd/lib/button';
import Divider from 'antd/lib/divider';
import Form from 'antd/lib/form';
import Modal from 'antd/lib/modal';
import Typography from 'antd/lib/typography';
import config from '../../config';
import { IMfaSetting, IUser, MfaRequiredError } from '../../domain/user/interface';
import { useAuth } from '../../domain/auth/useAuth';
import { enableMfa } from '../../domain/user/mfa';
import { RequiredMFA } from '../modals/requiredConfirmationModal/requiredMFA';
import App from 'antd/lib/app';

interface IMfaSetupModalProps {
    user: IUser;
    onClose: () => void;
}

export function MfaSetupModal({ onClose, user }: IMfaSetupModalProps) {
    const { notification } = App.useApp();
    const [formCode] = Form.useForm();
    const auth = useAuth();

    const [loading, setLoading] = useState<boolean>(false);
    const [step, setStep] = useState<'phone' | 'code'>('phone');
    const [mfaSetting, setMfaSetting] = useState<IMfaSetting | Partial<IMfaSetting>>();

    const preparePhoneNumber = (phone: string) => {
        const endDigits = phone.slice(-4);
        return endDigits.padStart(phone.length, '*');
    };

    useEffect(() => {
        if (step !== 'code') return;

        (async () => {
            try {
                await enableMfa(user.user_id, undefined, user.tokens?.accessToken!);
            } catch (error: any) {
                if (error instanceof MfaRequiredError) {
                    setMfaSetting(error);
                } else {
                    notification.error({
                        key: 'enable-mfa-error',
                        message: error?.message || 'Cannot enable two factor authentication!',
                    });
                }
            }
        })();
    }, [step]);

    async function submit() {
        const code = formCode.getFieldValue('code');
        if (!code) return;

        setLoading(true);
        try {
            await enableMfa(user.user_id, { code }, user.tokens?.accessToken!);
            await auth?.refetch();
            onClose();
        } catch (error: any) {
            if (error instanceof MfaRequiredError) {
                setMfaSetting(error);
            } else {
                notification.error({
                    key: 'enable-mfa-error',
                    message: error?.message || 'Cannot enable two factor authentication!',
                });
            }
        }
        setLoading(false);
    }

    function makeFooterActions() {
        return user.user_phone ? (
            <Button
                data-cy="mfa-setup-modal-submit"
                key="submit"
                type="primary"
                onClick={step === 'phone' ? () => setStep('code') : submit}
                loading={loading}
            >
                Submit
            </Button>
        ) : (
            <Button onClick={onClose}>Close</Button>
        );
    }

    const makeConfirmationCodeMessage = (user: IUser) => {
        if (config.APP_STAGE === 'production') {
            return (
                <>
                    {' '}
                    Confirmation code will be send on your phone number{' '}
                    <strong>{preparePhoneNumber(user.user_phone)}</strong>.
                </>
            );
        }
        return (
            <>
                {' '}
                Confirmation code will be send on email address <strong>{user.user_email}</strong>.
            </>
        );
    };

    return (
        <Modal
            open
            closable={false}
            maskClosable={false}
            title="Two-factor authentication setup"
            data-cy="mfa-setup-modal"
            footer={makeFooterActions()}
        >
            {user.user_phone ? (
                <>
                    <Alert
                        message="To improve your account security please setup two factor authentication."
                        type="info"
                        showIcon
                    />
                    <p></p>
                    <Typography.Paragraph>
                        A second step after entering your password verifies it's you signing in. With 2-Step
                        Verification (also known as two-factor authentication), you add an extra layer of security to
                        your account in case your password is stolen.
                    </Typography.Paragraph>
                    <p></p>
                    <Alert
                        message="After successful verification you must repeat the Authentication process to have an access to the system."
                        type="warning"
                        showIcon
                    />
                    <p></p>
                    <Divider />
                    <p></p>

                    {step === 'phone' && (
                        <>
                            <Typography.Paragraph>{makeConfirmationCodeMessage(user)}</Typography.Paragraph>
                            <Typography>
                                Need help? Email{' '}
                                <Typography.Link target={'_blank'} href={`mailto:${config.SUPPORT_EMAIL}`}>
                                    {config.SUPPORT_EMAIL}
                                </Typography.Link>{' '}
                                or call {config.SUPPORT_PHONE}
                            </Typography>
                        </>
                    )}
                    {step === 'code' && (
                        <Form form={formCode} onFinish={submit} layout="vertical" initialValues={user}>
                            <RequiredMFA
                                resendSettings={{
                                    email: user.user_email,
                                    uuid: mfaSetting?.uuid || '',
                                    infoMessage: mfaSetting?.infoMessage || '',
                                    updateUuid: () => {},
                                }}
                                error={mfaSetting?.message}
                            />
                        </Form>
                    )}
                </>
            ) : (
                <>
                    <Alert
                        message="Your phone number isn`t configured."
                        type="warning"
                        style={{ marginBottom: '8px' }}
                        showIcon
                    />
                    <Typography>
                        Please contact us via&nbsp;
                        <Typography.Link target={'_blank'} href={`mailto:${config.SUPPORT_EMAIL}`}>
                            {config.SUPPORT_EMAIL}
                        </Typography.Link>
                        &nbsp;or call {config.SUPPORT_PHONE}.
                    </Typography>
                </>
            )}
        </Modal>
    );
}

import Tag from 'antd/lib/tag';
import React from 'react';
import { PRIMARY_COLOR } from '../../theme';

export const ActiveSanTag = ({ isActive }: { isActive: boolean }) => {
    return isActive ? (
        <Tag style={{ margin: 0 }} color={PRIMARY_COLOR}>
            Active
        </Tag>
    ) : (
        <Tag style={{ margin: 0 }} color="grey">
            Inactive
        </Tag>
    );
};

import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '../../query-client';
import { API } from '../api';
import { IFetchPageQuery } from '../IFetchQueryOptions';
import { IPagination } from '../IPagination';
import { IAccount } from './interface';

const fetchAccounts = async (
    { pagination, sorter, search, include }: IFetchPageQuery,
    signal?: AbortSignal | null
): Promise<IPagination<IAccount>> => {
    const limit = pagination?.pageSize || 1000;
    const current = pagination?.current || 1;
    const offset = (current - 1) * limit;

    if (sorter && (!sorter.field || !sorter.order)) {
        sorter = {};
    }

    const path = `/salesforce/accounts?${new URLSearchParams({
        ...(sorter?.field && { sortField: sorter.field, sortOrder: sorter.order }),
        ...(search && { search }),
        offset: offset.toString(),
        limit: limit.toString(),
        ...(include && { include }),
    })}`;

    const response = await API.fetch(path, {
        ...(signal && { signal }),
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const respBody: any = await response.json();

    if (response.ok) {
        return respBody;
    }

    throw new Error(response.statusText);
};

export const useAccountListQuery = (query: IFetchPageQuery, options = {}) => {
    return useQuery<IPagination<IAccount>, Error>(
        [QueryKey.ACCOUNTS, query],
        ({ signal }) => fetchAccounts(query, signal),
        {
            ...options,
            keepPreviousData: true,
        }
    );
};

import { useState } from 'react';
import App from 'antd/lib/app';
import InboxOutlined from '@ant-design/icons/InboxOutlined';
import Dragger from 'antd/lib/upload/Dragger';
import Button from 'antd/lib/button';
import Input from 'antd/lib/input';
import Modal from 'antd/lib/modal';
import Form from 'antd/lib/form';
import { uploadEnergyFile } from '../../../../domain/energy';
import Upload from 'antd/lib/upload';

const formItemLayout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 12 },
};

interface IUploadEnergyDataArchiveModalProps {
    onClose: () => void;
}

const ALLOWED_FILE_TYPES = ['.zip'];

export const UploadEnergyDataArchiveModal = ({ onClose }: IUploadEnergyDataArchiveModalProps) => {
    const { notification } = App.useApp();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);
    const [file, setFile] = useState<File | null>(null);

    async function onFormFinish() {
        if (!file) {
            console.warn(`File is absent!`, file);
            return;
        }

        try {
            setLoading(true);

            await uploadEnergyFile(file, 'sharepoint-archive');

            setLoading(false);
            notification.info({
                key: 'energy-file-upload-info',
                message: 'Energy file uploaded',
            });
            onClose();
        } catch (err: any) {
            setLoading(false);
            notification.error({
                key: 'energy-file-upload-error',
                message: err?.message || 'Energy file upload error!',
            });
        }
    }

    const uploadProps = {
        accept: ALLOWED_FILE_TYPES.join(','),
        multiple: false,
        maxCount: 1,
        onRemove: (file: any) => {
            setFile(null);
        },
        beforeUpload: (file: File) => {
            const ALLOWED_FILE_SIZE_MB = 50;
            const fileSizeInMB = file.size! / 1024 / 1024;
            if (fileSizeInMB > ALLOWED_FILE_SIZE_MB) {
                notification.warning({
                    key: 'upload-file-size-notification',
                    message: `Please select file up to ${ALLOWED_FILE_SIZE_MB}MB.`,
                });
                return Upload.LIST_IGNORE;
            }

            if (!ALLOWED_FILE_TYPES.some(type => file.name.endsWith(type))) {
                notification.warning({
                    key: 'upload-file-type-notification',
                    message: `Please select "${ALLOWED_FILE_TYPES.join('" or "')}" file.`,
                });
                return Upload.LIST_IGNORE;
            }

            return false;
        },
        onChange: (info: any) => {
            if (info.file.status) {
                return;
            }

            setFile(info.file);
        },
    };

    return (
        <Modal
            title="Upload SCE SharePoint ZIP"
            width="50%"
            open
            destroyOnClose
            onCancel={onClose}
            footer={[
                <Button key="upload-energy-data-archive-modal-cancel" onClick={onClose}>
                    Cancel
                </Button>,
                <Button
                    key="upload-energy-data-archive-modal-submit"
                    type="primary"
                    disabled={!file}
                    loading={loading}
                    onClick={form.submit}
                >
                    Upload
                </Button>,
            ]}
        >
            <Form
                form={form}
                name="upload-energy-data-archive-form"
                preserve={false}
                {...formItemLayout}
                onFinish={onFormFinish}
            >
                <Form.Item name="upload-energy-data-archive-file-id" hidden>
                    <Input />
                </Form.Item>
                <Dragger {...uploadProps}>
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Click or drag a SCE SharePoint ZIP file to this area to upload</p>
                    <p className="ant-upload-hint">
                        The size limit is 50MB. We will upload the file <strong>without validation</strong>, because
                        actual parsing of data happen later in a separate process.
                    </p>
                    <p className="ant-upload-hint"></p>
                </Dragger>
            </Form>
        </Modal>
    );
};

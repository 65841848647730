import App from 'antd/lib/app';
import { IFetchPageQuery } from '../IFetchQueryOptions';
import { useProgramListQuery } from './queries';

export const usePrograms = (query?: IFetchPageQuery) => {
    const { notification } = App.useApp();
    const { data, isLoading, isError, error } = useProgramListQuery(query || {});

    const programs = data?.data || [];

    if (isError) {
        notification.error({ key: 'fetch-programs-error', message: error.message || 'Cannot fetch programs!' });
    }

    return {
        programs,
        isLoading,
    };
};

import { API } from '../api';
import { IFetchPageQuery } from '../IFetchQueryOptions';
import { IPagination } from '../IPagination';
import { IProduct } from './interface';

export const fetchProducts = async (
    { sorter, pagination, search, include, filter }: IFetchPageQuery,
    signal?: AbortSignal | null
): Promise<IPagination<IProduct>> => {
    const limit = pagination?.pageSize || 10000;
    const current = pagination?.current || 1;
    const offset = (current - 1) * limit;
    if (!sorter?.field || !sorter?.order) {
        sorter = {};
    }

    const path = `/salesforce/products?${new URLSearchParams({
        ...(sorter?.field && { sortField: sorter.field, sortOrder: sorter.order }),
        ...filter,
        ...(search && { search }),
        ...(include && { include }),
        offset: offset.toString(),
        limit: limit.toString(),
    })}`;

    const response = await API.fetch(path, {
        ...(signal && { signal }),
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const respBody: any = await response.json();

    if (response.ok) {
        return respBody;
    }

    throw new Error(respBody?.error?.message || `Cannot load list of products!`);
};

import Typography from 'antd/lib/typography';
import React, { useContext, useMemo } from 'react';
import Button from 'antd/lib/button';
import Modal from 'antd/lib/modal';
import Spin from 'antd/lib/spin';
import { ISite } from '../../../domain/site/interface';
import { IMergeSitesContext, MergeSitesContext, MergeSitesContextProvider, MergeSitesStep } from './MergeSitesContext';
import { MergeSitesResultPreview } from './MergeSitesResultPreview';
import { useGetMergeSitesData } from './useGetMergedSitesData';
import { MergeSitesTable } from './MergeSitesTable';

interface IMergeSitesModalProps {
    selectedSites: ISite[];
    onClose: (refetch?: boolean) => void;
}

export const MergeSitesModal = ({ selectedSites = [], onClose }: IMergeSitesModalProps) => {
    const siteIds = useMemo(() => selectedSites.map(site => site.site_id), [selectedSites]);
    const { isLoading, sites, eventsCountBySites } = useGetMergeSitesData(siteIds);
    const siteCompanyName = useMemo(() => sites[0]?.company?.company_name || '', [sites]);

    return (
        <MergeSitesContextProvider eventsCountBySites={eventsCountBySites} sites={sites} onClose={onClose}>
            <Modal
                open
                keyboard={false} // Disable closing modal with ESC key
                onCancel={() => onClose()}
                style={{ minWidth: '1300px' }}
                styles={{ body: { paddingTop: 12 } }}
                footer={<MergeSitesModalFooter />}
                title={`Merge ${selectedSites.length} sites under "${siteCompanyName}" company`}
            >
                <Spin tip="Loading..." spinning={isLoading}>
                    <MergeSitesModalContent />
                </Spin>
            </Modal>
        </MergeSitesContextProvider>
    );
};

interface IMergeSitesModalContentProps {}

export const MergeSitesModalContent: React.FC<IMergeSitesModalContentProps> = () => {
    const { step } = useContext<IMergeSitesContext>(MergeSitesContext);

    return step === MergeSitesStep.MERGE_SITES ? (
        <>
            <Typography.Text style={{ display: 'block', paddingBottom: 12 }}>
                Double-click the Site Name to set as primary
            </Typography.Text>
            <MergeSitesTable key="merge-sites-table" />
        </>
    ) : (
        <MergeSitesResultPreview />
    );
};

interface IMergeSitesModalFooterProps {}

export const MergeSitesModalFooter: React.FC<IMergeSitesModalFooterProps> = () => {
    const { step, mergeSitesMutation, handleBack, handleNext } = useContext<IMergeSitesContext>(MergeSitesContext);

    return (
        <>
            <Button key="back" onClick={handleBack} disabled={mergeSitesMutation.isLoading}>
                {step === MergeSitesStep.MERGE_SITES ? 'Cancel' : 'Back'}
            </Button>
            <Button key="submit" type="primary" onClick={handleNext} loading={mergeSitesMutation.isLoading}>
                {step === MergeSitesStep.MERGE_SITES ? 'Next' : 'Merge'}
            </Button>
        </>
    );
};

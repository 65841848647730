import { IPagination } from '../IPagination';
import { API } from '../api';
import { ICreateProgramType, IFetchProgramTypesQuery, IProgramType } from './interface';

export const fetchProgramTypes = async (
    { pagination, sorter, search }: IFetchProgramTypesQuery,
    signal?: AbortSignal
): Promise<IPagination<IProgramType>> => {
    const limit = pagination?.pageSize || 1000;
    const current = pagination?.current || 1;
    const offset = (current - 1) * limit;

    if (sorter && (!sorter.field || !sorter.order)) {
        sorter = {};
    }

    const path = `/program-types?${new URLSearchParams({
        ...(sorter?.field && { sortField: sorter.field, sortOrder: sorter.order }),
        ...(search && { search }),
        offset: offset.toString(),
        limit: limit.toString(),
    })}`;

    const response = await API.fetch(path, {
        signal,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (response.ok) {
        return await response.json();
    }

    const failedRes = await response.json();

    throw new Error(failedRes?.error.message || `Cannot load list of types!`);
};

export const createProgramType = async (programType: ICreateProgramType): Promise<IProgramType> => {
    const response = await API.fetch(`/program-types`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(programType),
    });

    if (response.ok) {
        return await response.json();
    }

    const failedRes = await response.json();

    throw new Error(failedRes?.error?.message || `Cannot create type!`);
};

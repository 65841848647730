import React, { createContext, PropsWithChildren, useMemo, useState } from 'react';
import ExclamationCircleOutlined from '@ant-design/icons/ExclamationCircleOutlined';
import App from 'antd/lib/app';
import { COMPANY_TYPE } from '../../../domain/company/interface';
import { IEventsCountBySiteAndYears } from '../../../domain/event/interface';
import { usePrograms } from '../../../domain/program/usePrograms';
import { ISite } from '../../../domain/site/interface';
import { useMergeSitesMutation } from '../../../domain/site/queries';
import { useCompanies } from '../../selectors/GlobalCompanySelector/CompanyContext';
import { filterCompaniesByType } from '../../selectors/GlobalCompanySelector/companyHelpers';
import { IResultsColumnData } from './interface';
import { MergeSitesMap } from './MergeSitesMap';
import { MergeSitesTableUtils } from './MergeSitesTableUtils';

export enum MergeSitesStep {
    MERGE_SITES = 1,
    PREVIEW_RESULT = 2,
}

export interface IMergeSitesContext {
    step: MergeSitesStep;
    setStep: (step: MergeSitesStep) => void;
    resultsColumnData: IResultsColumnData;
    setResultsColumnData: (resultsColumnData: IResultsColumnData) => void;
    sites: ISite[];
    eventsCountBySites: IEventsCountBySiteAndYears;
    mergeSitesMutation: ReturnType<typeof useMergeSitesMutation>;

    handleNext: () => void;
    handleBack: () => void;
}

export const MergeSitesContext = createContext<IMergeSitesContext>({} as IMergeSitesContext);

interface IMergeSitesContextProviderProps extends PropsWithChildren {
    sites: ISite[];
    eventsCountBySites: IEventsCountBySiteAndYears;
    onClose: (refetch?: boolean) => void;
}

export const MergeSitesContextProvider: React.FC<IMergeSitesContextProviderProps> = ({
    children,
    sites,
    eventsCountBySites,
    onClose,
}) => {
    const { notification, modal } = App.useApp();
    const { companies } = useCompanies()!;
    const controlProviderCompanies = useMemo(
        () => filterCompaniesByType(companies, COMPANY_TYPE.CONTROL_PROVIDER),
        [companies]
    );
    const utilityCustomerCompanies = useMemo(
        () => filterCompaniesByType(companies, COMPANY_TYPE.UTILITY_CUSTOMER),
        [companies]
    );
    const { programs } = usePrograms();

    const [resultsColumnData, setResultsColumnData] = useState<IResultsColumnData>({});
    const [step, setStep] = useState<MergeSitesStep>(MergeSitesStep.MERGE_SITES);
    const mergeSitesMutation = useMergeSitesMutation();

    const handleNext = () => {
        if (step === MergeSitesStep.MERGE_SITES) {
            setStep(MergeSitesStep.PREVIEW_RESULT);
            return;
        }

        if (step === MergeSitesStep.PREVIEW_RESULT) {
            modal.confirm({
                icon: <ExclamationCircleOutlined />,
                content: `Are you sure you want to merge these sites? This action cannot be undone.`,
                onOk: () => {
                    handleMerge();
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        }
    };

    const handleMerge = () => {
        const mergingSite = MergeSitesTableUtils.mergeResultsColumnValues(resultsColumnData);
        const reqDto = MergeSitesMap.toReqDto(
            mergingSite,
            sites,
            programs,
            controlProviderCompanies,
            utilityCustomerCompanies
        );

        mergeSitesMutation.mutate(reqDto, {
            onSuccess: () => {
                onClose(true);
                notification.success({ key: 'sites-merge-success', message: 'Sites merged successfully!' });
            },
        });
    };

    const handleBack = () => {
        if (step === MergeSitesStep.MERGE_SITES) {
            onClose();
            return;
        }

        setStep(MergeSitesStep.MERGE_SITES);
    };

    return (
        <MergeSitesContext.Provider
            value={{
                step,
                resultsColumnData,
                sites,
                eventsCountBySites,
                mergeSitesMutation,

                setStep,
                setResultsColumnData,
                handleNext,
                handleBack,
            }}
        >
            {children}
        </MergeSitesContext.Provider>
    );
};

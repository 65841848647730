import { useQuery } from '@tanstack/react-query';
import { QueryKey } from 'src/query-client';
import { IFetchPageQuery } from '../IFetchQueryOptions';
import { fetchCaisoResourceBids } from '.';

export const useScResourceBidsQuery = (query: IFetchPageQuery, _options = {}) => {
    return useQuery({
        queryKey: [QueryKey.SC_RESOURCE_BIDS],
        queryFn: () => fetchCaisoResourceBids(query),
    });
};

import {
    DATA_AVAILABILITY_PERCENTAGES_MAP,
    TKeyofDataAvailabilityPercentagesMap,
} from './buildMonthlyReadingsAvailabilityFilter';
import { IGroupedReadingsAvailabilityStat } from './interface';

export function filterReadingsAvailabilityStatsByCompany(
    data: IGroupedReadingsAvailabilityStat[],
    companyId: number | number[]
): IGroupedReadingsAvailabilityStat[] {
    return data.filter(record => {
        if (Array.isArray(companyId)) {
            return companyId.includes(record.companyId);
        }

        return record.companyId === companyId;
    });
}

export function filterReadingsAvailabilityStatsByEnrollmentId(
    data: IGroupedReadingsAvailabilityStat[],
    enrollmentId: string | number | (string | number)[]
): IGroupedReadingsAvailabilityStat[] {
    return data.filter(record => {
        if (Array.isArray(enrollmentId)) {
            return enrollmentId.map(eId => eId.toString()).includes(record.enrollmentId!);
        }

        return enrollmentId.toString() === record.enrollmentId;
    });
}

export function filterReadingsAvailabilityStatsByMonthPercentRange(
    data: IGroupedReadingsAvailabilityStat[],
    month: string,
    range: TKeyofDataAvailabilityPercentagesMap | TKeyofDataAvailabilityPercentagesMap[]
): IGroupedReadingsAvailabilityStat[] {
    if (Array.isArray(range)) {
        const ranges = (range as TKeyofDataAvailabilityPercentagesMap[]).map(
            key => DATA_AVAILABILITY_PERCENTAGES_MAP[key]
        );
        return data.filter(record => {
            return ranges.some(({ start, end }) => record.stats[month] >= start && record.stats[month] <= end);
        });
    }

    const { start, end } = DATA_AVAILABILITY_PERCENTAGES_MAP[range.toString() as TKeyofDataAvailabilityPercentagesMap];
    return data.filter(record => {
        return record.stats[month] >= start && record.stats[month] <= end;
    });
}

import { useMutation, useQuery } from '@tanstack/react-query';
import { QueryKey } from '../../query-client';
import { IFetchPageQuery } from '../IFetchQueryOptions';
import { IPagination } from '../IPagination';
import { ISettlementFile } from './interface';
import { createSettlement, deleteSettlement, fetchSettlements } from './index';

export const useSettlementsListQuery = (query: IFetchPageQuery, options = {}) => {
    return useQuery<IPagination<ISettlementFile>, Error>({
        queryKey: [QueryKey.SETTLEMENTS, query],
        queryFn: () => fetchSettlements(query),
        keepPreviousData: true,
        ...options,
    });
};

export const useCreateSettlementMutation = () => {
    return useMutation<void, Error, ISettlementFile>({
        mutationFn: settlement => createSettlement(settlement),
    });
};

export const useDeleteSettlementMutation = () => {
    return useMutation<void, Error, ISettlementFile>({
        mutationFn: (settlement: ISettlementFile) => deleteSettlement(settlement),
    });
};

import { ReactElement } from 'react';

import { CAISO_SIBR_VIEW } from 'src/pages/interface';
import { useCaisoSibrSwitcher } from 'src/components/caisoSibrSwitcher/useCaisoSibrSwitcher';
import { SibrConfigurationView, SibrOverviewView } from './view';

export function SibrView() {
    const { view, handleView } = useCaisoSibrSwitcher(CAISO_SIBR_VIEW.OVERVIEW)!;

    const viewComponent: { [key: string]: ReactElement } = {
        [CAISO_SIBR_VIEW.OVERVIEW]: <SibrOverviewView view={view} handleView={handleView} />,
        [CAISO_SIBR_VIEW.CONFIGURATION]: <SibrConfigurationView view={view} handleView={handleView} />,
    };

    return <>{viewComponent[view]}</>;
}

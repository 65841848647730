import { SyntheticEvent, useMemo } from 'react';
import Select, { SelectProps } from 'antd/lib/select';
import Space from 'antd/lib/space';
import Empty from 'antd/lib/empty';
import Spin from 'antd/lib/spin';
import Typography from 'antd/lib/typography';
import { useSiteInfiniteListQuery } from '../../../domain/site/queries';
import { ISite } from '../../../domain/site/interface';

interface ISiteSelectorProps extends SelectProps<number> {
    companyId?: number;
    allSitesOption?: boolean;

    [key: string]: any;
}

interface IOption {
    value: number;
    label: string;
    key: number;
    site?: ISite;
}

const filterSelectorOption = (inputValue: string, option: IOption | undefined) => {
    const label = option?.label?.toString().toLowerCase() ?? '';
    return label.includes(inputValue.toLowerCase());
};

const ALL_SITES_OPTION = {
    label: 'All sites',
    value: -1,
    key: -1,
};

export const SiteSelector = ({ companyId, allSitesOption, ...props }: ISiteSelectorProps) => {
    const isEnabled = !!companyId;

    const {
        data: sites,
        fetchNextPage,
        isFetching,
        meta,
    } = useSiteInfiniteListQuery(
        { company_id: companyId },
        {
            enabled: isEnabled,
        }
    );

    const options = useMemo(() => {
        const siteOptions = sites?.map(site => ({
            label: site.site_name,
            value: site.site_id,
            key: site.site_id,
            site,
        }));

        return allSitesOption ? [ALL_SITES_OPTION, ...siteOptions] : siteOptions;
    }, [allSitesOption, sites]);

    const handleScroll = async (event: SyntheticEvent) => {
        const target = event.target as HTMLDivElement;

        const isScrolledToBottom = target.scrollHeight - target.scrollTop === target.clientHeight;
        const isMoreData = options.length < meta.total;

        if (isScrolledToBottom && isMoreData) {
            await fetchNextPage();
        }
    };

    return (
        <Select
            allowClear
            showSearch
            size="large"
            options={options}
            disabled={!companyId}
            loading={isEnabled && isFetching}
            filterOption={(inputValue, option) => filterSelectorOption(inputValue, option as IOption)}
            placeholder="Please select site"
            onPopupScroll={handleScroll}
            notFoundContent={isFetching ? <RenderLoadingList /> : <RenderEmptySitesList />}
            {...props}
        />
    );
};

const RenderLoadingList = () => {
    return (
        <Empty
            description=""
            image={
                <Space align="center" direction="vertical">
                    <Spin />
                    <Typography.Text type="secondary">Loading...</Typography.Text>
                </Space>
            }
        />
    );
};

const RenderEmptySitesList = () => {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sites not found!" />;
};

import orderBy from 'lodash/orderBy';
import sum from 'lodash/sum';
import uniq from 'lodash/uniq';
import { ICompany } from '../../../domain/company/interface';
import { IEventsCountBySiteAndYears } from '../../../domain/event/interface';
import { IProgram } from '../../../domain/program/interface';
import { ISanFormItem, ISite, IUpdateSite } from '../../../domain/site/interface';
import {
    checkIfLoadZoneSupportsPriceResponse,
    checkIfMarketSupportsCleanResponse,
} from '../../../domain/site/useMarketZoneOptions';
import { IMergeSitesReqDto, IMergingSite, MergeSitesStatus } from './interface';

export class MergeSitesMap {
    public static toTableSites(sites: ISite[], eventsCountBySites: IEventsCountBySiteAndYears): IMergingSite[] {
        sites = orderBy(sites, site => this.getTotalEventsCount(eventsCountBySites, site.site_id.toString()), 'desc');

        return sites.map(site => this.prepareSite(site));
    }

    public static toReqDto(
        mergingSite: IMergingSite,
        mergingSites: ISite[],
        programs: IProgram[],
        controlProviderCompanies: ICompany[],
        utilityCustomerCompanies: ICompany[]
    ): IMergeSitesReqDto {
        const updateSiteDto = this.toUpdateSiteDto(mergingSite, mergingSites);

        updateSiteDto.control_provider_id = this.getControlProviderId(
            mergingSite.control_provider_name,
            controlProviderCompanies
        );
        updateSiteDto.utility_customer_id = this.getUtilityCustomerId(
            mergingSite.utility_customer_name,
            utilityCustomerCompanies
        );
        updateSiteDto.program_ids = this.getProgramIds(programs, mergingSite.programs);

        return {
            updateSiteDto,
            eventsFromSiteId: mergingSite.eventsFromSiteId,
            cloudVensFromSiteIds: mergingSite.cloudVensFromSiteIds,
            customerVenFromSiteId: mergingSite.customerVenFromSiteId,
            enrollToPriceResponse: checkIfLoadZoneSupportsPriceResponse(
                updateSiteDto.lmp_market,
                updateSiteDto.site_load_zone
            )
                ? mergingSite.site_price_response_enrolled
                : false,
            enrollToCleanResponse: checkIfMarketSupportsCleanResponse(updateSiteDto.lmp_market)
                ? mergingSite.site_clean_response_enrolled
                : false,
            mergingSites: this.getMergingSites(
                mergingSite.site_id,
                mergingSites.map(site => site.site_id)
            ),
        };
    }

    private static prepareSite(site: ISite): IMergingSite {
        return {
            site_id: site.site_id,
            site_name: site.site_name,
            company_id: site.company_id,
            company_name: site.company?.company_name!,

            control_provider_id: site.control_provider_id || null,
            control_provider_name: site.control_provider?.company_name || null,

            utility_customer_id: site.utility_customer_id || null,
            utility_customer_name: site.utility_customer?.company_name || null,

            site_store_number: site.site_store_number,
            site_utility: site.site_utility || null,
            marketAndLoadZone: this.getMarketLoadZone(site),
            site_network: site.site_network || null,
            site_resource_id: site.site_resource_id || null,
            partner_id: site.partner_id || null,
            site_estimated_kw: site.site_estimated_kw || null,
            event_max_duration: site.event_max_duration || null,
            event_offset: site.event_offset || null,
            site_label: site.site_label,
            site_highlight: site.site_highlight,
            email_only_integration: !!site.email_only_integration,
            site_price_response_enrolled: site.site_price_response_enrolled,
            site_clean_response_enrolled: site.site_clean_response_enrolled,

            programs: site.programs?.map(program => program.name) || [],

            location: {
                site_address: site.site_address,
                site_city: site.site_city,
                site_state: site.site_state,
                site_zip: site.site_zip,
                site_country: site.site_country,
                site_lat: site.site_lat,
                site_long: site.site_long,
                site_timezone: site.site_timezone,
                site_county: site.site_county,
                google_place_id: site.google_place_id || null,
            },

            sans: site.sans.map(san => san.service_account_number),

            cloudVensFromSiteIds: [site.site_id],

            customerVenFromSiteId: site.site_id,
            eventsFromSiteId: site.site_id,
        };
    }

    private static getTotalEventsCount(eventsCountBySites: IEventsCountBySiteAndYears, siteId: string): number {
        return sum(Object.values(eventsCountBySites[siteId] || {})) || 0;
    }

    public static getMarketLoadZone(site: ISite): string {
        if (site.lmp_market && site.site_load_zone) {
            return `${site.lmp_market?.toLocaleUpperCase()} / ${site.site_load_zone}`;
        }

        if (site.lmp_market) {
            return site.lmp_market?.toLocaleUpperCase();
        }

        return '';
    }

    public static toUpdateSiteDto(mergingSite: IMergingSite, mergingSites: ISite[]): Omit<IUpdateSite, 'site_id'> {
        const previouslyActiveSans = this.getPreviouslyActiveSans(mergingSites);

        const [market, loadZone] = this.getMarketZone(mergingSite.marketAndLoadZone || '');

        return {
            company_id: mergingSite.company_id,
            control_provider_id: mergingSite.control_provider_id,
            partner_id: mergingSite.partner_id,

            site_name: mergingSite.site_name,
            site_store_number: mergingSite.site_store_number,
            site_utility: mergingSite.site_utility,

            lmp_market: !!market ? market : null,
            site_load_zone: !!loadZone ? loadZone : null,

            site_network: mergingSite.site_network,
            site_resource_id: mergingSite.site_resource_id,

            site_estimated_kw: mergingSite.site_estimated_kw || 0,
            event_max_duration: mergingSite.event_max_duration,
            event_offset: mergingSite.event_offset || 0,

            site_label: mergingSite.site_label,

            site_highlight: mergingSite.site_highlight,
            email_only_integration: mergingSite.email_only_integration,

            site_address: mergingSite.location.site_address,
            site_city: mergingSite.location.site_city,
            site_state: mergingSite.location.site_state,
            site_zip: mergingSite.location.site_zip,
            site_country: mergingSite.location.site_country,
            site_lat: mergingSite.location.site_lat,
            site_long: mergingSite.location.site_long,
            site_timezone: mergingSite.location.site_timezone,
            site_county: mergingSite.location.site_county,
            google_place_id: mergingSite.location.google_place_id,

            sans: this.getSansForUpdate(mergingSite.sans, previouslyActiveSans),
        };
    }

    private static getMergingSites(primarySiteId: number, siteIds: number[]): { [siteId: string]: MergeSitesStatus } {
        return siteIds.reduce((acc: { [siteId: string]: MergeSitesStatus }, siteId) => {
            acc[siteId.toString()] = siteId === primarySiteId ? 'primary' : 'source';
            return acc;
        }, {});
    }

    public static getMarketZone(marketLoadZone: string | null): string[] | null[] {
        return marketLoadZone?.toLowerCase()?.split(' / ') || [null, null];
    }

    private static getSansForUpdate(sanStrings: string[], previouslyActiveSans: string[]): ISanFormItem[] {
        return sanStrings.map((san, index) => ({
            service_account_number: san,
            is_active: previouslyActiveSans.includes(san),
        }));
    }

    private static getProgramIds(programs: IProgram[], programNames: string[]): number[] {
        const programIds: number[] = [];

        programNames.forEach((name: string) => {
            const program = programs.find(p => p.name.toLowerCase() === name.toLowerCase());

            if (program) {
                programIds.push(program.program_id);
            }
        });

        return programIds;
    }

    private static getControlProviderId(
        controlProviderName: string | null,
        controlProviderCompanies: ICompany[]
    ): number | null {
        if (!controlProviderName) {
            return null;
        }

        const controlProvider = controlProviderCompanies.find(company => company?.company_name === controlProviderName);

        return controlProvider?.company_id || null;
    }

    private static getUtilityCustomerId(
        utilityCustomerName: string | null,
        utilityCustomerCompanies: ICompany[]
    ): number | null {
        if (!utilityCustomerName) {
            return null;
        }

        const utilityCustomer = utilityCustomerCompanies.find(company => company?.company_name === utilityCustomerName);

        return utilityCustomer?.company_id || null;
    }

    private static getPreviouslyActiveSans(sites: ISite[]): string[] {
        return uniq(
            sites
                .map(site => site.sans)
                .flat()
                .filter(san => san.is_active)
                .map(san => san.service_account_number)
        );
    }
}

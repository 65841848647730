import Tooltip from 'antd/lib/tooltip';
import Tag from 'antd/lib/tag';
import { toAllowedStringLength } from '../../../domain/common/formattersToAllowedValueLength';
import { ICloudVen, ICustomerVen, statusColorMap } from '../../../domain/ven/interface';

interface IShortVenInfoProps {
    ven?: ICloudVen | ICustomerVen | null;
}

export const ShortVenInfo: React.FC<IShortVenInfoProps> = ({ ven }) => {
    if (!ven) return null;

    return (
        <Tooltip title={`VEN ID: ${ven.ven_id}`} placement="topLeft">
            <Tag color={statusColorMap[ven.status]} style={{ textOverflow: 'ellipsis' }}>
                {toAllowedStringLength(`${ven.ven_id} (${ven.status})`)}
            </Tag>
        </Tooltip>
    );
};

import Radio, { RadioChangeEvent } from 'antd/lib/radio';
import { ICaisoSibrSwitcher } from './caisoSibrInterface';

export const CaisoSibrSwitcher = ({ viewOptions, view, handleViewMode }: ICaisoSibrSwitcher) => {
    const handleView = (e: RadioChangeEvent) => {
        handleViewMode(e.target.value);
    };

    return (
        <Radio.Group value={view} buttonStyle="solid" onChange={handleView}>
            {viewOptions.map(
                view =>
                    view.isVisible && (
                        <Radio.Button value={view.value} data-cy={`view-${view.value}`} key={view.value}>
                            {view.title}
                        </Radio.Button>
                    )
            )}
        </Radio.Group>
    );
};

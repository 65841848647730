import EditOutlined from '@ant-design/icons/EditOutlined';
import Alert from 'antd/lib/alert';
import App from 'antd/lib/app';
import Button from 'antd/lib/button';
import Col from 'antd/lib/col';
import Divider from 'antd/lib/divider';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import Modal from 'antd/lib/modal';
import Row from 'antd/lib/row';
import Select from 'antd/lib/select';
import Typography from 'antd/lib/typography';
import orderBy from 'lodash/orderBy';
import { useEffect, useState } from 'react';
import { AccountList } from '../../../components/company/AccountList';
import { CompanyFormData, ICompanyFormData } from '../../../components/company/CompanyFormData';
import { ManageAccountModal } from '../../../components/company/ManageAccountModal';
import { filterSelectorOption } from '../../../components/selectors/selectorHelpers';
import { WithControlledTooltip } from '../../../components/withControlledTooltip';
import { IAccount } from '../../../domain/account/interface';
import { useAuth } from '../../../domain/auth/useAuth';
import {
    COMPANY_TYPE,
    COMPANY_VERTICAL,
    ICompany,
    ICreateCompany,
    IUpdateCompany,
} from '../../../domain/company/interface';
import {
    useCompanyCreateMutation,
    useCompanyUpdateMutation,
    useSyncCopelandSitesMutation,
} from '../../../domain/company/queries';
import { AlternativeNamesInput } from './AlternativeNamesInput/AlternativeNamesInput';
import { CompanyTypeSelector } from './CompanyTypeSelector';
import { ProgramUtilityOrIsoSelector } from 'src/components/selectors/ProgramUtilityOrIsoSelector/ProgramUtilityOrIsoSelector';

interface ICompanyFormModalProps {
    company: ICompany | Partial<ICompany>;
    onClose: (company?: ICreateCompany | IUpdateCompany) => void;
}

const companyVerticalOption = COMPANY_VERTICAL.sort().map(vertical => ({
    label: vertical,
    value: vertical,
}));

export function CompanyFormModal({ company, onClose }: ICompanyFormModalProps) {
    const { notification } = App.useApp();
    const auth = useAuth()!;
    const [form] = Form.useForm();

    Form.useWatch('type', form);

    const isEditMode = Boolean(company.company_id);

    const [utilityNames, setUtilityNames] = useState<string[]>(company.utility_names || []);
    const [selectedAccounts, setSelectedAccounts] = useState<IAccount[]>(company.accounts || []);
    const [isManageAccountsModalOpen, setIsManageAccountsModalOpen] = useState<boolean>(false);

    const companyCreateMutation = useCompanyCreateMutation();
    const companyUpdateMutation = useCompanyUpdateMutation();
    const { mutateAsync: syncCopelandSites, isLoading: isSyncCopelandLoading } = useSyncCopelandSitesMutation();

    const isLoading = companyCreateMutation.isLoading || companyUpdateMutation.isLoading;

    async function onCompanyModalFormFinish(companyFormData: ICompanyFormData) {
        const company = CompanyFormData.toEntity(companyFormData);
        company.utility_names = utilityNames;

        try {
            if ('company_id' in company && !!company.company_id) {
                await companyUpdateMutation.mutateAsync(company);
            } else {
                await companyCreateMutation.mutateAsync(company);
            }

            notification.success({ key: 'company-save-info', message: 'Company saved successfully!' });
            onClose(company);
        } catch (err: any) {
            notification.error({ key: 'company-save-info', message: err?.message || 'Cannot save company!' });
        }
    }

    function closeCompanyDialog() {
        onClose();
    }

    function onCompanyTypeChange(type: COMPANY_TYPE) {
        form.setFieldValue('type', type);
    }

    const onSyncCopelandAPI = async () => {
        try {
            await syncCopelandSites(company.company_id!);
            notification.info({ key: 'copeland-sync-info', message: 'Synchronization completed' });
        } catch (error: any) {
            notification.error({
                key: 'copeland-sync-error',
                message: error.message || 'Synchronization failed, please try again later',
            });
        }
    };

    const openManageProductsModal = () => {
        setIsManageAccountsModalOpen(true);
    };

    const closeManageProductsModal = () => {
        setIsManageAccountsModalOpen(false);
    };

    const handleManageAccountsFinish = (updatedAccounts: IAccount[]) => {
        const updatedAccountIds = updatedAccounts.map(account => account.salesforce_id);

        setSelectedAccounts(updatedAccounts);
        form.setFieldValue('account_ids', updatedAccountIds);
        form.validateFields(['account_ids']);

        setIsManageAccountsModalOpen(false);
    };

    const handleUnlinkAccount = (salesforceId: string) => {
        const updatedAccounts = selectedAccounts.filter(account => account.salesforce_id !== salesforceId);
        const updatedAccountIds = updatedAccounts.map(account => account.salesforce_id);

        setSelectedAccounts(updatedAccounts);
        form.setFieldValue('account_ids', updatedAccountIds);
        form.validateFields(['account_ids']);
    };

    useEffect(() => {
        setSelectedAccounts(orderBy(company.accounts, ['account_name'], ['asc']));
    }, [company.accounts]);

    return (
        <Modal
            open
            destroyOnClose
            title={getModalTitle(company.type!, isEditMode)}
            onCancel={closeCompanyDialog}
            footer={[
                <Button key="company-modal-cancel" onClick={closeCompanyDialog}>
                    Cancel
                </Button>,
                <Button
                    key="company-modal-submit"
                    data-cy="save-company"
                    type="primary"
                    loading={isLoading}
                    onClick={form.submit}
                >
                    Save
                </Button>,
            ]}
            data-cy="company-modal"
        >
            <Form
                form={form}
                name="company-form"
                preserve={false}
                layout="vertical"
                onFinish={onCompanyModalFormFinish}
                initialValues={CompanyFormData.fromEntity(company)}
                className="company-form"
            >
                <Form.Item name="company_id" hidden>
                    <Input data-cy="company-id" />
                </Form.Item>

                <Form.Item
                    name="company_name"
                    label={<Typography.Text strong>Name</Typography.Text>}
                    hasFeedback
                    rules={[
                        { required: true, message: 'Please enter name!' },
                        { max: 100, message: 'Number of characters should be less than 100' },
                    ]}
                >
                    <Input placeholder="Company name" size="large" data-cy="company-name" />
                </Form.Item>

                <Form.Item
                    name="company_vertical"
                    hasFeedback
                    rules={[{ required: true, message: 'Please select vertical!' }]}
                    label={<Typography.Text strong>Vertical</Typography.Text>}
                >
                    <Select
                        size="large"
                        placeholder="Please select Vertical"
                        options={[...companyVerticalOption, { label: 'Other', value: 'Other' }]}
                        showSearch
                        filterOption={filterSelectorOption}
                        data-cy="company_vertical"
                    />
                </Form.Item>

                {!isEditMode && (
                    <Form.Item
                        name="type"
                        rules={[{ required: true, message: 'Please select company type!' }]}
                        label={<Typography.Text strong>Type</Typography.Text>}
                    >
                        <CompanyTypeSelector onChange={onCompanyTypeChange} />
                    </Form.Item>
                )}

                {auth.user?.isAdminRoleType() && form.getFieldValue('type') === COMPANY_TYPE.UTILITY_CUSTOMER && (
                    <Form.Item
                        required
                        hasFeedback
                        name="utility_iso"
                        label={<Typography.Text strong>Utility/ISO </Typography.Text>}
                        rules={[{ required: true, message: 'Please select utility/iso!' }]}
                    >
                        <ProgramUtilityOrIsoSelector />
                    </Form.Item>
                )}

                {auth.user?.isAdminRoleType() && form.getFieldValue('type') === COMPANY_TYPE.CUSTOMER_COMPANY && (
                    <Form.Item style={{ marginBottom: 0 }}>
                        <AlternativeNamesInput utilities={utilityNames} setUtilityNames={setUtilityNames} />
                    </Form.Item>
                )}

                {auth.user?.isAdminRoleType() && (
                    <>
                        <Divider plain>Salesforce</Divider>

                        <Row gutter={[8, 8]}>
                            <Col span={24}>
                                <Form.Item
                                    name="account_ids"
                                    label={
                                        <>
                                            <Typography.Text strong>Linked Salesforce Accounts</Typography.Text>
                                            <Button size="small" type="link" onClick={openManageProductsModal}>
                                                Manage <EditOutlined />
                                            </Button>
                                        </>
                                    }
                                    rules={(() => {
                                        return !isEditMode &&
                                            form.getFieldValue('type') === COMPANY_TYPE.CUSTOMER_COMPANY
                                            ? [{ required: true, message: 'Please select Salesforce Account!' }]
                                            : [];
                                    })()}
                                >
                                    <AccountList accounts={selectedAccounts} onUnlinkAccount={handleUnlinkAccount} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </>
                )}

                {auth.user?.isAdmin() && form.getFieldValue('type') === COMPANY_TYPE.CUSTOMER_COMPANY && (
                    <>
                        <Divider plain>Integrations</Divider>

                        <Form.Item label={<Typography.Text strong>Copeland API key</Typography.Text>}>
                            <Row gutter={[8, 16]} justify="space-between">
                                <Col span={isEditMode ? 20 : 24}>
                                    <Form.Item noStyle name={['api_credentials', 'copeland_key']}>
                                        <Input.Password placeholder="Copeland API key" size="large" />
                                    </Form.Item>
                                </Col>

                                {isEditMode && (
                                    <>
                                        <Col span={4}>
                                            <WithControlledTooltip
                                                title="Please set API key"
                                                extraVisibleCondition={!company.api_credentials?.copeland_key}
                                            >
                                                <Button
                                                    style={
                                                        !company.api_credentials?.copeland_key
                                                            ? { pointerEvents: 'none' }
                                                            : {}
                                                    }
                                                    type="primary"
                                                    onClick={onSyncCopelandAPI}
                                                    loading={isSyncCopelandLoading}
                                                    size="large"
                                                    disabled={!company.api_credentials?.copeland_key}
                                                >
                                                    Sync
                                                </Button>
                                            </WithControlledTooltip>
                                        </Col>

                                        {company.api_credentials?.copeland_key_error && (
                                            <Col span={24}>
                                                <Alert
                                                    showIcon
                                                    message={company.api_credentials?.copeland_key_error}
                                                    type="warning"
                                                />{' '}
                                            </Col>
                                        )}
                                    </>
                                )}
                            </Row>
                        </Form.Item>
                    </>
                )}
            </Form>

            {isManageAccountsModalOpen && (
                <ManageAccountModal
                    company={company.company_id ? (company as ICompany) : null}
                    accounts={selectedAccounts}
                    onOk={handleManageAccountsFinish}
                    onClose={closeManageProductsModal}
                />
            )}
        </Modal>
    );
}

function getModalTitle(companyType: COMPANY_TYPE, isEditMode: boolean) {
    if (!isEditMode) return 'New company';

    const map = {
        [COMPANY_TYPE.CUSTOMER_COMPANY]: 'Customer',
        [COMPANY_TYPE.CONTROL_PROVIDER]: 'Control Provider',
        [COMPANY_TYPE.UTILITY_CUSTOMER]: 'Utility Customer',
    };

    const typeName = map[companyType] || '';

    return `Edit ${typeName} company`;
}

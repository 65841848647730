import { useState } from 'react';
import Col from 'antd/lib/col';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import Row from 'antd/lib/row';
import Typography from 'antd/lib/typography';
import Alert from 'antd/lib/alert';
import { resendMfa } from '../../../domain/user/mfa';
import { CountdownButton } from '../../countdownButton/countdownButton';
import App from 'antd/lib/app';

interface IRequiredMFA {
    resendSettings: {
        email: string;
        uuid: string;
        updateUuid: (uuid: string) => void;
        infoMessage?: string;
    };
    error?: string;
}

const RESEND_TIME_SEC = 5 * 60;

export const RequiredMFA = ({ resendSettings, error }: IRequiredMFA) => {
    const { notification } = App.useApp();
    const [loading, setLoading] = useState(false);

    const resendCodeAction = async () => {
        setLoading(true);
        try {
            const { uuid } = await resendMfa(resendSettings.email, resendSettings.uuid);
            resendSettings.updateUuid(uuid);
        } catch (error: any) {
            notification.error({
                key: 'send-confirmation-code-error',
                message: error.message || 'Cannot resend confirmation code!',
            });
        }
        setLoading(false);
    };

    return (
        <Row>
            {error && (
                <Col span={24}>
                    <Alert showIcon type="error" message={error} style={{ marginBottom: '8px' }} />
                </Col>
            )}
            <Col span={24} style={{ textAlign: 'center' }}>
                <Typography.Paragraph style={{ textAlign: 'center' }}>
                    {resendSettings.infoMessage || 'Confirmation code has been sent.'}
                    &nbsp;Please enter the code in the field below.
                </Typography.Paragraph>
            </Col>
            <Col span={12} offset={6} style={{ textAlign: 'center' }}>
                <Form.Item
                    name="code"
                    rules={[{ required: true, message: 'Please enter confirmation code!' }]}
                    style={{ marginBottom: '8px' }}
                >
                    <Input size="large" placeholder="Enter confirmation code" data-cy="mfa-confirmation-code-input" />
                </Form.Item>
            </Col>
            <Col span={24} style={{ textAlign: 'center' }}>
                <CountdownButton
                    loading={loading}
                    time={RESEND_TIME_SEC}
                    title="Resend code"
                    onClick={resendCodeAction}
                />
            </Col>
        </Row>
    );
};

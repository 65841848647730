import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '../../query-client';
import { IPagination } from '../IPagination';
import { fetchEvents, fetchEventsMetrics, fetchResources } from './index';
import { IEvent, IEventMetrics, IFetchEventsRequestParams, IFetchResourcesRequestParams } from './interface';
import { IResource } from './resource';

export const useEventListQuery = (params: IFetchEventsRequestParams, options = {}) => {
    return useQuery<IPagination<IEvent>, Error>({
        queryKey: [QueryKey.EVENTS, params],
        queryFn: () => fetchEvents(params),
        refetchOnMount: 'always',
        ...options,
    });
};

export const useEventMetricsQuery = (params: { companyId: any; start: Date; end: Date }, options = {}) => {
    return useQuery<IEventMetrics, Error>({
        queryKey: [QueryKey.EVENTS_METRICS, params],
        queryFn: () => fetchEventsMetrics(params),
        ...options,
    });
};

export const useResourceListQuery = (params: IFetchResourcesRequestParams, options = {}) => {
    return useQuery<IResource[], Error>({
        queryKey: [QueryKey.RESOURCES, params],
        queryFn: ({ signal }) => fetchResources(params, signal),
        ...options,
    });
};

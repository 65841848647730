import { Empty, notification, Row, Skeleton } from 'antd';
import Card from 'antd/lib/card/Card';
import { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AbilityContext } from '../../../components/ability/can';
import { PageHeader } from '../../../components/pageHeader/pageHeader';
import { useDocumentTitle } from '../../../components/useDocumentTitle';
import { ViewSwitcher } from '../../../components/viewSwitcher/ViewSwitcher';
import { useIntervalDataReportingQuery } from '../../../domain/interval-data/queries';
import { usePageLocation } from '../../usePageState';
import { ExportIntervalDataBtn } from '../components/summary/ExportIntervalDataBtn';
import { PartnerSummary } from '../components/summary/PartnerSummary';
import { SitesSummary } from '../components/summary/SitesSummary';
import { UtilitySummary } from '../components/summary/UtilitySummary';
import { viewNavigationOptions } from './viewNavigationOptions';
import './EnergyUsageData.css';

export const EnergyUsageDataSummary = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { queryToState } = usePageLocation();
    const pageState: any = queryToState(location.search);
    const ability = useContext(AbilityContext);

    useDocumentTitle('Energy Usage Data');

    const { data, isLoading, isError, error } = useIntervalDataReportingQuery(pageState.companyId);
    const intervalData = data;

    if (isError) {
        notification.error({
            key: 'interval-data-reporting-fetch-info',
            message: error?.message || 'Cannot fetch reporting data',
        });
    }

    const renderContent = () => {
        return intervalData ? (
            <Row gutter={[16, 16]}>
                <SitesSummary sites={intervalData?.sites!} />
                <PartnerSummary partner={intervalData?.partner} />
                <UtilitySummary utility={intervalData?.utility} />
            </Row>
        ) : (
            <Row justify="center">
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </Row>
        );
    };

    const onViewChange = (view: string) => {
        const companyQuery = pageState.companyId ? `?companyId=${pageState.companyId}` : '';
        navigate(`${view}${companyQuery}`);
    };

    return (
        <>
            <PageHeader
                pageTitle="Energy Usage Data"
                extra={[
                    <ViewSwitcher
                        viewOptions={viewNavigationOptions(ability!)}
                        key="energy-usage-data-view-switcher"
                        view={location.pathname}
                        handleViewMode={onViewChange}
                    />,
                ]}
                actions={[
                    (ability.can('read', 'EnergyCustomer') || ability.can('read', 'ConnectCustomer')) && (
                        <ExportIntervalDataBtn key="company-export" />
                    ),
                ]}
            />
            <Row justify="center">
                <div className="energy-usage-reporting summary">
                    {isLoading ? (
                        <Card size="small">
                            <Skeleton active />
                        </Card>
                    ) : (
                        renderContent()
                    )}
                </div>
            </Row>
        </>
    );
};

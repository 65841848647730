import format from 'date-fns-tz/format';
import { API } from '../api';
import { exportCSVData } from '../common/exportCSVData';
import { IFetchGroupedReadingsAvailabilityStats, IGroupedReadingsAvailabilityStat } from './interface';

export const fetchGroupedReadingsAvailabilityStats = async (
    { companyId, groupedByCompany, endDate, startDate }: IFetchGroupedReadingsAvailabilityStats,
    signal?: AbortSignal | null
): Promise<IGroupedReadingsAvailabilityStat[]> => {
    const path = `/readings-availability-stats?${new URLSearchParams({
        ...(companyId && { companyId: `${companyId}` }),
        groupedByCompany: groupedByCompany ? 'true' : 'false',
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
    })}`;

    const response = await API.fetch(path, {
        ...(signal && { signal }),
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const respBody: any = await response.json();

    if (response.ok) {
        return respBody;
    }

    throw new Error(respBody?.error?.message || `Cannot load list of readings stats!`);
};

export const exportReadingsAvailabilityStats = async (
    { companyId, startDate, endDate }: IFetchGroupedReadingsAvailabilityStats,
    signal?: AbortSignal | null
): Promise<void> => {
    const path = `/readings-availability-stats?${new URLSearchParams({
        format: 'csv',
        ...(companyId && { companyId: `${companyId}` }),
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
    })}`;

    const response = await API.fetch(path, {
        ...(signal && { signal }),
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const respBody: any = await response.text();

    if (response.ok) {
        const fileName = `readings-availability-stats-${format(new Date(), 'yyyy-MM-dd')}.csv`;
        exportCSVData(respBody, fileName);
    } else {
        const respBody: any = await response.json();
        throw new Error(respBody?.error?.message || `Cannot export list of readings stats!`);
    }
};

import { IMetrics, IMetricsByPeriod } from './interface';

const getZeroMetrics = () => ({
    locations: 0,
    eventsHours: 0,
    co2Impact: 0,
    capacity: 0,
});

export const toFixedValue = (value: number) => (Number.isInteger(value) ? value : Number(value.toFixed(1)));

export const toPreferredOrder = (data: any, keyOrder: string[]) => {
    const newObject: any = {};
    for (const key of keyOrder) {
        if (data.hasOwnProperty(key)) {
            newObject[key] = data[key];
        }
    }
    return newObject;
};

export const formatTotalMetrics = (metrics: IMetricsByPeriod) => {
    const totalMetrics = calculateTotalMetrics(metrics);
    const orderedMetricsData = toPreferredOrder(totalMetrics, [
        'locations',
        'capacity',
        'eventsHours',
        'co2Impact',
    ]) as IMetrics;
    const preparedImpactMetricsData = Object.entries(orderedMetricsData).map(([key, value]) => [
        key,
        typeof value === 'number' ? toFixedValue(value) : value,
    ]);

    return Object.fromEntries(preparedImpactMetricsData);
};

export const calculateTotalMetrics = (metrics: IMetricsByPeriod) => {
    const result = getZeroMetrics();

    Object.values(metrics).forEach(metricsItem => {
        const metricsItemData: any = metricsItem || getZeroMetrics();
        result.locations = Math.max(result.locations, metricsItemData.locations);
        result.capacity = Math.max(result.capacity, metricsItemData.capacity);
        result.eventsHours += metricsItemData.eventsHours;
        result.co2Impact += metricsItemData.co2Impact;
    });

    return result;
};

export const calculateTotalMetricsByMonths = (metrics: { [key: string]: IMetricsByPeriod }) => {
    const result: { [key: string]: IMetrics } = {};

    for (let [month, metricsData] of Object.entries(metrics)) {
        let monthMetrics = calculateTotalMetrics(metricsData);
        result[month] = monthMetrics;
    }

    return result;
};

export const getCo2ImpactMetric = (metrics: IMetricsByPeriod) => {
    const result: number[] = Array(12).fill(0);
    Object.entries(metrics).forEach(([month, metricsItem]) => {
        const monthIndex = +month - 1;
        result[monthIndex] = metricsItem!.co2Impact;
    });
    return result;
};

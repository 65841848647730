import { ReactElement } from 'react';
import { VIEW_MODE } from './eventInterface';
import { useCompanies } from '../../components/selectors/GlobalCompanySelector/CompanyContext';
import { EventCalendarView } from './view/EventCalendarView';
import { EventHistoryView } from './view/EventHistoryView';
import { EventTrackingView } from './view/EventTrackingView';
import { useViewSwitcher } from '../../components/viewSwitcher/useViewSwitcher';
import { useDocumentTitle } from '../../components/useDocumentTitle';
import './Events.css';

export function Events() {
    const { view, handleView } = useViewSwitcher(VIEW_MODE.CALENDAR)!;
    const { companyId } = useCompanies()!;

    useDocumentTitle('Events');

    const viewComponent: { [key: string]: ReactElement } = {
        [VIEW_MODE.CALENDAR]: (
            <EventCalendarView company={companyId} view={view} handleViewMode={handleView} key="event-calendar-view" />
        ),
        [VIEW_MODE.HISTORY]: (
            <EventHistoryView company={companyId} view={view} handleViewMode={handleView} key="event-history-view" />
        ),
        [VIEW_MODE.TRACKING]: (
            <EventTrackingView company={companyId} view={view} handleViewMode={handleView} key="event-tracking-view" />
        ),
    };

    return <div className="events-page">{viewComponent[view]}</div>;
}

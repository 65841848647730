import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { createUser, deleteUser, fetchUsers, updateUser } from '.';
import App from 'antd/lib/app';
import { IFetchPageQuery } from '../IFetchQueryOptions';
import { IPagination } from '../IPagination';
import { IUser } from './interface';
import { QueryKey } from '../../query-client';

export const useUserListQuery = (query: IFetchPageQuery, options = {}) => {
    return useQuery<IPagination<IUser>, Error>([QueryKey.USERS, query], ({ signal }) => fetchUsers(query, signal), {
        ...options,
    });
};

export const useUserCreate = () => {
    const { notification } = App.useApp();
    const queryClient = useQueryClient();

    return useMutation<void, Error, IUser>(createUser, {
        onSuccess: async () => {
            await queryClient.invalidateQueries([QueryKey.USERS]);
            notification.success({ key: 'user-create-success', message: 'User created successfully' });
        },
        onError: (error: Error) => {
            notification.error({ key: 'user-create-error', message: error?.message || 'Cannot create user' });
        },
    });
};

export const useUserUpdate = () => {
    const { notification } = App.useApp();
    const queryClient = useQueryClient();

    return useMutation<void, Error, IUser>(updateUser, {
        onSuccess: async () => {
            await queryClient.invalidateQueries([QueryKey.USERS]);
            notification.success({ key: 'user-update-success', message: 'User updated successfully' });
        },
        onError: (error: Error) => {
            notification.error({ key: 'user-update-error', message: error?.message || 'Cannot update user' });
        },
    });
};

export const useUserDelete = () => {
    const queryClient = useQueryClient();

    return useMutation<void, Error, IUser>(deleteUser, {
        onSuccess: async () => await queryClient.invalidateQueries([QueryKey.USERS]),
    });
};

import { ArrowLeftOutlined } from '@ant-design/icons';
import { TableColumnsType, TableColumnType } from 'antd/lib';
import App from 'antd/lib/app';
import Button from 'antd/lib/button';
import Card from 'antd/lib/card/Card';
import Divider from 'antd/lib/divider';
import Table from 'antd/lib/table';
import Tooltip from 'antd/lib/tooltip';
import Typography from 'antd/lib/typography';
import format from 'date-fns-tz/format';
import React, { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCompanies } from '../../components/selectors/GlobalCompanySelector/CompanyContext';
import {
    buildEnrollmentIdFilter,
    getColumnFilteredValue,
    getColumnSortOrder,
} from '../../components/table/columnFormatHelpers';
import { WithEmptyDataTable } from '../../components/table/withEmptyDataTable';
import { defaultDateFormatInUTC } from '../../domain/common/dateFormatters';
import { toAllowedStringLength } from '../../domain/common/formattersToAllowedValueLength';
import { DEFAULT_PAGINATION } from '../../domain/commonConst';
import {
    buildMonthlyReadingsAvailabilityFilter,
    TKeyofDataAvailabilityPercentagesMap,
} from '../../domain/readings-availability-stats/buildMonthlyReadingsAvailabilityFilter';
import { getReadingsStatsCellColor } from '../../domain/readings-availability-stats/getReadingsStatsCellColor';
import { IGroupedReadingsAvailabilityStat } from '../../domain/readings-availability-stats/interface';
import { useGroupedReadingsAvailabilityStatListQuery } from '../../domain/readings-availability-stats/queries';
import { useReadingsAvailabilityStatsContext } from '../../domain/readings-availability-stats/ReadingsAvailabilityStatsContext';
import {
    filterReadingsAvailabilityStatsByEnrollmentId,
    filterReadingsAvailabilityStatsByMonthPercentRange,
} from '../../domain/readings-availability-stats/tableHelpers';
import { SECONDARY_COLOR } from '../../theme';
import { toUsefulParams } from '../toUsefulParams';
import { usePageLocation } from '../usePageState';

interface IPageState {
    companyId?: number;
    pagination?: {
        pageSize: number;
        current: number;
    };
    filter?: {
        enrollmentId?: string | number | (string | number)[];
        stats?: {
            [key: string]: number | string[];
        };
    };
    sorter?: {
        field: string;
        order: 'ascend' | 'descend';
    };
}

export const ReadingsAvailabilityStatsByEnrollment = () => {
    const { notification } = App.useApp();
    const location = useLocation();
    const { setPageQuery, queryToState } = usePageLocation();
    const pageState: IPageState = queryToState(location.search);
    const { startDate, endDate, months, company } = useReadingsAvailabilityStatsContext();

    const {
        data = [],
        isLoading,
        isFetching,
        isError,
        error,
    } = useGroupedReadingsAvailabilityStatListQuery(
        {
            startDate,
            endDate,
            companyId: company?.company_id,
        },
        {
            disable: !company,
        }
    );

    if (isError) {
        notification.error({
            key: 'fetch-readings-availability-stats',
            message: error.message || 'Cannot fetch Data Quality Report!',
        });
    }

    const updatedAt = data?.[0]?.updatedAt || null;
    const enrollmentIds = data.map(record => record.enrollmentId!);

    const readingsAvailabilityStats = useMemo(() => {
        let filteredData = data;
        let filter = pageState.filter || {};

        if (filter.enrollmentId) {
            filteredData = filterReadingsAvailabilityStatsByEnrollmentId(filteredData, filter.enrollmentId);
        }

        if (filter.stats) {
            Object.entries(filter.stats).forEach(([monthKey, rangeKey]) => {
                filteredData = filterReadingsAvailabilityStatsByMonthPercentRange(
                    filteredData,
                    monthKey,
                    rangeKey as TKeyofDataAvailabilityPercentagesMap | TKeyofDataAvailabilityPercentagesMap[]
                );
            });
        }

        return filteredData;
    }, [data, pageState]);

    async function onTableChange(pagination: any, filter: any, sorter: any) {
        const preparedParams = toUsefulParams({ pagination, filter, sorter });

        setPageQuery({ ...pageState, ...preparedParams });
    }

    const columns: TableColumnsType<IGroupedReadingsAvailabilityStat> = [
        {
            title: 'Enrollment ID',
            dataIndex: 'enrollmentId',
            key: 'enrollmentId',
            fixed: 'left',
            width: 200,
            render: (value: string, record) => (
                <Typography.Text ellipsis={{ tooltip: true }} style={{ maxWidth: 200 }}>
                    {record.enrollmentId}
                </Typography.Text>
            ),
            filterMultiple: true,
            filterSearch: true,
            filters: buildEnrollmentIdFilter(enrollmentIds),
            ...getColumnFilteredValue('enrollmentId', pageState.filter),
            sorter: (a, b) => a.enrollmentId!.toLocaleLowerCase().localeCompare(b.enrollmentId!.toLocaleLowerCase()),
            ...getColumnSortOrder('enrollmentId', pageState.sorter),
        },

        {
            title: 'Nomination KW',
            dataIndex: 'nominationKw',
            key: 'nominationKw',
            align: 'center',
            width: 130,
            render: (value: string, record) => record.nominationKw || '-',
            sorter: (a, b) => (a.nominationKw || 0) - (b.nominationKw || 0),
            ...getColumnSortOrder('nominationKw', pageState.sorter),
        },

        ...months.map<TableColumnType<IGroupedReadingsAvailabilityStat>>(month => {
            const monthKey = format(month, 'yyyy-MM-01');
            const monthTitle = format(month, 'MMM-yyyy');

            return {
                title: monthTitle,
                key: `stats.${monthKey}`,
                align: 'center',

                width: 130,

                filters: buildMonthlyReadingsAvailabilityFilter(),
                filterMultiple: true,
                ...getColumnFilteredValue(`stats.${monthKey}`, pageState.filter),

                dataIndex: `stats.${monthKey}`,
                sorter: (a, b) => a.stats[monthKey] - b.stats[monthKey],
                ...getColumnSortOrder(`stats.${monthKey}`, pageState.sorter),

                render: (_, record) => record.stats[monthKey] || '0',
                onCell: record => ({
                    style: {
                        fontSize: '0.8rem',
                        fontWeight: 'bold',
                        background: getReadingsStatsCellColor(record.stats[monthKey]),
                    },
                }),
            };
        }),
    ];

    return (
        <Card size="small" loading={isLoading}>
            <TableTitle updatedAt={updatedAt} companyName={company?.company_name || ''} isLoading={isLoading} />

            <ReadingsAvailabilityStatsByCompanyTable />

            <Divider type="horizontal" />

            <WithEmptyDataTable condition={isLoading}>
                <Table<IGroupedReadingsAvailabilityStat>
                    loading={isLoading || isFetching}
                    columns={columns}
                    rowKey={record => record.enrollmentId!}
                    scroll={{ x: 'max-content' }}
                    tableLayout="fixed"
                    onChange={onTableChange}
                    pagination={{
                        pageSize: pageState.pagination?.pageSize || DEFAULT_PAGINATION.pageSize,
                        current: pageState.pagination?.current || DEFAULT_PAGINATION.current,
                        total: readingsAvailabilityStats.length,
                        showSizeChanger: true,
                    }}
                    dataSource={readingsAvailabilityStats}
                />
            </WithEmptyDataTable>
        </Card>
    );
};

function ReadingsAvailabilityStatsByCompanyTable() {
    const { startDate, endDate, months, company } = useReadingsAvailabilityStatsContext();
    const { data, isLoading } = useGroupedReadingsAvailabilityStatListQuery(
        {
            startDate,
            endDate,
            companyId: company?.company_id,
            groupedByCompany: true,
        },
        {
            disable: !company,
        }
    );

    const readingsAvailabilityStatsByCompany = data || [];

    const columns: TableColumnsType<IGroupedReadingsAvailabilityStat> = [
        {
            title: 'Company Name',
            dataIndex: 'companyId',
            key: 'companyId',
            fixed: 'left',
            width: 330,
            render: (value: string, record) => (
                <Typography.Text ellipsis={{ tooltip: true }} style={{ maxWidth: '300px' }}>
                    {record.companyName}
                </Typography.Text>
            ),
        },

        ...months.map<TableColumnType<IGroupedReadingsAvailabilityStat>>(month => {
            const monthKey = format(month, 'yyyy-MM-01');
            const monthTitle = format(month, 'MMM-yyyy');

            return {
                title: monthTitle,
                key: `stats.${monthKey}`,
                align: 'center',

                width: 130,

                dataIndex: `stats.${monthKey}`,
                render: (_, record) => record.stats[monthKey] || '0',
                onCell: record => ({
                    style: {
                        fontSize: '0.8rem',
                        fontWeight: 'bold',
                        background: getReadingsStatsCellColor(record.stats[monthKey]),
                    },
                }),
            };
        }),
    ];

    return (
        <WithEmptyDataTable condition={isLoading}>
            <Table<IGroupedReadingsAvailabilityStat>
                loading={isLoading}
                columns={columns}
                rowKey={record => record.companyId}
                scroll={{ x: 'max-content' }}
                tableLayout="fixed"
                dataSource={readingsAvailabilityStatsByCompany}
                pagination={{
                    hideOnSinglePage: true,
                }}
            />
        </WithEmptyDataTable>
    );
}

interface ITableTitleProps {
    updatedAt: string | null;
    companyName: string;
    isLoading: boolean;
}

const TableTitle: React.FC<ITableTitleProps> = ({ companyName, isLoading, updatedAt }) => {
    const navigate = useNavigate();
    const { handleCompany } = useCompanies()!;

    const formattedDate = useMemo(() => {
        const date = updatedAt ? new Date(updatedAt) : new Date();
        return defaultDateFormatInUTC(date, new Date().toISOString(), 'dd/MMM/yyyy hh:mm a');
    }, [updatedAt]);

    const returnToAllCompaniesStatistics = () => {
        handleCompany(null);
        navigate('/admin/data-quality/by-company');
    };

    return (
        <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
            <Tooltip title="Back to all companies">
                <Button
                    size="small"
                    shape="circle"
                    icon={<ArrowLeftOutlined />}
                    onClick={returnToAllCompaniesStatistics}
                    style={{ marginTop: 4 }}
                />
            </Tooltip>

            {!isLoading ? (
                <Typography.Title level={4} style={{ marginTop: 0, color: SECONDARY_COLOR, textAlign: 'center' }}>
                    Report of KW interval % for{' '}
                    <Tooltip title={companyName}>
                        <span style={{ color: 'black' }}>{toAllowedStringLength(companyName)}</span>
                    </Tooltip>{' '}
                    company
                    <br />
                    <Typography.Text style={{ marginTop: 0, color: SECONDARY_COLOR, textAlign: 'center' }}>
                        Report last processed {formattedDate} (UTC)
                    </Typography.Text>
                </Typography.Title>
            ) : (
                <Typography.Title
                    level={4}
                    style={{
                        marginTop: 0,
                        color: SECONDARY_COLOR,
                        textAlign: 'center',
                    }}
                >
                    Loading...
                </Typography.Title>
            )}

            <div></div>
        </div>
    );
};

import React from 'react';
import Space from 'antd/lib/space';
import Select from 'antd/lib/select';
import Form from 'antd/lib/form';

const TIME_RANGE = {
    HOURS: {
        START: 0,
        END: 24,
    },
    DAY: 24,
};

const preparedTimeInterval = ({ start = TIME_RANGE.HOURS.START, end = TIME_RANGE.HOURS.END }) =>
    Array.from({ length: TIME_RANGE.DAY + 1 }, (_, k) => {
        var hour: any = k;
        if (k < 10) {
            hour = `0${k}`;
        }
        return { label: hour, value: k };
    }).filter(hour => hour.value >= start);

interface ITimeRangeSelectorProps {
    onChange: (fieldName: string, value: number | null) => void;
    startTime?: number;
    endTime?: number;
    startTimeFieldName?: string;
    endTimeFieldName?: string;
}

export const TimeRangeSelector: React.FC<ITimeRangeSelectorProps> = ({
    onChange,
    startTime,
    endTime,
    startTimeFieldName = 'start_time',
    endTimeFieldName = 'end_time',
}) => {
    const handleChange = (fieldName: string) => (value: null | number) => {
        onChange(fieldName, value);
    };

    const validateTriggerStartTime = (_: any, value: any) => {
        if (value && !endTime) {
            return Promise.reject(new Error('End time should be defined'));
        }

        if (!value && value !== 0 && endTime) {
            return Promise.reject(new Error('Start time should be defined'));
        }

        if (endTime && value >= endTime) {
            return Promise.reject(new Error('End time should be after start time'));
        }

        return Promise.resolve();
    };

    const validateTriggerEndTime = (_: any, value: any) => {
        if (value && !startTime && startTime !== 0) {
            return Promise.reject(new Error('Start time should be defined'));
        }

        return Promise.resolve();
    };

    return (
        <Space>
            <Form.Item
                noStyle
                name={startTimeFieldName}
                dependencies={[endTimeFieldName]}
                rules={[{ validator: validateTriggerStartTime }]}
            >
                <Select
                    allowClear
                    id="startTime"
                    size="large"
                    placeholder="Start time"
                    style={{ width: '125px' }}
                    options={preparedTimeInterval({})}
                    onChange={handleChange(startTimeFieldName)}
                />
            </Form.Item>
            -
            <Form.Item noStyle dependencies={[startTimeFieldName]} rules={[{ validator: validateTriggerEndTime }]}>
                {({ getFieldValue }) => {
                    const minHour = getFieldValue(startTimeFieldName);
                    const options = preparedTimeInterval({ start: minHour + 1 });

                    return (
                        <Form.Item noStyle name={endTimeFieldName}>
                            <Select
                                allowClear
                                size="large"
                                placeholder="End time"
                                style={{ width: '125px' }}
                                options={options}
                                onChange={handleChange(endTimeFieldName)}
                            />
                        </Form.Item>
                    );
                }}
            </Form.Item>
        </Space>
    );
};

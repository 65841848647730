import Typography from 'antd/lib/typography';
import { Space } from 'antd';
import { useCloudVenCertificateDetailsQuery } from '../../../domain/ven/queries';

interface ICertificateFingerprintsProps {
    certificate: string;
}
export function CertificateFingerprints({ certificate }: ICertificateFingerprintsProps) {
    const { data, isLoading, isError } = useCloudVenCertificateDetailsQuery(certificate);

    if (!data || isLoading || isError) {
        return null;
    }

    return (
        <>
            <Space direction="vertical" size="small" style={{ paddingTop: '8px' }}>
                <Typography.Text
                    copyable={{ text: transformFingerprints(data.fingerprints?.sha1) }}
                    style={{ lineBreak: 'anywhere' }}
                >
                    SHA1 Fingerprint:{' '}
                    <Typography.Text code>{transformFingerprints(data.fingerprints?.sha1)}</Typography.Text>
                </Typography.Text>
                <Typography.Text
                    copyable={{ text: transformFingerprints(data.fingerprints?.sha256) }}
                    style={{ lineBreak: 'anywhere' }}
                >
                    SHA256 Fingerprint:{' '}
                    <Typography.Text code>{transformFingerprints(data.fingerprints?.sha256)}</Typography.Text>
                </Typography.Text>
            </Space>
        </>
    );
}
// transform raw string to format like 2A:3B:C4:...
function transformFingerprints(fingerprintString: string = '') {
    const fragments: string[] = fingerprintString.match(/.{1,2}/g) || [];

    return fragments.join(':');
}

import inRange from 'lodash/inRange';

import cluster_green_img from '../../images/cluster_green.png';
import cluster_yellow_img from '../../images/cluster_yellow.png';
import cluster_red_img from '../../images/cluster_red.png';
import cluster_purple_img from '../../images/cluster_purple.png';
import cluster_orange_img from '../../images/cluster_orange.png';
import cluster_light_blue_img from '../../images/cluster_light_blue.png';
import cluster_dark_red_img from '../../images/cluster_dark_red.png';
import cluster_dark_blue_img from '../../images/cluster_dark_blue.png';
import isUndefined from 'lodash/isUndefined';
import { PRIMARY_COLOR } from '../../../../theme';

const commonClusterMarkerStyle = {
    height: 30,
    width: 30,
    textColor: 'white',
    fontFamily: 'Roboto',
};

export const clusterMarkerStyle = [
    {
        url: cluster_green_img,
        ...commonClusterMarkerStyle,
    },
    {
        url: cluster_purple_img,
        ...commonClusterMarkerStyle,
    },
    {
        url: cluster_dark_blue_img,
        ...commonClusterMarkerStyle,
    },
    {
        url: cluster_light_blue_img,
        ...commonClusterMarkerStyle,
    },
    {
        url: cluster_yellow_img,
        ...commonClusterMarkerStyle,
    },
    {
        url: cluster_orange_img,
        ...commonClusterMarkerStyle,
    },
    {
        url: cluster_red_img,
        ...commonClusterMarkerStyle,
    },
    {
        url: cluster_dark_red_img,
        ...commonClusterMarkerStyle,
    },
];

const PRICE = [
    { range: [Number.NEGATIVE_INFINITY, -50], color: '#ad4093', styleIndex: 2 },
    { range: [-50, -20], color: '#2771ad', styleIndex: 3 },
    { range: [-20, 0], color: '#52affa', styleIndex: 4 },
    { range: [0, 60], color: PRIMARY_COLOR, styleIndex: 1 },
    { range: [60, 120], color: '#fac42a', styleIndex: 5 },
    { range: [120, 200], color: '#fa962a', styleIndex: 6 },
    { range: [200, 500], color: '#fa675c', styleIndex: 7 },
    { range: [500, Number.POSITIVE_INFINITY], color: '#ad4840', styleIndex: 8 },
];

const getItemRange = (price: number, dataRange: any[]) =>
    dataRange.find(({ range }) => inRange(price, +range[0], +range[1]));

export const calculateMarkerStyle = (price: number) => {
    const priceItem = getItemRange(price, PRICE);
    return priceItem?.color || PRIMARY_COLOR;
};

export const calculateClusterStyle = (price: number) => {
    const priceItem = getItemRange(price, PRICE);
    return priceItem?.styleIndex || 2;
};

const CO2_INTENSITY = [
    { range: [0, 200], color: '#FFFFB0' },
    { range: [200, 400], color: '#E0B040' },
    { range: [400, 600], color: '#A06030' },
    { range: [600, 800], color: '#602020' },
    { range: [800, Number.POSITIVE_INFINITY], color: '#000010' },
];

export const calculateCO2IntensityStyle = (intensity: number | undefined) => {
    if (isUndefined(intensity)) return '#b5b5b51a';

    const intensityItem = getItemRange(intensity, CO2_INTENSITY);
    return intensityItem?.color || '#b5b5b51a';
};

import { isNull, isNumber, isUndefined, max, mean, sum } from 'lodash';
import { IEnergyData } from '../../../../domain/energy/interface';
import { IDateRange } from '../../../interface';
import {
    addMinutes,
    eachHourOfInterval,
    endOfHour,
    subMinutes,
    format,
    isSameHour,
    startOfDay,
    startOfHour,
} from 'date-fns';
import { EVENT_COLORS, HOUR_MS } from '../../../../domain/commonConst';
import { formatEnergyValueToFixed } from '../../../../domain/common/formattersToAllowedValueLength';
import { IEvent } from '../../../../domain/event/interface';
import { IEventData } from '../../../../domain/event/getEventsDataService';
import { initHourMap } from './prepareEnergyDataForBarChart';

export const formatTimeByHour = (timestamp: number) => format(+timestamp, 'h-a');
const convertSecondsToMiliseconds = (seconds: number) => seconds * 1000;

const getPrevCursor = (totalEnergyByHour: [number, number | null, boolean[], boolean[], boolean[], boolean[]][]) => {
    if (!totalEnergyByHour.length) return null;
    const [prevCursor] = totalEnergyByHour[totalEnergyByHour.length - 1];
    return prevCursor;
};

const groupByHour = (values: [number, number][]) => {
    const hourMap = new Map<string, number[]>();
    values.forEach(([timestamp, usage]) => {
        const date = new Date(timestamp);
        const hour = +date.getUTCHours();
        const key = `${startOfHour(timestamp).valueOf()}::${hour}`;

        if (!hourMap.has(key)) {
            hourMap.set(key, []);
        }
        hourMap.set(key, [...(hourMap.get(key) as number[]), usage]);
    });
    return hourMap;
};

export const initTotalEnergyByHour = (dateRange: IDateRange) => {
    const totalEnergyByHour: [number, number | null, boolean[], boolean[], boolean[], boolean[]][] = [];
    const startTimestamp = startOfHour(dateRange.start).valueOf();
    const endTimestamp = endOfHour(dateRange.end).valueOf();

    let cursor = startTimestamp;
    while (cursor < endTimestamp) {
        let prevCursor = getPrevCursor(totalEnergyByHour);
        const isTheSameHour = prevCursor && isSameHour(new Date(prevCursor), new Date(cursor));
        if (!isTheSameHour) {
            totalEnergyByHour.push([cursor, null, [], [], [], []]);
        }
        cursor += HOUR_MS;
    }

    return totalEnergyByHour;
};

export const calculateSiteAvgByHour = (sites: IEnergyData[]) => {
    const sitesEnergyAvgByHour = sites.map(site => {
        const hourMap = groupByHour(site.values);
        const siteAvgByHourMap = new Map();

        hourMap.forEach((arr, hour) => {
            let avg = mean(arr);
            const [timestamp] = hour.split('::');

            // related to issue with summer/winter time
            if (siteAvgByHourMap.has(timestamp)) {
                const existedAvgValue = siteAvgByHourMap.get(timestamp);
                avg = max([...existedAvgValue, avg]);
            }
            siteAvgByHourMap.set(timestamp, [avg]);
        });

        return {
            ...site,
            hourMap: siteAvgByHourMap,
        };
    });
    return sitesEnergyAvgByHour;
};

const prepareSiteEventsData = (siteEventsData: IEvent[]) => {
    const preEventHours = new Set();
    const normalEventHours = new Set();
    const CREventHours = new Set();
    const PREventHours = new Set();

    siteEventsData.forEach(event => {
        const preEventDuration = event.event_pre_duration || 0;
        const normalEventStart = new Date(event.event_datetime_start);
        const preEventStart = subMinutes(normalEventStart, preEventDuration);
        const normalEventEnd = addMinutes(normalEventStart, event.event_duration);
        const preEventPeriod = eachHourOfInterval({ start: preEventStart, end: normalEventStart });
        const normalEventPeriod = eachHourOfInterval({ start: normalEventStart, end: normalEventEnd });
        const isCREvent = event.event_source.includes('type=clean_response');
        const isPREvent = event.event_source.includes('type=bid');

        preEventPeriod.forEach(eventTime => preEventHours.add(`${+startOfHour(+eventTime)}`));
        normalEventPeriod.forEach(eventTime => {
            normalEventHours.add(`${+startOfHour(+eventTime)}`);
            if (isCREvent) {
                CREventHours.add(`${+startOfHour(+eventTime)}`);
            }
            if (isPREvent) {
                PREventHours.add(`${+startOfHour(+eventTime)}`);
            }
        });
    });

    return { preEventHours, normalEventHours, CREventHours, PREventHours };
};

export const prepareEnergyDataForHeatMap = (sites: IEnergyData[], dateRange: IDateRange, eventData: IEventData[]) => {
    const sitesEnergyAvgByHour = calculateSiteAvgByHour(sites);
    const sitesEnergyAvgByHourWithEvents = sitesEnergyAvgByHour.map(site => {
        const siteEventsData = eventData?.find(event => event.site_id === site.site.site_id)?.data;

        const preparedSiteData = prepareSiteEventsData(siteEventsData ?? []);

        return {
            ...site,
            ...preparedSiteData,
        };
    });

    const totalEnergyByHour: [number, number | null, boolean[], boolean[], boolean[], boolean[]][] =
        initTotalEnergyByHour(dateRange);

    for (let totalEnergy of totalEnergyByHour) {
        const [hour] = totalEnergy;

        sitesEnergyAvgByHourWithEvents.forEach(site => {
            const isPreEvent = site.preEventHours.has(`${hour}`);
            const isNormalEvent = site.normalEventHours.has(`${hour}`);
            const isCREvent = site.CREventHours.has(`${hour}`);
            const isPREvent = site.PREventHours.has(`${hour}`);
            const [avg] = isUndefined(site.hourMap.get(`${hour}`)) ? [null] : site.hourMap.get(`${hour}`)!;
            if (isNumber(avg)) {
                totalEnergy[1] = totalEnergy[1] ? totalEnergy[1] + avg : avg;
            }
            totalEnergy[2].push(isPreEvent);
            totalEnergy[3].push(isNormalEvent);
            totalEnergy[4].push(isCREvent);
            totalEnergy[5].push(isPREvent);
        });
    }

    return totalEnergyByHour.reverse().map(([hour, usage, isPreEvent, isNormalEvent, isCREvent, isPREvent]) => {
        let energyUsage = usage;
        const isPreEventValue = isPreEvent.every(value => value) && !isNormalEvent.some(value => value);
        const isNormalEventValue = isNormalEvent.some(value => value);
        const isPREventValue = isNormalEventValue && isPREvent.every(value => value);
        const isCREventValue = isNormalEventValue && isCREvent.every(value => value);

        if (!isNull(energyUsage)) {
            const fixedNumberOfDigits = energyUsage >= 10 ? 0 : 1;
            energyUsage = formatEnergyValueToFixed(energyUsage, fixedNumberOfDigits);
        }

        let style = { label: {} };
        if (isPreEventValue) {
            style.label = {
                fontWeight: 'bold',
                backgroundColor: EVENT_COLORS.PRE_EVENT,
                color: 'black',
            };
        } else if (isNormalEventValue) {
            if (isCREventValue) {
                style.label = {
                    fontWeight: 'bold',
                    backgroundColor: EVENT_COLORS.CLEAN_RESPONSE,
                    color: 'black',
                };
            } else if (isPREventValue) {
                style.label = {
                    fontWeight: 'bold',
                    backgroundColor: EVENT_COLORS.PRICE_RESPONSE,
                    color: 'white',
                };
            } else {
                style.label = {
                    backgroundColor: EVENT_COLORS.DEFAULT,
                    color: 'black',
                };
            }
        }

        return {
            value: [formatTimeByHour(hour), format(startOfDay(hour), 'EEE, dd-LLL-yyyy'), energyUsage],
            containsEventMinutes: isNormalEvent.some(value => value),
            ...style,
        };
    });
};

export const formatEnergyUsageData = (dateRange: IDateRange, energyBaselineData: [number, number][]) => {
    if (energyBaselineData.length === 0) return [];
    const hourMap = initHourMap(dateRange);
    const sitesEnergySumByHour = new Map<number, number | null>();

    energyBaselineData.forEach(([seconds, value]: any) => {
        let timestampInMiliseconds = convertSecondsToMiliseconds(seconds);
        if (hourMap.has(timestampInMiliseconds)) {
            hourMap.set(timestampInMiliseconds, [...(hourMap.get(timestampInMiliseconds) as number[]), value]);
        }
    });

    hourMap.forEach((energy, time) => {
        let energyValue = null;
        if (energy.length) {
            const energySum = sum(energy);
            const fixedNumberOfDigits = energySum >= 10 ? 0 : 1;
            energyValue = formatEnergyValueToFixed(energySum, fixedNumberOfDigits);
        }

        sitesEnergySumByHour.set(time, energyValue);
    });

    return [...sitesEnergySumByHour];
};

import { useMemo } from 'react';
import App from 'antd/lib/app';
import Select, { SelectProps } from 'antd/lib/select';
import { IProgramType } from '../../../domain/program-type/interface';
import { useProgramTypeListQuery } from '../../../domain/program-type/queries';

export const ProgramTypeSelector = (props: SelectProps) => {
    const { notification } = App.useApp();
    const { isLoading, data, isError, error } = useProgramTypeListQuery({});

    if (isError) {
        notification.error({
            key: 'program-type-list-error',
            message: error.message || 'Cannot load program types!',
        });
    }

    const programTypeOptions = useMemo(
        () =>
            data?.data?.map((programType: IProgramType) => ({
                label: programType.name,
                value: programType.id,
            })),
        [data]
    );

    return (
        <Select
            showSearch
            autoClearSearchValue
            optionFilterProp="label" // to search by label not value
            loading={isLoading}
            placeholder="Select Type"
            options={programTypeOptions}
            size="large"
            {...props}
        />
    );
};

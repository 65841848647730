import { useQuery } from '@tanstack/react-query';
import { IDateRange } from '../../pages/interface';
import { QueryKey } from '../../query-client';
import { IRawImpact } from './interface';
import { fetchImpact } from './index';

export const useImpactListQuery = (query: { companyId: any; date: IDateRange }, options = {}) => {
    return useQuery<IRawImpact[], Error>([QueryKey.IMPACT, query], ({ signal }) => fetchImpact(query, signal), {
        ...options,
    });
};
